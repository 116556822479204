import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { DesignSelectorOptions } from ".";
import "./index.css";
import i18n from "./i18n";
import { MainDesignSelector } from "./components/designSelector/MainDesignSelector";

const queryClient = new QueryClient();

type AppProps = {
  token: string;
  apiKey: string;
  options: DesignSelectorOptions;
};

export const DesignSelector: React.FC<AppProps> = ({
  token,
  apiKey,
  options,
}) => {
  const { language } = options;

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <QueryClientProvider client={queryClient}>
      <MainDesignSelector token={token} apiKey={apiKey} options={options} />
    </QueryClientProvider>
  );
};
