import { useFormikContext } from "formik";
import { ConfiguratorApi } from "melco-shared-logic/dist/client-fusion/apis/ConfiguratorApi";
import { FusionMelcoFusionModelsSession } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoFusionModelsSession";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { OnCancelCallback } from "..";
import { ConfirmActions } from "./useConfirm";
import { useAPIConfig } from "./useAPIConfig";

type useCancelSessionOptions = {
  withConfirm?: ConfirmActions;
  isViewModeEnabled?: boolean;
};

export const useCancelSession = (
  apiKey: string,
  onCancelCallback: OnCancelCallback,
  options?: useCancelSessionOptions
) => {
  const { t } = useTranslation();
  const apiConfig = useAPIConfig(apiKey);
  const { values } = useFormikContext<
    FusionMelcoFusionModelsSession | undefined
  >();
  const { withConfirm, isViewModeEnabled } = options || {};

  const cancelSession = useCallback(async () => {
    onCancelCallback();

    if (!values || isViewModeEnabled) {
      return;
    }

    try {
      const api = new ConfiguratorApi(apiConfig);

      await api.configuratorSessionsDrop({
        sessionId: values.session_id!,
        fusionMelcoFusionModelsSession: values,
      });
    } catch (e) {
      // we still want the configurator to disappear, even if something
      // goes wrong while canceling the session
      console.error(e);
    }
  }, [onCancelCallback, apiKey, values, isViewModeEnabled]);

  return useCallback(() => {
    if (withConfirm) {
      const { showConfirm, hideConfirm } = withConfirm;
      showConfirm({
        title: t("cancelSession.title"),
        text: t("cancelSession.text"),
        onOk: cancelSession,
        onOkText: t("cancelSession.ok"),
        onCancel: hideConfirm,
        onCancelText: t("cancelSession.cancel"),
      });
    } else {
      cancelSession();
    }
  }, [withConfirm, cancelSession]);
};
