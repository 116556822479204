import { MelcoModelsDesignElement } from "../../api";

const isLettering = (item: MelcoModelsDesignElement) =>
  item.type === "Lettering";

const isColor = (item: MelcoModelsDesignElement) => item.type === "Color";

const isDesign = (item: MelcoModelsDesignElement) => item.type === "Design";

const isNotActiveGroupColor = (item: MelcoModelsDesignElement) =>
  !(item.active_color_group_id && item.active_color_group_id.length > 0);

const isEditable = (item: MelcoModelsDesignElement) => !!item.editable;

export const ElementListHelper = {
  isLettering,
  isColor,
  isDesign,
  isNotActiveGroupColor,
  isEditable,
};
