/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FusionMelcoFusionModelsCustomProductDesignCollection,
    FusionMelcoFusionModelsCustomProductDesignCollectionFromJSON,
    FusionMelcoFusionModelsCustomProductDesignCollectionFromJSONTyped,
    FusionMelcoFusionModelsCustomProductDesignCollectionToJSON,
    FusionMelcoFusionModelsCustomProductDesignHoop,
    FusionMelcoFusionModelsCustomProductDesignHoopFromJSON,
    FusionMelcoFusionModelsCustomProductDesignHoopFromJSONTyped,
    FusionMelcoFusionModelsCustomProductDesignHoopToJSON,
    FusionMelcoFusionModelsRendererMatrix,
    FusionMelcoFusionModelsRendererMatrixFromJSON,
    FusionMelcoFusionModelsRendererMatrixFromJSONTyped,
    FusionMelcoFusionModelsRendererMatrixToJSON,
    FusionMelcoFusionModelsRendererRect,
    FusionMelcoFusionModelsRendererRectFromJSON,
    FusionMelcoFusionModelsRendererRectFromJSONTyped,
    FusionMelcoFusionModelsRendererRectToJSON,
    FusionMelcoModelsDesign,
    FusionMelcoModelsDesignFromJSON,
    FusionMelcoModelsDesignFromJSONTyped,
    FusionMelcoModelsDesignToJSON,
} from './';

/**
 * 
 * @export
 * @interface FusionMelcoFusionModelsCustomProductImageDesign
 */
export interface FusionMelcoFusionModelsCustomProductImageDesign {
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsCustomProductImageDesign
     */
    personalization_id?: string | null;
    /**
     * 
     * @type {FusionMelcoFusionModelsRendererRect}
     * @memberof FusionMelcoFusionModelsCustomProductImageDesign
     */
    renderer_rect?: FusionMelcoFusionModelsRendererRect;
    /**
     * 
     * @type {FusionMelcoFusionModelsRendererMatrix}
     * @memberof FusionMelcoFusionModelsCustomProductImageDesign
     */
    renderer_matrix?: FusionMelcoFusionModelsRendererMatrix;
    /**
     * 
     * @type {FusionMelcoModelsDesign}
     * @memberof FusionMelcoFusionModelsCustomProductImageDesign
     */
    design?: FusionMelcoModelsDesign;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsCustomProductImageDesign
     */
    production_rotation_degree?: number;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsCustomProductImageDesign
     */
    production_instructions?: string | null;
    /**
     * 
     * @type {FusionMelcoFusionModelsCustomProductDesignCollection}
     * @memberof FusionMelcoFusionModelsCustomProductImageDesign
     */
    design_collection?: FusionMelcoFusionModelsCustomProductDesignCollection;
    /**
     * 
     * @type {FusionMelcoFusionModelsCustomProductDesignHoop}
     * @memberof FusionMelcoFusionModelsCustomProductImageDesign
     */
    hoop?: FusionMelcoFusionModelsCustomProductDesignHoop;
}

export function FusionMelcoFusionModelsCustomProductImageDesignFromJSON(json: any): FusionMelcoFusionModelsCustomProductImageDesign {
    return FusionMelcoFusionModelsCustomProductImageDesignFromJSONTyped(json, false);
}

export function FusionMelcoFusionModelsCustomProductImageDesignFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoFusionModelsCustomProductImageDesign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personalization_id': !exists(json, 'personalization_id') ? undefined : json['personalization_id'],
        'renderer_rect': !exists(json, 'renderer_rect') ? undefined : FusionMelcoFusionModelsRendererRectFromJSON(json['renderer_rect']),
        'renderer_matrix': !exists(json, 'renderer_matrix') ? undefined : FusionMelcoFusionModelsRendererMatrixFromJSON(json['renderer_matrix']),
        'design': !exists(json, 'design') ? undefined : FusionMelcoModelsDesignFromJSON(json['design']),
        'production_rotation_degree': !exists(json, 'production_rotation_degree') ? undefined : json['production_rotation_degree'],
        'production_instructions': !exists(json, 'production_instructions') ? undefined : json['production_instructions'],
        'design_collection': !exists(json, 'design_collection') ? undefined : FusionMelcoFusionModelsCustomProductDesignCollectionFromJSON(json['design_collection']),
        'hoop': !exists(json, 'hoop') ? undefined : FusionMelcoFusionModelsCustomProductDesignHoopFromJSON(json['hoop']),
    };
}

export function FusionMelcoFusionModelsCustomProductImageDesignToJSON(value?: FusionMelcoFusionModelsCustomProductImageDesign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personalization_id': value.personalization_id,
        'renderer_rect': FusionMelcoFusionModelsRendererRectToJSON(value.renderer_rect),
        'renderer_matrix': FusionMelcoFusionModelsRendererMatrixToJSON(value.renderer_matrix),
        'design': FusionMelcoModelsDesignToJSON(value.design),
        'production_rotation_degree': value.production_rotation_degree,
        'production_instructions': value.production_instructions,
        'design_collection': FusionMelcoFusionModelsCustomProductDesignCollectionToJSON(value.design_collection),
        'hoop': FusionMelcoFusionModelsCustomProductDesignHoopToJSON(value.hoop),
    };
}


