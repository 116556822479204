import { ActiveView } from "../../hooks/useActiveView";
import { ActiveColor } from "../../hooks/useProductColor";
import { useViewThumbnailImage } from "../../hooks/useViewThumbnailImage";
import { useAvailableViews } from "../../hooks/useAvailableViews";
import { Switch } from "../switch/Switch";
import { SwitchItem } from "../switch/item/SwitchItem";
import "./ViewSelector.css";

type ViewSelectorProps = {
  activeView: ActiveView | undefined;
  setActiveView: (activeView: ActiveView | undefined) => void;
  activeProductColor: ActiveColor | undefined;
  isUIDisabled: boolean;
};

export const ViewSelector: React.FC<ViewSelectorProps> = ({
  activeView,
  setActiveView,
  activeProductColor,
  isUIDisabled,
}) => {
  const availableViews = useAvailableViews();

  const getViewThumbnailImage = useViewThumbnailImage(activeProductColor?.name);

  if (!availableViews) {
    return null;
  }

  return (
    <div className="mfc__configurator-view-selector">
      <Switch disabled={isUIDisabled}>
        {availableViews.map(({ name }, viewIdx) => (
          <SwitchItem
            key={name}
            active={activeView && activeView.index === viewIdx}
            disabled={isUIDisabled}
            onClick={() => {
              setActiveView({ index: viewIdx, name });
            }}
          >
            <img src={getViewThumbnailImage(name)} alt={name} title={name} />
          </SwitchItem>
        ))}
      </Switch>
    </div>
  );
};
