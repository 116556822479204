import { ErrorIcon } from "../../icons/icons";

import "./ModalError.css";

type ModalErrorProps = {
  text: string;
  buttons?: React.ReactNode[];
};

const ModalError: React.FC<ModalErrorProps> = ({ text, buttons }) => (
  <div className="mfc__modal-error">
    <ErrorIcon />

    <span>{text}</span>

    {buttons && <div className="mfc__modal-error-buttons">{buttons}</div>}
  </div>
);

export { ModalError };
