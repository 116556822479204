import { Alphabet as InternalAlphabet, RenderElement, RenderModule, SelectionBoxElement, WireframeElement } from "../wasm/MelcoRendererApp";
import { LetteringParams, ThreadColor } from "./elementparams";
import { StitchSubElement } from "./elements";
import { Rectangle, RectangleUtil } from "./geom";

export class LRUNodeData {
    item?: InternalReload;
    alphabet_item?: AlphabetCacheData;
    element_item?: ElementCacheData;
    download?: Promise<number>;
}
interface LRUNode {
    last_seen: number;
    left?: LRUNode;
    right?: LRUNode;
    data: LRUNodeData
    key: string;
}
export class LRUManager {
    constructor(max_elements: number = 5, check_iter: boolean = true) {
        this.max_items = max_elements;
        this.check_iter = check_iter;
    }
    public setCheckIter(v: boolean) {
        this.check_iter = v;
    }
    public setMaxElements(m: number) {
        this.max_items = m;
    }
    public addStitchElementCacheItem(key: string, elem: ElementCacheData): LRUNodeData {
        if (this.count >= this.max_items && this.head && (!this.check_iter || this.head.last_seen < this.curr_iter)) {
            this.popHead();
        }
        let data: LRUNodeData = {
            element_item: elem
        }
        this.appendNode(key.toString(), data);
        return data;
    }
    public addStitchElementItem(key: string, item: Promise<InternalReload>, noopid: number): LRUNodeData {
        if (this.count >= this.max_items && this.head && (!this.check_iter || this.head.last_seen < this.curr_iter)) {
            this.popHead();
        }
        let data: LRUNodeData = {};
        data.download = item.then((v : InternalReload) => {
            data.item = v;
            data.item.key = key;
            return noopid;
        });
        this.appendNode(key, data);
        return data;
    }
    public addAlphabetItem(key: string, item: Promise<AlphabetCacheData>, noopid: number): LRUNodeData {
        if (this.count >= this.max_items && (!this.check_iter || this.head && this.head.last_seen < this.curr_iter)) {
            this.popHead();
        }
        let data: LRUNodeData = {};
        data.download = item.then((v : AlphabetCacheData) => {
            data.alphabet_item = v;
            return noopid;
        });
        this.appendNode(key, data);
        return data;
    }
    public getItemByKey(key: string): LRUNodeData | undefined {
        let node = this.lookup_map.get(key);
        if (node && node != this.tail && this.tail) {
            if (node.left) {
                node.left.right = node.right;
            }
            if (node.right) {
                node.right.left = node.left;
            }
            if (this.head == node) {
                this.head = node.right;
            }
            this.tail.right = node;
            node.left = this.tail;
            node.right = undefined;
            this.tail = node;
        }
        if (node) {
            node.last_seen = this.curr_iter;
        }
        return node ? node.data : undefined;
    }
    public popHead() {
        if (this.head && this.head.right) {
            this.lookup_map.delete(this.head.key);
            this.freeMemory(this.head.data);
            this.head.right.left = undefined;
            this.head = this.head.right;
            this.count = this.count - 1;
        } else if (this.head) {
            this.lookup_map.delete(this.head.key);
            this.freeMemory(this.head.data);
            this.head = undefined;
            this.tail = undefined;
            this.count = this.count - 1;
        }
    }
    public freeMemory(data: LRUNodeData) {
        if (data.alphabet_item) {
            data.alphabet_item.decrementReferenceCount();
            data.alphabet_item = undefined;
        }
        if (data.item) {
            data.item.decrementReferenceCount();
            data.item = undefined;
        }
        if (data.element_item) {
            data.element_item.decrementReferenceCount();
            data.element_item = undefined;
        }
    }
    public destroy() {
        while (this.head) {
            this.popHead();
        }
    }
    public appendNode(key: string, data: LRUNodeData) {
        let node = this.lookup_map.get(key);
        if (node) {
            if (node.data !== data) {
                this.freeMemory(node.data)
            }
            node.key = key
            node.last_seen = this.curr_iter
            node.data = data
            return
        }
        if (!this.head || !this.tail) {
            this.head = {
                data: data,
                last_seen: this.curr_iter,
                key: key
            }
            this.tail = this.head;
        } else {
            this.tail.right = {
                left: this.tail,
                data: data,
                last_seen: this.curr_iter,
                key: key
            }
            this.tail = this.tail.right;
        }
        this.count = this.count + 1;
        this.lookup_map.set(key, this.tail);
    }
    public incrementIter() {
        this.curr_iter = this.curr_iter + 1;
    }
    head?: LRUNode;
    tail?: LRUNode;
    count: number = 0;
    max_items: number = 5;
    curr_iter: number = 1;
    lookup_map: Map<string, LRUNode> = new Map<string, LRUNode>();
    check_iter: boolean = true;
}

export class InternalReload {
    public renderElement: RenderElement;
    public xdim: number = 0;
    public ydim: number = 0;
    public rect: Rectangle = RectangleUtil.emptyRect();
    public colors: Array<ThreadColor> = [];
    public letteringParams: Array<LetteringParams> = [];
    public subElements: Array<StitchSubElement> = [];
    public stitchCount: number = 0;
    public key: string = "";
    public constructor(r: RenderElement) {
        this.renderElement = r;
    }
    private referenceCount: number = 1;
    public incrementReferenceCount() {
        this.referenceCount = this.referenceCount + 1;
    }
    public decrementReferenceCount() {
        this.referenceCount = this.referenceCount - 1;
        if (this.referenceCount == 0) {
            this.renderElement.delete();
        }
    }
}
export class AlphabetCacheData {
    public internalAlphabet: InternalAlphabet;
    public characters?: Array<string>;
    public alphabet_name?: string;
    private referenceCount: number = 1;
    public constructor(a: InternalAlphabet) {
        this.internalAlphabet = a;
        this.alphabet_name = a.getAlphabetName();
        let chrs = a.getCharacters();
        this.characters = [];
        for (let i=0; i < chrs.size(); i++) {
            this.characters.push(chrs.get(i));
        }
    }
    public incrementReferenceCount() {
        this.referenceCount = this.referenceCount + 1;
    }
    public decrementReferenceCount() {
        this.referenceCount = this.referenceCount - 1;
        if (this.referenceCount == 0) {
            this.internalAlphabet.delete();
        }
    }
}
export class ElementCacheData {
    private _internalReload?: InternalReload;
    public get internalReload(): InternalReload | undefined {
        return this._internalReload;
    }
    public set internalReload(r: InternalReload | undefined) {
        if (r) {
            r.incrementReferenceCount();
        }
        if (this._internalReload) {
            this._internalReload.decrementReferenceCount();
        }
        this._internalReload = r;
    }
    public _element?: RenderElement;
    public get element(): RenderElement | undefined {
        if (this._element) {
            return this._element;
        }
        if (this._internalReload) {
            return this._internalReload.renderElement;
        }
        return undefined;
    }
    public clone(): ElementCacheData {
        let e = this.element;
        let n = new ElementCacheData();
        n.internalReload = this._internalReload;
        if (e) {
            n._element = e.clone();
        }
        return n;
    }
    private referenceCount: number = 1;
    public incrementReferenceCount() {
        this.referenceCount = this.referenceCount + 1;
    }
    public decrementReferenceCount() {
        this.referenceCount = this.referenceCount - 1;
        if (this.referenceCount == 0 && this._element) {
            this._element.delete();
            this._element = undefined;
        }
        if (this.referenceCount == 0 && this._internalReload) {
            this._internalReload.decrementReferenceCount();
            this._internalReload = undefined;
        }
    }
}