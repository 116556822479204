import { useField } from "formik";
import { FusionMelcoFusionModelsSession } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoFusionModelsSession";
import { FusionMelcoFusionModelsRendererMatrix } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoFusionModelsRendererMatrix";
import { useDesignFormikPath } from "./useDesignFormikPath";
import { ActiveView } from "./useActiveView";
import { ActiveColor } from "./useProductColor";

export const useDesign = (
  activeView: ActiveView | undefined,
  activeColor: ActiveColor | undefined
) => {
  const designFormikPath = useDesignFormikPath(activeView, activeColor);
  const rendererMatrixFormikPath = designFormikPath.replace(
    /\.design$/,
    ".renderer_matrix"
  );

  const [{ value: design }] = useField<
    FusionMelcoFusionModelsSession | undefined
  >(designFormikPath);

  const [{ value: designRendererMatrix }] = useField<
    FusionMelcoFusionModelsRendererMatrix | undefined
  >(rendererMatrixFormikPath);

  return [design, designRendererMatrix] as const;
};
