import {
  PersonalizeEditSection,
  PersonalizeEditSectionProps,
} from "../editSection/PersonalizeEditSection";

import "./PersonalizeButtonSection.css";

type PersonalizeButtonSectionProps = PersonalizeEditSectionProps;

const PersonalizeButtonSection: React.FC<PersonalizeButtonSectionProps> = ({
  children,
  ...rest
}) => (
  <PersonalizeEditSection {...rest}>
    <div className="mfc__personalize-edit-button-section">{children}</div>
  </PersonalizeEditSection>
);

export { PersonalizeButtonSection };
