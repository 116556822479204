import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./translations/en.json";
import de from "./translations/de.json";
import fr from "./translations/fr.json";
import it from "./translations/it.json";
import nl from "./translations/nl.json";
import sv from "./translations/sv.json";

export const defaultNS = "melco-fusion-configurator-web";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "de", "fr", "it", "nl", "sv"],
    fallbackLng: "en",
    ns: ["melco-fusion-configurator-web"],
    defaultNS: "melco-fusion-configurator-web",
    cleanCode: true,
    resources: {
      en: {
        "melco-fusion-configurator-web": en,
      },
      de: {
        "melco-fusion-configurator-web": de,
      },
      fr: {
        "melco-fusion-configurator-web": fr,
      },
      it: {
        "melco-fusion-configurator-web": it,
      },
      nl: {
        "melco-fusion-configurator-web": nl,
      },
      sv: {
        "melco-fusion-configurator-web": sv,
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ["querystring", "navigator"],
    },
    returnNull: false,
  });

export default i18n;
