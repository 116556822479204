import { useField } from "formik";
import { FusionMelcoModelsCanvasPreview } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoModelsCanvasPreview";
import { FusionMelcoModelsUserImage } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoModelsUserImage";
import { ActiveView } from "./useActiveView";
import { ActiveColor } from "./useProductColor";

export const useBlankPictureUrl = (
  activeView: ActiveView | undefined,
  activeColor: ActiveColor | undefined
) => {
  const [{ value: blankPictures }] = useField<
    FusionMelcoModelsUserImage[] | undefined
  >("product.blank.images");

  const [{ value: canvasPreviews }] = useField<
    FusionMelcoModelsCanvasPreview[] | undefined
  >("product.blank.canvas_previews");

  // get blank picture to find out the correct blank picture id for current view and color
  const blankPicture = (blankPictures ?? []).find(
    (bp) =>
      activeView &&
      activeColor &&
      bp.view_name === activeView.name &&
      bp.color_name === activeColor.name
  );

  const canvasPreview = (canvasPreviews ?? []).find(
    (cp) => blankPicture && cp.id === blankPicture.id
  );

  return canvasPreview?.url;
};
