/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FusionMelcoFusionModelsSessionListItem,
    FusionMelcoFusionModelsSessionListItemFromJSON,
    FusionMelcoFusionModelsSessionListItemFromJSONTyped,
    FusionMelcoFusionModelsSessionListItemToJSON,
    FusionMelcoModelsCustomWarning,
    FusionMelcoModelsCustomWarningFromJSON,
    FusionMelcoModelsCustomWarningFromJSONTyped,
    FusionMelcoModelsCustomWarningToJSON,
} from './';

/**
 * 
 * @export
 * @interface FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItem
 */
export interface FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItem {
    /**
     * 
     * @type {Array<FusionMelcoFusionModelsSessionListItem>}
     * @memberof FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItem
     */
    list: Array<FusionMelcoFusionModelsSessionListItem>;
    /**
     * 
     * @type {Array<FusionMelcoModelsCustomWarning>}
     * @memberof FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItem
     */
    warning_list?: Array<FusionMelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItem
     */
    total: number;
}

export function FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItemFromJSON(json: any): FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItem {
    return FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItemFromJSONTyped(json, false);
}

export function FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(FusionMelcoFusionModelsSessionListItemFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(FusionMelcoModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItemToJSON(value?: FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(FusionMelcoFusionModelsSessionListItemToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(FusionMelcoModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


