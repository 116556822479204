import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFeature } from "flagged";
import { ActiveFont } from "../../hooks/useElementFonts";
import { ModalPicker } from "../modal/picker/ModalPicker";
import { Button } from "../button/Button";
import { FloatingButton } from "../floatingButton/FloatingButton";
import { FloatingButtonGrid } from "../floatingButtonGrid/FloatingButtonGrid";
import { LetteringFloatingButton } from "../floatingButton/LetteringFloatingButton";

type FontPickerProps = {
  isPickerVisible: boolean;
  closePicker: () => void;
  initialFont: ActiveFont | undefined;
  onFontChange: (newFont: ActiveFont | undefined) => void;
  availableFonts: ActiveFont[];
};

export const FontPicker: React.FC<FontPickerProps> = ({
  isPickerVisible,
  closePicker,
  initialFont,
  onFontChange,
  availableFonts,
}) => {
  const { t } = useTranslation();

  const isLabeledFeatureEnabled = useFeature("ui/labeled");

  const [selectedFont, setSelectedFont] = useState<ActiveFont | undefined>(
    initialFont
  );

  // set initial font, if no font has been selected
  useEffect(() => {
    if (!selectedFont && initialFont) {
      setSelectedFont(initialFont);
    }
  }, [selectedFont, initialFont, setSelectedFont]);

  const onCancel = useCallback(() => {
    closePicker();

    // reset
    setSelectedFont(initialFont);
  }, [initialFont, closePicker, setSelectedFont]);

  const onOk = useCallback(() => {
    // confirm font change
    onFontChange(selectedFont);

    closePicker();
  }, [selectedFont, closePicker, setSelectedFont]);

  return (
    <ModalPicker
      isVisible={isPickerVisible}
      title={t("fontPicker.title")}
      buttons={[
        <Button key="picker-cancel" onClick={onCancel}>
          {t("actionButtons.cancel")}
        </Button>,
        <Button key="picker-ok" type="primary" onClick={onOk}>
          {t("actionButtons.ok")}
        </Button>,
      ]}
    >
      {isLabeledFeatureEnabled && (
        <LetteringFloatingButton font={selectedFont} />
      )}

      <FloatingButtonGrid>
        {availableFonts.map((font) => {
          const { id, name, previewThumbnailUrl } = font;

          return (
            <FloatingButton
              key={id}
              isActive={selectedFont && selectedFont.id === id}
              onClick={() => {
                setSelectedFont(font);
              }}
            >
              <img src={previewThumbnailUrl} alt={name} />
            </FloatingButton>
          );
        })}
      </FloatingButtonGrid>
    </ModalPicker>
  );
};
