import { UseMutationResult } from "react-query";
import { useTranslation } from "react-i18next";
import { PersonalizeButtonSection } from "../personalize/buttonSection/PersonalizeButtonSection";
import { Button } from "../button/Button";
import { ActiveView } from "../../hooks/useActiveView";
import { ActiveColor } from "../../hooks/useProductColor";
import { useElementList } from "../../hooks/useElementList";
import { ProductColor } from "./ProductColor";
import { Lettering } from "./Lettering";
import { DesignColors } from "./DesignColors";
import "./ConfiguratorSidebar.css";
import { useDesignCollectionId } from "../../hooks/useDesignCollectionId";
import { DesignCollection } from "./DesignCollection";

type ConfiguratorSidebarProps = {
  activeView: ActiveView | undefined;
  activeProductColor: ActiveColor | undefined;
  setActiveProductColor: (activeColor: ActiveColor | undefined) => void;
  onCancel: () => void;
  onFinish: UseMutationResult;
  isProductColorChangeDisabled?: boolean;
  isDesignChangeDisabled?: boolean;
  apiKey: string;
};

export const ConfiguratorSidebar: React.FC<ConfiguratorSidebarProps> = ({
  activeView,
  activeProductColor,
  setActiveProductColor,
  onCancel,
  onFinish,
  isProductColorChangeDisabled,
  isDesignChangeDisabled,
  apiKey,
}) => {
  const { t } = useTranslation();

  const designCollectionId = useDesignCollectionId(
    activeView,
    activeProductColor,
    isDesignChangeDisabled
  );

  const { letteringObjects, colorObjects, elementIndexes } = useElementList(
    activeView,
    activeProductColor
  );

  const isUIDisabled = false;

  const { mutate: finish, isLoading } = onFinish;

  return (
    <>
      <h1>{t("customization.title")}</h1>

      {!isProductColorChangeDisabled && (
        <ProductColor
          activeProductColor={activeProductColor}
          setActiveProductColor={setActiveProductColor}
        />
      )}

      {designCollectionId != null && (
        <DesignCollection
          activeView={activeView}
          activeColor={activeProductColor}
          designCollectionId={designCollectionId}
          apiKey={apiKey}
        />
      )}

      {letteringObjects.map((l) => (
        <Lettering
          key={l.id}
          letteringElement={l}
          activeView={activeView}
          elementIndex={elementIndexes.get(l)}
        />
      ))}

      <DesignColors
        colorObjects={colorObjects}
        activeView={activeView}
        elementIndexes={elementIndexes}
      />

      <PersonalizeButtonSection hideOnDesktop>
        <Button onClick={onCancel} disabled={isUIDisabled}>
          {t("actionButtons.cancel")}
        </Button>
        <Button
          type="primary"
          disabled={isUIDisabled || isLoading}
          onClick={finish}
        >
          {t("actionButtons.finish")}
        </Button>
      </PersonalizeButtonSection>
    </>
  );
};
