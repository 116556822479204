import { ActiveView } from "../hooks/useActiveView";

export const designFormikPrefix = (
  activeView: ActiveView,
  colorIndex?: number
) =>
  `product.view_list[${activeView.index}].color_list[${
    colorIndex != null ? colorIndex : 0
  }].design_list[0].design`;

export const elementFormikPrefix = (
  activeView: ActiveView,
  elementIndex: number,
  colorIndex?: number
) =>
  `${designFormikPrefix(activeView, colorIndex)}.element_list[${elementIndex}]`;
