/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FusionMelcoModelsColorCollection,
    FusionMelcoModelsColorCollectionFromJSON,
    FusionMelcoModelsColorCollectionFromJSONTyped,
    FusionMelcoModelsColorCollectionToJSON,
    FusionMelcoModelsDesignElementColor,
    FusionMelcoModelsDesignElementColorFromJSON,
    FusionMelcoModelsDesignElementColorFromJSONTyped,
    FusionMelcoModelsDesignElementColorToJSON,
    FusionMelcoModelsDesignLetteringElement,
    FusionMelcoModelsDesignLetteringElementFromJSON,
    FusionMelcoModelsDesignLetteringElementFromJSONTyped,
    FusionMelcoModelsDesignLetteringElementToJSON,
} from './';

/**
 * 
 * @export
 * @interface FusionMelcoModelsDesignElement
 */
export interface FusionMelcoModelsDesignElement {
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoModelsDesignElement
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoModelsDesignElement
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoModelsDesignElement
     */
    type?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FusionMelcoModelsDesignElement
     */
    editable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FusionMelcoModelsDesignElement
     */
    is_required?: boolean;
    /**
     * 
     * @type {FusionMelcoModelsDesignElementColor}
     * @memberof FusionMelcoModelsDesignElement
     */
    color?: FusionMelcoModelsDesignElementColor;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoModelsDesignElement
     */
    color_collection_id?: string | null;
    /**
     * 
     * @type {FusionMelcoModelsColorCollection}
     * @memberof FusionMelcoModelsDesignElement
     */
    color_collection?: FusionMelcoModelsColorCollection;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoModelsDesignElement
     */
    default_color_id?: string | null;
    /**
     * 
     * @type {FusionMelcoModelsDesignLetteringElement}
     * @memberof FusionMelcoModelsDesignElement
     */
    lettering?: FusionMelcoModelsDesignLetteringElement;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoModelsDesignElement
     */
    active_color_group_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoModelsDesignElement
     */
    lettering_id?: string | null;
}

export function FusionMelcoModelsDesignElementFromJSON(json: any): FusionMelcoModelsDesignElement {
    return FusionMelcoModelsDesignElementFromJSONTyped(json, false);
}

export function FusionMelcoModelsDesignElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoModelsDesignElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'editable': !exists(json, 'editable') ? undefined : json['editable'],
        'is_required': !exists(json, 'is_required') ? undefined : json['is_required'],
        'color': !exists(json, 'color') ? undefined : FusionMelcoModelsDesignElementColorFromJSON(json['color']),
        'color_collection_id': !exists(json, 'color_collection_id') ? undefined : json['color_collection_id'],
        'color_collection': !exists(json, 'color_collection') ? undefined : FusionMelcoModelsColorCollectionFromJSON(json['color_collection']),
        'default_color_id': !exists(json, 'default_color_id') ? undefined : json['default_color_id'],
        'lettering': !exists(json, 'lettering') ? undefined : FusionMelcoModelsDesignLetteringElementFromJSON(json['lettering']),
        'active_color_group_id': !exists(json, 'active_color_group_id') ? undefined : json['active_color_group_id'],
        'lettering_id': !exists(json, 'lettering_id') ? undefined : json['lettering_id'],
    };
}

export function FusionMelcoModelsDesignElementToJSON(value?: FusionMelcoModelsDesignElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'editable': value.editable,
        'is_required': value.is_required,
        'color': FusionMelcoModelsDesignElementColorToJSON(value.color),
        'color_collection_id': value.color_collection_id,
        'color_collection': FusionMelcoModelsColorCollectionToJSON(value.color_collection),
        'default_color_id': value.default_color_id,
        'lettering': FusionMelcoModelsDesignLetteringElementToJSON(value.lettering),
        'active_color_group_id': value.active_color_group_id,
        'lettering_id': value.lettering_id,
    };
}


