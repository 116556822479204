import classNames from "classnames";

import { Spinner, SpinnerProps } from "../spinner/Spinner";

import "./Loader.css";

type LoaderProps = {
  spinnerSize?: Pick<SpinnerProps, "size">["size"];
  text?: string;
  backgroundTransparent?: boolean;
};

const Loader: React.FC<LoaderProps> = ({
  spinnerSize,
  text,
  backgroundTransparent,
}) => (
  <div
    className={classNames("mfc__loader", {
      "mfc__loader-background-transparent": backgroundTransparent,
    })}
  >
    <Spinner size={spinnerSize} />
    {text && <span>{text}</span>}
  </div>
);

export { Loader };
