/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FusionMelcoFusionModelsCustomProductView,
    FusionMelcoFusionModelsCustomProductViewFromJSON,
    FusionMelcoFusionModelsCustomProductViewFromJSONTyped,
    FusionMelcoFusionModelsCustomProductViewToJSON,
    FusionMelcoFusionModelsUserBlank,
    FusionMelcoFusionModelsUserBlankFromJSON,
    FusionMelcoFusionModelsUserBlankFromJSONTyped,
    FusionMelcoFusionModelsUserBlankToJSON,
    FusionMelcoModelsCustomWarning,
    FusionMelcoModelsCustomWarningFromJSON,
    FusionMelcoModelsCustomWarningFromJSONTyped,
    FusionMelcoModelsCustomWarningToJSON,
    FusionMelcoModelsPreview,
    FusionMelcoModelsPreviewFromJSON,
    FusionMelcoModelsPreviewFromJSONTyped,
    FusionMelcoModelsPreviewToJSON,
} from './';

/**
 * 
 * @export
 * @interface FusionMelcoFusionModelsCustomProduct
 */
export interface FusionMelcoFusionModelsCustomProduct {
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsCustomProduct
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsCustomProduct
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsCustomProduct
     */
    name?: string | null;
    /**
     * 
     * @type {FusionMelcoFusionModelsUserBlank}
     * @memberof FusionMelcoFusionModelsCustomProduct
     */
    blank?: FusionMelcoFusionModelsUserBlank;
    /**
     * 
     * @type {Array<FusionMelcoFusionModelsCustomProductView>}
     * @memberof FusionMelcoFusionModelsCustomProduct
     */
    view_list?: Array<FusionMelcoFusionModelsCustomProductView> | null;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsCustomProduct
     */
    version?: number;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsCustomProduct
     */
    publish_state?: number;
    /**
     * 
     * @type {Date}
     * @memberof FusionMelcoFusionModelsCustomProduct
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FusionMelcoFusionModelsCustomProduct
     */
    publish_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FusionMelcoFusionModelsCustomProduct
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {Array<FusionMelcoModelsPreview>}
     * @memberof FusionMelcoFusionModelsCustomProduct
     */
    default_previews?: Array<FusionMelcoModelsPreview> | null;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsCustomProduct
     */
    configurator_version?: number | null;
    /**
     * 
     * @type {Array<FusionMelcoModelsCustomWarning>}
     * @memberof FusionMelcoFusionModelsCustomProduct
     */
    warning_list?: Array<FusionMelcoModelsCustomWarning> | null;
}

export function FusionMelcoFusionModelsCustomProductFromJSON(json: any): FusionMelcoFusionModelsCustomProduct {
    return FusionMelcoFusionModelsCustomProductFromJSONTyped(json, false);
}

export function FusionMelcoFusionModelsCustomProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoFusionModelsCustomProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'blank': !exists(json, 'blank') ? undefined : FusionMelcoFusionModelsUserBlankFromJSON(json['blank']),
        'view_list': !exists(json, 'view_list') ? undefined : (json['view_list'] === null ? null : (json['view_list'] as Array<any>).map(FusionMelcoFusionModelsCustomProductViewFromJSON)),
        'version': !exists(json, 'version') ? undefined : json['version'],
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'publish_date': !exists(json, 'publish_date') ? undefined : (json['publish_date'] === null ? null : new Date(json['publish_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'default_previews': !exists(json, 'default_previews') ? undefined : (json['default_previews'] === null ? null : (json['default_previews'] as Array<any>).map(FusionMelcoModelsPreviewFromJSON)),
        'configurator_version': !exists(json, 'configurator_version') ? undefined : json['configurator_version'],
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(FusionMelcoModelsCustomWarningFromJSON)),
    };
}

export function FusionMelcoFusionModelsCustomProductToJSON(value?: FusionMelcoFusionModelsCustomProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'token': value.token,
        'name': value.name,
        'blank': FusionMelcoFusionModelsUserBlankToJSON(value.blank),
        'view_list': value.view_list === undefined ? undefined : (value.view_list === null ? null : (value.view_list as Array<any>).map(FusionMelcoFusionModelsCustomProductViewToJSON)),
        'version': value.version,
        'publish_state': value.publish_state,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date === null ? null : value.publish_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'default_previews': value.default_previews === undefined ? undefined : (value.default_previews === null ? null : (value.default_previews as Array<any>).map(FusionMelcoModelsPreviewToJSON)),
        'configurator_version': value.configurator_version,
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(FusionMelcoModelsCustomWarningToJSON)),
    };
}


