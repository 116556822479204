import classNames from "classnames";

import "./ModalPicker.css";

type ModalPickerProps = {
  isVisible?: boolean;
  title?: string;
  buttons?: React.ReactNode[];
  children?: React.ReactNode;
};

const ModalPicker: React.FC<ModalPickerProps> = ({
  isVisible,
  title,
  buttons,
  children,
}) => (
  <div
    className={classNames("mfc__modal-picker", {
      "mfc__modal-picker-visible": isVisible,
    })}
  >
    <div className="mfc__modal-picker-inner">
      {title && <h1>{title}</h1>}
      {children}
      {buttons && <div className="mfc__modal-picker-buttons">{buttons}</div>}
    </div>
  </div>
);

export { ModalPicker };
