/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FusionMelcoFusionModelsPersonalizationDownloads
 */
export interface FusionMelcoFusionModelsPersonalizationDownloads {
    /**
     * 
     * @type {Array<string>}
     * @memberof FusionMelcoFusionModelsPersonalizationDownloads
     */
    personalization_list?: Array<string> | null;
}

export function FusionMelcoFusionModelsPersonalizationDownloadsFromJSON(json: any): FusionMelcoFusionModelsPersonalizationDownloads {
    return FusionMelcoFusionModelsPersonalizationDownloadsFromJSONTyped(json, false);
}

export function FusionMelcoFusionModelsPersonalizationDownloadsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoFusionModelsPersonalizationDownloads {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personalization_list': !exists(json, 'personalization_list') ? undefined : json['personalization_list'],
    };
}

export function FusionMelcoFusionModelsPersonalizationDownloadsToJSON(value?: FusionMelcoFusionModelsPersonalizationDownloads | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personalization_list': value.personalization_list,
    };
}


