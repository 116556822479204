import { LRUManager, InternalReload, ElementCacheData, AlphabetCacheData, LRUNodeData } from "./memorymanager";
import { Matrix, Rectangle, MatrixUtil, RectangleUtil } from "./geom";
import { NumberToSelectionBoxHit, StitchElement, BaseElement, ElementType, ImageElement, HitTestInfo, StitchMetadata, StitchSubElement, ImageMetadata, SelectionBox, WireframeType, SelectionBoxHit, SelectionBoxUtil, GridElement } from "./elements";
import { Alphabet, AlphabetMetadata } from "./alphabet";
import { DesignParams, LetteringParams, ThreadColor } from "./elementparams";
import { RenderModule, WireframeElement, SelectionBoxElement, RenderElement, SubElement } from "../wasm/MelcoRendererApp";
import { RenderScene, WireframeLayer } from "./scene";

export class StitchElementUtil {
    private _designCache: LRUManager = new LRUManager();
    private _alphabetCache: LRUManager = new LRUManager();
    private _elemCache: LRUManager = new LRUManager();
    private _module: RenderModule;
    private wireframeElement?: WireframeElement;
    private internalSelectionBox?: SelectionBoxElement;
    private _noopid = 1;
    constructor(module: RenderModule) {
        this._module = module;
    }
    public destroy() {
        if (this.wireframeElement) {
            this.wireframeElement.delete();
        }
        if (this.internalSelectionBox) {
            this.internalSelectionBox.delete();
        }
        this._alphabetCache.destroy();
        this._designCache.destroy();
        this._elemCache.destroy();
    }
    public _internalGetFromElemCache(elem: StitchElement): LRUNodeData | undefined {
        this._internalSyncElement(elem, () => {}, new Set<RenderElement>(), false);
        let checkElem = this._elemCache.getItemByKey(elem.uid);
        return checkElem;
    }
    public calcRectForTransform(elem: StitchElement, m: Matrix): Rectangle {
        let checkElem = this._internalGetFromElemCache(elem);
        if (checkElem && checkElem.element_item && checkElem.element_item.element) {
            let r = checkElem.element_item.element.getCalcRect(m.m00, m.m01, m.m02, m.m10, m.m11, m.m12);
            let ret = {
                llx: r.llx, 
                lly: r.lly, 
                urx: r.urx, 
                ury: r.ury
            };
            r.delete();
            return ret;
        }
        console.error("Element not in cache");
        return {
            llx: 0,
            lly: 0,
            urx: 0,
            ury: 0
        };
    }
    /** Perform hit test on design element */
    public hitTest(elem: StitchElement, x: number, y: number, thresholdInPts: number): HitTestInfo {
       let r: HitTestInfo = {
          hit: false
       };
       let checkElem = this._internalGetFromElemCache(elem);
       if (checkElem && checkElem.element_item && checkElem.element_item.element) {
           let h = checkElem.element_item.element.hitTest(x, y, thresholdInPts);
           r = {
            hit: h.hit,
            subElementHit: h.subElementHit,
            colorIndex: h.colorIndex,
            subElementColorIndexHit: h.subElementColorIndexHit,
            distanceInPoints: h.distanceInPoints
           };
       }
       return r;
    }
    public setLRUCacheSize(designItems: number = 5, alphabetItems: number = 5, elementItems: number = 5) {
        this._designCache.setMaxElements(designItems);
        this._alphabetCache.setMaxElements(alphabetItems);
        this._elemCache.setMaxElements(elementItems);
    }
    public async ensureImageLoaded(image: ImageElement, noopid: number = 0): Promise<number> {
        let ret = noopid;
        let data = this._designCache.getItemByKey(image.image_params.imageUrl);
        let params = image.image_params;
        if (!data || !data.download) {
            let p = new Promise<InternalReload>((resolve, reject) => {
                this._module.Renderer.loadGraphic(params.imageUrl, (elem, xdim, ydim) => {
                    let i: InternalReload= new InternalReload(elem);
                    i.xdim = xdim;
                    i.ydim = ydim;
                    let defaultDpi = params.defaultDpi ? params.defaultDpi : 96;
                    let defaultCenterX = params.defaultCenterX ? params.defaultCenterX : 0;
                    let defaultCenterY = params.defaultCenterY ? params.defaultCenterY : 0;
                    let widthPts = xdim / defaultDpi * 254;
                    let heightPts = ydim / defaultDpi * 254;
                    i.rect = {llx: -.5 * widthPts + defaultCenterX, lly: -.5 * heightPts + defaultCenterY,
                        urx: .5 * widthPts + defaultCenterX, ury: .5 * heightPts + defaultCenterY};
                    var a = new this._module.AffineMatrix();
                    let r = new this._module.Rectangle();
                    r.llx = i.rect.llx;
                    r.lly = i.rect.lly;
                    r.urx = i.rect.urx;
                    r.urx = i.rect.ury;
                    elem.setImageMatrix(r, a);
                    r.delete();
                    a.delete();
                    resolve(i);
                }, message => {
                    reject(new Error(message));
                });
            });
            data = this._designCache.addStitchElementItem(image.image_params.imageUrl, p, 0);
            if (data.item) {
                let cacheItem = new ElementCacheData();
                cacheItem.internalReload = data.item;
                this._elemCache.addStitchElementCacheItem(image.uid, cacheItem);
            }
        }
        if (data && !data.item && data.download) {
            await data.download;
        }
        if (!image.rect && data && data.item) {
            image.rect = data.item.rect;
        }
        if (data && data.download) {
            return data.download;
        }
        return ret;
    }
    public doSyncLettering(st: StitchElement) {
        let result = false
        let data = this._elemCache.getItemByKey(st.uid);
        if (data && data.element_item && st.letteringParams && st.letteringParams.length > 0 &&
                st.letteringParams[0].alphabet) {
            let checkLoaded = this._internalCheckAlphabetLoaded(st.letteringParams[0].alphabet);
            if (checkLoaded && data.element_item.element) {
                result = true
                const i = 0
                let alphabet = st.letteringParams[i].alphabet;
                let text = st.letteringParams[i].text;
                if (st.letteringParams[i].baseline_type) {
                    let internal_alphabet_name = data.element_item.element.getLetteringAlphabet(i);
                    let lParams = data.element_item.element.getLetterParams(i)
                    let internal_params = data.element_item.element
                    let baseline_type = st.letteringParams[i].baseline_type || 'Straight'
                    let radius = baseline_type === 'Arced' ? st.letteringParams[i].radius : 0
                    let height = st.letteringParams[i].height
                    if (internal_alphabet_name != checkLoaded.alphabet_name || height != lParams.height || lParams.text != text || lParams.baseline_type != baseline_type || lParams.radius != radius) {
                        lParams.baseline_type = baseline_type
                        lParams.height = height
                        lParams.radius = radius ? radius : 0
                        lParams.text = text
                        data.element_item.element.setFullLetteringParams(i, lParams, checkLoaded.internalAlphabet);
                        console.log("Update lettering params V: ", st.letteringParams[i]);
                    }
                    lParams.delete()
                } else {
                    let internal_alphabet_name = data.element_item.element.getLetteringAlphabet(i);
                    let internal_text = data.element_item.element.getLetteringText(i);
                    if (internal_alphabet_name != checkLoaded.alphabet_name || internal_text != text) {
                        data.element_item.element.setLetteringParams(i, text, checkLoaded.internalAlphabet);
                        console.log("Update lettering text V: ", text);
                    }
                }
            }
        } else if (!data && st.letteringParams && st.letteringParams.length > 0 && st.letteringParams[0].alphabet) {
            let checkLoaded = this._internalCheckAlphabetLoaded(st.letteringParams[0].alphabet);
            if (checkLoaded) {
                result = true
                console.log("Create lettering")
                const loadElement = new ElementCacheData();
                let lParams = new this._module.LetterParams()
                lParams.text = st.letteringParams[0].text
                if (st.letteringParams[0].height > 0) {
                    lParams.height = st.letteringParams[0].height
                }
                if (st.letteringParams[0].radius && st.letteringParams[0].baseline_type === 'Arced') {
                    lParams.radius = st.letteringParams[0].radius
                    lParams.baseline_type = 'Arced'
                }

                loadElement._element = this._module.Renderer.generateLettering(lParams, checkLoaded.internalAlphabet);
                this._internalAddCache(st, loadElement);
                data = this._elemCache.getItemByKey(st.uid);
                lParams.delete()
            }
        }
        return result
    }
    public getStitchElementMetadataIfLoaded(st: StitchElement): StitchMetadata | undefined {
        if (st.design_params) {
            let data = this._designCache.getItemByKey(st.design_params.designMetadataUrl);
            if (data && data.item) {
                return {
                    stitchCount: data.item.stitchCount,
                    originalBoundingBox: data.item.rect,
                    originalColors: data.item.colors,
                    originalSubElements: data.item.subElements,
                    originalLetteringParams: data.item.letteringParams,
                }
            }
        }
        if (st.type == ElementType.LETTERING && st.letteringParams && st.letteringParams.length > 0
                && st.letteringParams[0].alphabet) {
            this.doSyncLettering(st)
        }
        let data = this._elemCache.getItemByKey(st.uid);
        if (data && data.item) {
            return {
                stitchCount: data.item.stitchCount,
                originalBoundingBox: data.item.rect,
                originalColors: data.item.colors,
                originalSubElements: data.item.subElements,
                originalLetteringParams: data.item.letteringParams,
            }
        }
        if (data && data.element_item && data.element_item.element) {
            const box = data.element_item.element.getRect()
            const subElements = data.element_item.element.getSubElements()
            var numSubElements = subElements.size();
            const ses = [] as StitchSubElement[]
            for (var j=0; j < numSubElements; j++) {
                let sub = subElements.get(j);
                let ci: Array<number> = [];
                for (var k=0;k<sub.colorIndexes.size();k++) {
                    ci.push(sub.colorIndexes.get(k));
                }
                let sci: Array<boolean> = [];
                for (var k=0;k<sub.selectedColorIndexes.size();k++) {
                    sci.push(sub.selectedColorIndexes.get(k) ? true : false);
                }
                let subElement: StitchSubElement = {
                    internalSubElementIndex: sub.subIdx,
                    letteringIndex: sub.letteringIndex,
                    colorsIndexes: ci,
                    selectedColorIndexes: sci.length ? sci : undefined,
                }
                ses.push(subElement);
                sub.delete();
            }
            subElements.delete();
            const rect = {llx: box.llx, lly: box.lly, urx: box.urx, ury: box.ury}
            box.delete()
            console.log("NewST", data.element_item.element.getStitchCount())
            return {
                stitchCount: data.element_item.element.getStitchCount(),
                originalBoundingBox: rect,
                originalColors: [],
                originalSubElements: ses,
                originalLetteringParams: [],
            }
        }
    }
    public async ensureElementLoaded(e: StitchElement, noopid: number = 0, updateMetadata: boolean = false): Promise<number> {
        let ret = noopid;
        if (e.design_params) {
            let params: DesignParams = e.design_params;
            let data = this._designCache.getItemByKey(params.designMetadataUrl);
            if (!data || !data.download) {
                let p = new Promise<InternalReload>((resolve, reject) => {
                    this._module.Renderer.loadDesign(params.designMetadataUrl, renderElement => {
                        let ir: InternalReload= new InternalReload(renderElement);
                        ir.stitchCount = renderElement.getStitchCount();
                        let r = renderElement.getRect();
                        ir.rect = {llx: r.llx, lly: r.lly, urx: r.urx, ury: r.ury};
                        if (params && params.colors) {
                            ir.colors = params.colors;
                        } else {
                            var clrs = renderElement.getColors();
                            if (clrs.size() > 0) {
                                ir.colors = [];
                                for (var i=0;i<clrs.size();i++) {
                                    let clr = clrs.get(i);
                                    let convert: ThreadColor = {
                                        red: clr.red,
                                        green: clr.green,
                                        blue: clr.blue,
                                        productLine: clr.productLine,
                                        name: clr.name,
                                        manufacturer: clr.manufacturer,
                                        code: clr.code
                                    }
                                    ir.colors.push(convert);
                                    clr.delete();
                                }
                            }
                            clrs.delete();
                        }
                        var numLetters = renderElement.getNumLetteringElems();
                        for (var j=0; j < numLetters; j++) {
                            let alphabet_name = renderElement.getLetteringAlphabet(j);
                            let text = renderElement.getLetteringText(j);
                            let height = renderElement.getLetteringHeight(j);
                            let lparams: LetteringParams = {
                                text: text,
                                height: height,
                                original_alphabet_name: alphabet_name
                            }
                            ir.letteringParams.push(lparams);
                        }
                        var subElements = renderElement.getSubElements();
                        var numSubElements = subElements.size();
                        for (var j=0; j < numSubElements; j++) {
                            let sub = subElements.get(j);
                            let ci: Array<number> = [];
                            for (var k=0;k<sub.colorIndexes.size();k++) {
                                ci.push(sub.colorIndexes.get(k));
                            }
                            let sci: Array<boolean> = [];
                            for (var k=0;k<sub.selectedColorIndexes.size();k++) {
                                sci.push(sub.selectedColorIndexes.get(k) ? true : false);
                            }
                            let subElement: StitchSubElement = {
                                internalSubElementIndex: sub.subIdx,
                                letteringIndex: sub.letteringIndex,
                                colorsIndexes: ci,
                                selectedColorIndexes: sci.length ? sci: undefined
                            }
                            ir.subElements.push(subElement);
                            sub.delete();
                        }
                        subElements.delete();
                        resolve(ir);
                    }, message => {
                        reject(new Error(message));
                    });
                });
                data = this._designCache.addStitchElementItem(params.designMetadataUrl, p, noopid);
                if (data.item) {
                    let cacheItem = new ElementCacheData();
                    cacheItem.internalReload = data.item;
                    this._elemCache.addStitchElementCacheItem(e.uid, cacheItem)
                }
            }  
            if (data && !data.item && data.download) {
                await data.download;
            }
            if (data && data.item) {
                const node = this._elemCache.getItemByKey(e.uid)
                if (node?.item?.key !== data.item?.key) {
                    console.log("Replacing Element Node")
                    let cacheItem = new ElementCacheData()
                    cacheItem.internalReload = data.item
                    this._elemCache.addStitchElementCacheItem(e.uid, cacheItem)
                }
            }
            if (updateMetadata && !e.letteringParams && data && data.item) {
                e.letteringParams = data.item.letteringParams;
            }
            if (updateMetadata && !e.colors && data && data.item) {
                e.colors = data.item.colors;
            }
            if (updateMetadata && !e.subElements && data && data.item) {
                e.subElements = data.item.subElements;
            }
            if (data && data.download) {
                return data.download;
            }
        } else if (e.letteringParams && e.letteringParams.length > 0) {
            const lp = e.letteringParams[0]
            if (lp.alphabet) {
                return this.ensureAlphabetLoaded(lp.alphabet, noopid)
            }
        }
        return ret;
    }
    public getAlphabetMetadataIfLoaded(a: Alphabet): AlphabetMetadata | undefined {
        if (a.alphabet_metadata_path) {
            let alphabet_path = a.alphabet_metadata_path;
            let data = this._alphabetCache.getItemByKey(alphabet_path);
            if (data && data.alphabet_item) {
                return {
                    alphabet_name: data.alphabet_item.alphabet_name,
                    valid_characters: data.alphabet_item.characters
                }
            }
        }
    }
    public async ensureAlphabetLoaded(a: Alphabet, noopid: number = 0): Promise<number> {
        let ret = noopid;
        if (a.alphabet_metadata_path) {
            let alphabet_path = a.alphabet_metadata_path;
            let data = this._alphabetCache.getItemByKey(alphabet_path);
            if (!data || !data.download) {
                var p: Promise<AlphabetCacheData> = new Promise<AlphabetCacheData>((resolve, reject) => {
                    this._module.Renderer.loadAlphabet(alphabet_path, alphabet => {
                            let data = new AlphabetCacheData(alphabet);
                            resolve(data);
                        }, message => {
                            reject(new Error(message));
                        }
                    );
                });
                data = this._alphabetCache.addAlphabetItem(alphabet_path, p, noopid);
            }
            if (data && !data.item && data.download) {
                await data.download;
            }
            if (data && data.download) {
                return data.download;
            }
        }
        return ret;
    }
    public checkAlphabetLoaded(alphabet: Alphabet): boolean {
        let c = this._internalCheckAlphabetLoaded(alphabet);
        return c ? true : false;
    }
    public _internalCheckAlphabetLoaded(alphabet: Alphabet): AlphabetCacheData | undefined {
        if (alphabet.alphabet_metadata_path) {
            let ad = this._alphabetCache.getItemByKey(alphabet.alphabet_metadata_path);
            if (ad) {
                return ad.alphabet_item;
            }
        }
        return undefined;
    }
    public checkDesignLoaded(stitchElement: StitchElement): boolean {
        let c = this._internalCheckDesignLoaded(stitchElement);
        return c ? true : false;
    }
    public _internalCheckDesignLoaded(stitchElement: StitchElement): InternalReload | undefined {
        if (stitchElement.design_params) {
            let data = this._designCache.getItemByKey(stitchElement.design_params.designMetadataUrl);
            if (data) {
                return data.item;
            }
        }
        return undefined;
    }
    public checkImageLoaded(image: ImageElement): boolean {
        let c = this._internalCheckImageLoaded(image);
        return c ? true : false;
    }
    public _internalCheckImageLoaded(image: ImageElement): InternalReload | undefined {
        let data = this._designCache.getItemByKey(image.image_params.imageUrl);
        if (data) {
            return data.item;
        }
        return undefined;
    }
    public _internalCheckCached(elem: BaseElement): ElementCacheData | undefined {
        let data = this._elemCache.getItemByKey(elem.uid);
        if (data) {
            if (data.item) {
                this._designCache.getItemByKey(data.item.key);
            }
            return data.element_item;
        }
        return undefined;
    }
    public _internalAddCache(elem: BaseElement, r: ElementCacheData): LRUNodeData {
        return this._elemCache.addStitchElementCacheItem(elem.uid, r);
    }
    public _internalIncrementIterNumber() {
        this._alphabetCache.incrementIter();
        this._designCache.incrementIter();
        this._elemCache.incrementIter();
    }
    public getImageMetadataIfLoaded(image: ImageElement): ImageMetadata | undefined {
        let data = this._designCache.getItemByKey(image.image_params.imageUrl);
        if (data && data.item) {
            return {
                "xdim": data.item.xdim,
                "ydim": data.item.ydim,
                "defaultRect": data.item.rect
            }
        }
    }
    public _internalGetSelectionBoxRect(wf?: WireframeLayer, scene?: RenderScene): WireframeElement | undefined {
        if (wf && scene) {
            let b: SelectionBox | undefined = undefined;
            for (let e of wf.elements) {
                if (e) {
                    if (e.type == WireframeType.SELECTION_BOX) {
                        b = e;
                    }
                }
            }
            if (b && b.display) {
                if (!this.wireframeElement) {
                    this.wireframeElement = this._module.Renderer.createWireframeElement();
                    this.internalSelectionBox = this._module.Renderer.createSelectionBoxElement();
                    this.wireframeElement.setSelectionBox(this.internalSelectionBox);
                }
                if (this.internalSelectionBox) {
                    this.internalSelectionBox.setIsDisplayed(b.display);
                    this.internalSelectionBox.setIsRotate(b.isRotate);
                    let rbounds: Rectangle | undefined = b.rect;
                    if (!rbounds && b.selectedElemIds && b.selectedElemIds.length > 0) {
                        let identity = MatrixUtil.identityMatrix();
                        let s = new Set<string>(b.selectedElemIds);
                        for (let e of scene.elements) {
                            if (e && s.has(e.uid) && e.type != ElementType.IMAGE) {
                                let selem = e as StitchElement;
                                let r = this.calcRectForTransform(selem, selem.matrix ? selem.matrix : identity);
                                if (rbounds) {
                                     rbounds = RectangleUtil.union(rbounds, r);
                                } else {
                                    rbounds = r;
                                }
                            }
                        }
                    } 
                    if (rbounds) {
                        let r = new this._module.Rectangle();
                        r.llx = rbounds.llx;
                        r.lly = rbounds.lly;
                        r.urx = rbounds.urx;
                        r.ury = rbounds.ury;
                        this.internalSelectionBox.setRect(r);
                        r.delete();
                    }
                    else {
                        this.internalSelectionBox.setIsDisplayed(false);
                    }
                    if (b.matrix) {
                        let a = new this._module.AffineMatrix();
                        a.m00 = b.matrix.m00;
                        a.m01 = b.matrix.m01;
                        a.m02 = b.matrix.m02;
                        a.m10 = b.matrix.m10;
                        a.m11 = b.matrix.m11;
                        a.m12 = b.matrix.m12;
                        this.internalSelectionBox.setMatrix(a);
                        a.delete();
                    } else {
                        this.internalSelectionBox.resetMatrix();
                    }
                }
                return this.wireframeElement;
            }
        }
        if (this.wireframeElement && this.internalSelectionBox) {
            this.internalSelectionBox.setIsDisplayed(false);
            return this.wireframeElement;
        }
        return undefined;
    }
    public hitTestSelectionBox(x: number, y: number): SelectionBoxHit {
        if (this.internalSelectionBox && this.internalSelectionBox.isDisplayed()) {
            let h = this.internalSelectionBox.hitTest(x, y);
            let v = h.value;
            return NumberToSelectionBoxHit(v);
        }
        return SelectionBoxHit.hitNothing;
    }
    public getSelectionBoxRect(): SelectionBox {
        let r = SelectionBoxUtil.createEmpty();
        if (this.internalSelectionBox) {
            r.display = this.internalSelectionBox.isDisplayed();
            r.isRotate = this.internalSelectionBox.getIsRotate();
            let rect = this.internalSelectionBox.getRectangle();
            r.rect = RectangleUtil.createRect(rect.llx, rect.lly, rect.urx, rect.ury);
            rect.delete();
            let a = this.internalSelectionBox.getMatrix();
            let m = MatrixUtil.createMatrix(a.m00, a.m01, a.m02, a.m10, a.m11, a.m12);
            if (!MatrixUtil.isIdentity(m)) {
                r.matrix = m;
            }
            a.delete();
        }
        return r;
    }

    public _internalSyncElement(element: (StitchElement | ImageElement | GridElement | null), downloadedCallback: (isFirst: boolean) => void,
            excludeElements: Set<RenderElement>, downloadIfNeeded: boolean): ElementCacheData | undefined {
        let ret = undefined as (ElementCacheData | undefined);
        if (element) {
            let e = element;
            let addElem = true;
            let loadElement: ElementCacheData | undefined = undefined;
            if (e) {
                loadElement = this._internalCheckCached(e);
            }
            if (!loadElement) {
                if (e && e.type == ElementType.IMAGE) {
                    let im = e as ImageElement;
                    let checkLoaded = this._internalCheckImageLoaded(im);
                    let noop = this._noopid++;
                    if (!checkLoaded) {
                        if (downloadIfNeeded) {
                            console.debug("Lazy Downloading image", im.image_params.imageUrl);
                            this.ensureImageLoaded(im, noop).then((v) => {
                                downloadedCallback(v == noop);
                            });
                        }
                    } else if (checkLoaded) {
                        loadElement = new ElementCacheData();
                        loadElement.internalReload = checkLoaded;
                        this._internalAddCache(e, loadElement);
                    }
                } else if (e && e.type == ElementType.GRID) {
                    let g = e as GridElement;
                    loadElement = new ElementCacheData();
                    loadElement._element = this._module.Renderer.createGridElement();
                    this._internalAddCache(e, loadElement);
                } else if (e) {
                    let se = e as StitchElement;
                    if (!se.design_params && se.letteringParams && se.letteringParams.length == 1) {
                        if (se.letteringParams[0].alphabet) {
                            let checkLoaded = this._internalCheckAlphabetLoaded(se.letteringParams[0].alphabet);
                            if (checkLoaded && checkLoaded.internalAlphabet) {
                                loadElement = new ElementCacheData();
                                let lParams = new this._module.LetterParams()
                                lParams.text = se.letteringParams[0].text
                                if (se.letteringParams[0].height > 0) {
                                    lParams.height = se.letteringParams[0].height
                                }
                                if (se.letteringParams[0].radius && se.letteringParams[0].baseline_type === 'Arced') {
                                    lParams.radius = se.letteringParams[0].radius
                                    lParams.baseline_type = 'Arced'
                                }

                                loadElement._element = this._module.Renderer.generateLettering(lParams, checkLoaded.internalAlphabet);
                                this._internalAddCache(e, loadElement);
                                lParams.delete()
                            } else if (!checkLoaded) {
                                addElem = false;
                                if (downloadIfNeeded) {
                                    console.debug("Lazy Downloading alphabet", se.letteringParams[0].alphabet.alphabet_metadata_path);
                                    let noop = this._noopid++;
                                    this.ensureAlphabetLoaded(se.letteringParams[0].alphabet, noop).then((v) => {
                                        downloadedCallback(v == noop);
                                    });
                                }
                            }
                        } else {
                            addElem = false;
                        }
                    } else {
                        let checkLoaded = this._internalCheckDesignLoaded(se);
                        let noop = this._noopid++;
                        if (!checkLoaded) {
                            if (downloadIfNeeded) {
                                console.debug("Lazy Downloading element", se.design_params ? se.design_params.designMetadataUrl : "");
                                this.ensureElementLoaded(se, noop).then((v) => {
                                    downloadedCallback(v == noop);
                                });
                            }
                        } else if (checkLoaded) {
                            loadElement = new ElementCacheData();
                            loadElement.internalReload = checkLoaded;
                            this._internalAddCache(e, loadElement);
                        }
                    }
                }
            }
            if (e && loadElement && loadElement.element) {
                if (excludeElements.has(loadElement.element)) {
                    loadElement = loadElement.clone();
                    this._internalAddCache(e, loadElement);
                } else {
                    excludeElements.add(loadElement.element);
                }
                if ((e.type == ElementType.DESIGN || e.type == ElementType.IMAGE || e.type == ElementType.LETTERING) && loadElement.element) {
                    var a = new this._module.AffineMatrix();
                    var m: Matrix | undefined;
                    if (e.type == ElementType.IMAGE) {
                        let im = e as ImageElement;
                        m = im.matrix;
                    } else {
                        let se = e as StitchElement;
                        m = se.matrix;
                    }
                    if (m) {
                        a.m00 = m.m00;
                        a.m01 = m.m01;
                        a.m02 = m.m02;
                        a.m10 = m.m10;
                        a.m11 = m.m11;
                        a.m12 = m.m12;
                    }
                    if (e.type != ElementType.IMAGE) {
                        loadElement.element.setMatrix(a);
                    } else {
                        let im = e as ImageElement;
                        let srect = im.rect;
                        if (!srect && loadElement.internalReload) {
                            srect = loadElement.internalReload.rect;
                        }
                        let r = new this._module.Rectangle();
                        if (srect && loadElement.element) {
                            r.llx = srect.llx;
                            r.lly = srect.lly;
                            r.urx = srect.urx;
                            r.ury = srect.ury;
                            loadElement.element.setImageMatrix(r, a);
                        } else {
                            console.log("Error: image not cached");
                            addElem = false;
                        }
                        r.delete();
                    }
                    a.delete();
                }
                if (e.type != ElementType.IMAGE && e.type != ElementType.GRID && loadElement.element) {
                    let revertColors = true;
                    let se = e as StitchElement;
                    if (se.colors && se.colors.length > 0) {
                        revertColors = false;
                    }
                    if (se.subElements && se.subElements.length > 0) {
                        revertColors = false;
                    }
                    loadElement.element.revertColorChanges();
                    if (!revertColors) {
                        if (se.colors && se.colors.length > 0) {
                            var clrs = new this._module.VectorThreadColor();
                            for (let c of se.colors) {
                                let g = new this._module.ThreadColor();
                                g.red = c.red;
                                g.green = c.green;
                                g.blue = c.blue;
                                if (c.name) {
                                    g.name = c.name;
                                }
                                if (c.manufacturer) {
                                    g.manufacturer = c.manufacturer;
                                }
                                if (c.productLine) {
                                    g.productLine = c.productLine;
                                }
                                if (c.code) {
                                    g.code = c.code;
                                }
                                clrs.push_back(g);
                            }
                            loadElement.element.setColors(clrs);
                            let sz = clrs.size();
                            for (let i = 0; i < sz; i++) {
                                clrs.get(i).delete(); 
                            }
                            clrs.delete();
                        }
                        if (se.subElements && se.subElements.length > 0) {
                            var subElements = new this._module.VectorSubElement();
                            for (let s of se.subElements) {
                                var subElem = new this._module.SubElement();
                                subElem.subIdx = s.internalSubElementIndex;
                                subElem.letteringIndex = s.letteringIndex;
                                let clri = new this._module.VectorInts();
                                for (let c of s.colorsIndexes) {
                                    clri.push_back(c);
                                }
                                subElem.colorIndexes = clri;
                                if (s.selectedColorIndexes) {
                                    let sc = new this._module.VectorInts();
                                    for (let c of s.selectedColorIndexes) {
                                        sc.push_back(c ? 1 : 0);
                                    }
                                    subElem.selectedColorIndexes = sc
                                    sc.delete()
                                }
                                subElements.push_back(subElem);
                                clri.delete();
                            }
                            loadElement.element.setSubElements(subElements);
                            let sz = subElements.size();
                            for (let i = 0; i < sz; i++) {
                                subElements.get(i).delete(); 
                            }
                            subElements.delete();
                        }
                    }
                }
                if (e.type != ElementType.IMAGE && e.type != ElementType.GRID && loadElement.element) {
                    let se = e as StitchElement;
                    let numLettering = 0;
                    if (loadElement.internalReload) {
                        numLettering = loadElement.internalReload.letteringParams.length;
                    } else if (loadElement.element) {
                        numLettering = loadElement.element.getNumLetteringElems();
                    }
                    for (let i=0;i<numLettering;i++) {
                        if (se.letteringParams && i < se.letteringParams.length) {
                            let alphabet = se.letteringParams[i].alphabet;
                            let text = se.letteringParams[i].text;
                            if (alphabet) {
                                let checkLoaded = this._internalCheckAlphabetLoaded(alphabet);
                                if (!checkLoaded || !checkLoaded.internalAlphabet) {
                                    if (downloadIfNeeded) {
                                        console.debug("Lazy Downloading alphabet", alphabet.alphabet_metadata_path);
                                        let noop = this._noopid++;
                                        this.ensureAlphabetLoaded(alphabet, noop).then((v) => {
                                            downloadedCallback(v == noop);
                                        });
                                    }
                                } else if (se.letteringParams[i].baseline_type) {
                                    let internal_alphabet_name = loadElement.element.getLetteringAlphabet(i);
                                    let lParams = loadElement.element.getLetterParams(i)
                                    let internal_params = loadElement.element
                                    let baseline_type = se.letteringParams[i].baseline_type || 'Straight'
                                    let radius = baseline_type === 'Arced' ? se.letteringParams[i].radius : 0
                                    let height = se.letteringParams[i].height
                                    if (internal_alphabet_name != checkLoaded.alphabet_name || height != lParams.height || lParams.text != text || lParams.baseline_type != baseline_type || lParams.radius != radius) {
                                        lParams.baseline_type = baseline_type
                                        lParams.height = height
                                        lParams.radius = radius ? radius : 0
                                        lParams.text = text
                                        loadElement.element.setFullLetteringParams(i, lParams, checkLoaded.internalAlphabet);
                                        console.log("Update lettering params: ", se.letteringParams[i]);
                                    }
                                    lParams.delete()
                                } else {
                                    let internal_alphabet_name = loadElement.element.getLetteringAlphabet(i);
                                    let internal_text = loadElement.element.getLetteringText(i);
                                    if (internal_alphabet_name != checkLoaded.alphabet_name || internal_text != text) {
                                        loadElement.element.setLetteringParams(i, text, checkLoaded.internalAlphabet);
                                        console.log("Update lettering text: ", text);
                                    }
                                }
                            } else if (loadElement.element.hasLetteringChanges(i)) {
                                loadElement.element.revertLetteringChanges(i);
                            }
                        } else if (loadElement.element.hasLetteringChanges(i)) {
                            loadElement.element.revertLetteringChanges(i);
                        }
                    }
                }
                if (e.type == ElementType.GRID && loadElement.element) {
                    let ge = e as GridElement;
                    if (ge.grid_params) {
                        let param = new this._module.GridParams();
                        if (ge.grid_params.gridColor) {
                            param.gridColor.red = ge.grid_params.gridColor.red;
                            param.gridColor.green = ge.grid_params.gridColor.green;
                            param.gridColor.blue = ge.grid_params.gridColor.blue;
                        }
                        if (ge.grid_params.originColor) {
                            param.originColor.red = ge.grid_params.originColor.red;
                            param.originColor.green = ge.grid_params.originColor.green;
                            param.originColor.blue = ge.grid_params.originColor.blue;
                        }
                        param.showGrid = ge.grid_params.showGrid;
                        param.showOrigin = ge.grid_params.showOrigin;
                        if (ge.grid_params.gridSize) {
                            param.gridSpaceX = ge.grid_params.gridSize;
                            param.gridSpaceY = ge.grid_params.gridSize;
                        }
                        if (ge.grid_params.gridSubDivs) {
                            param.subDivisions = ge.grid_params.gridSubDivs;
                        }
                        loadElement.element.setGridParams(param);
                        param.delete();
                    }
                }
                if (addElem && loadElement.element) {
                    ret = loadElement;
                }
            }
        }
        return ret;
    }


    
    public static cloneBaseElement(elem: BaseElement): BaseElement {
        if (elem.type == ElementType.IMAGE) {
            return StitchElementUtil.cloneImageElement(elem as ImageElement);
        } else {
            return StitchElementUtil.cloneStitchElement(elem as StitchElement);
        }
    }
    public static cloneStitchElement(elem: StitchElement): StitchElement {
        let clone = {...elem};
        if (clone.colors) {
            clone.colors = [...clone.colors]
        }
        if (clone.letteringParams) {
            clone.letteringParams = [...clone.letteringParams]
        }
        if (clone.subElements) {
            clone.subElements = [...clone.subElements];
        }
        return clone;
    }
    public static cloneImageElement(elem: ImageElement): ImageElement {
        let clone = {...elem};
        return clone;
    }
}