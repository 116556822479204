import classNames from "classnames";
import "./Switch.css";

type SwitchProps = {
  disabled?: boolean;
  children?: React.ReactNode;
};

const Switch: React.FC<SwitchProps> = ({ disabled, children }) => (
  <div
    className={classNames("mfc__switch", {
      "mfc__switch-disabled": disabled,
    })}
  >
    {children}
  </div>
);

export { Switch };
