import { PersonalizeEditItem } from "../personalize/editItem/PersonalizeEditItem";
import { InputField } from "../inputField/InputField";
import { ActiveView } from "../../hooks/useActiveView";
import { useLetteringText } from "../../hooks/useLetteringText";

type LetteringTextProps = {
  activeView: ActiveView;
  elementIndex: number;
};

export const LetteringText: React.FC<LetteringTextProps> = ({
  activeView,
  elementIndex,
}) => {
  const { help, count, error, ...field } = useLetteringText(
    activeView,
    elementIndex
  );

  return (
    <PersonalizeEditItem
      size="full"
      help={help}
      suffix={count}
      error={!!error}
      errorMessage={error}
    >
      <InputField {...field} />
    </PersonalizeEditItem>
  );
};
