import { Dispatch, useCallback } from "react";
import { BaseAction } from "@melco/renderer/dist/events";
import { FloatingButton } from "../floatingButton/FloatingButton";
import { ZoomInIcon, ZoomOutIcon } from "../icons/icons";
import "./ZoomButton.css";
import {
  ConfiguratorZoomToFitAction,
  ZoomedTo,
} from "../../renderer/ConfiguratorRendererActions";
import { StitchElementUtil } from "@melco/renderer";

type ZoomButtonProps = {
  zoomedTo: ZoomedTo;
  canvas: HTMLElement;
  rendererDispatch: Dispatch<BaseAction>;
  elementUtil: StitchElementUtil | undefined;
};

export const ZoomButton: React.FC<ZoomButtonProps> = ({
  zoomedTo,
  canvas,
  rendererDispatch,
  elementUtil,
}) => {
  const toggleZoom = useCallback(() => {
    const { clientWidth, clientHeight } = canvas;

    rendererDispatch(
      new ConfiguratorZoomToFitAction(
        "toggle",
        clientWidth,
        clientHeight,
        elementUtil!
      )
    );
  }, [rendererDispatch, canvas]);

  return (
    <div className="mfc__configurator-zoom-button">
      <FloatingButton roundedFull onClick={toggleZoom}>
        {zoomedTo === "product" ? <ZoomInIcon /> : <ZoomOutIcon />}
      </FloatingButton>
    </div>
  );
};
