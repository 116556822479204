import { useCallback, ChangeEvent } from "react";
import { useField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { ActiveView } from "./useActiveView";
import { useProductColorIndexList } from "./useProductColorIndexList";
import { elementFormikPrefix } from "../helper/formikPrefixHelper";

export const useLetteringText = (
  activeView: ActiveView,
  elementIndex: number
) => {
  const { t } = useTranslation();

  const allColorIndexes = useProductColorIndexList();

  const { setFieldValue } = useFormikContext();

  const formikPrefix = elementFormikPrefix(activeView, elementIndex);

  const [{ value, name, onBlur }, { error }] = useField<string | undefined>(
    `${formikPrefix}.lettering.text`
  );

  const [{ value: textMaxLength }] = useField<number>(
    `${formikPrefix}.lettering.text_max_length`
  );
  const textCurrentLength = value?.length ?? 0;

  const help = t(`help.lettering.text.text_max_length`, {
    textMaxLength,
  });

  const count = `${textCurrentLength}/${textMaxLength}`;

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      for (const colorIndex of allColorIndexes) {
        setFieldValue(
          `${elementFormikPrefix(
            activeView,
            elementIndex ?? -1,
            colorIndex
          )}.lettering.text`,
          e?.target?.value ?? ""
        );
      }
    },
    [allColorIndexes, setFieldValue, activeView, elementIndex]
  );

  return {
    value,
    name,
    onBlur,
    onChange,
    maxLength: textMaxLength,
    help,
    count,
    error,
  };
};
