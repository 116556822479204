import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFeature } from "flagged";
import { ColorHelper } from "melco-ui/dist/helper/ColorHelper";
import { PersonalizeEditSection } from "../personalize/editSection/PersonalizeEditSection";
import { PersonalizeEditItem } from "../personalize/editItem/PersonalizeEditItem";
import { LabeledFloatingButton } from "../labeledFloatingButton/LabeledFloatingButton";
import { ActiveColor } from "../../hooks/useProductColor";
import { useAvailableProductColors } from "../../hooks/useAvailableProductColors";
import { ColorPicker } from "./ColorPicker";

const { argbToRGB } = ColorHelper;

type ProductColorProps = {
  activeProductColor: ActiveColor | undefined;
  setActiveProductColor: (activeColor: ActiveColor | undefined) => void;
};

export const ProductColor: React.FC<ProductColorProps> = ({
  activeProductColor,
  setActiveProductColor,
}) => {
  const { t } = useTranslation();

  const isLabeledFeatureEnabled = useFeature("ui/labeled");

  const availableProductColors = useAvailableProductColors();

  const [isPickerVisible, setPickerVisible] = useState(false);

  const closePicker = useCallback(() => {
    setPickerVisible(false);
  }, [setPickerVisible]);

  const onColorChange = useCallback(
    (color: ActiveColor | undefined) => {
      setActiveProductColor(color);
    },
    [setActiveProductColor]
  );

  return (
    <>
      <PersonalizeEditSection
        title={isLabeledFeatureEnabled ? t("product.color") : undefined}
      >
        <PersonalizeEditItem size="full">
          <LabeledFloatingButton
            backgroundColor={argbToRGB(activeProductColor?.argb)}
            label={
              isLabeledFeatureEnabled
                ? activeProductColor?.name ?? ""
                : t("product.color")
            }
            onClick={() => setPickerVisible(true)}
          />
        </PersonalizeEditItem>
      </PersonalizeEditSection>

      <ColorPicker
        key={activeProductColor?.id}
        isPickerVisible={isPickerVisible}
        closePicker={closePicker}
        initialColor={activeProductColor}
        onColorChange={onColorChange}
        availableColors={availableProductColors}
      />
    </>
  );
};
