import {
  getDesignCollectionIdForView,
  parseDefaultDesignIDs,
} from "../../helper/designCollectionHelper";
import { useAvailableViews } from "../../hooks/useAvailableViews";
import { SetDefaultDesign } from "./SetDefaultDesign";

type SetDefaultDesignsProps = {
  apiKey: string;
  defaultDesignIds?: string;
};

export const SetDefaultDesigns: React.FC<SetDefaultDesignsProps> = ({
  apiKey,
  defaultDesignIds,
}) => {
  const availableViews = useAvailableViews();

  if (!defaultDesignIds) {
    return null;
  }

  const parsedDesignIDs = parseDefaultDesignIDs(defaultDesignIds);

  const designCollectionIds = availableViews.reduce<Record<string, string>>(
    (result, view) => {
      const designCollectionId = getDesignCollectionIdForView(view);

      if (designCollectionId) {
        result[view.name!] = designCollectionId;
      }

      return result;
    },
    {}
  );

  return (
    <>
      {Object.keys(parsedDesignIDs).map((viewName) => (
        <SetDefaultDesign
          key={viewName}
          apiKey={apiKey}
          availableViews={availableViews}
          viewName={viewName}
          designCollectionId={designCollectionIds[viewName]}
          designId={parsedDesignIDs[viewName]}
        />
      ))}
    </>
  );
};
