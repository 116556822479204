import {
  APIError,
  useErrorMessageMapping,
} from "melco-shared-logic/dist/hooks/error/useErrorMessageMapping";
import { useEffect, useState } from "react";

export const useErrorMessage = (error: unknown) => {
  const mapErrors = useErrorMessageMapping();

  const [message, setMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getErrorMessage = async (error: unknown) => {
      const {
        feedbackMessage: { message },
      } = await mapErrors(error as APIError[]);
      setMessage(message);
    };

    if (!error) {
      setMessage(undefined);
    } else {
      getErrorMessage(error);
    }
  }, [error]);

  return message;
};
