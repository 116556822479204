import { useTranslation } from "react-i18next";
import { Modal } from "../modal/Modal";
import { OnCancelCallback } from "../..";
import { ModalError } from "../modal/error/ModalError";
import { Button } from "../button/Button";

type ConfiguratorSessionInitializerProps = {
  onCancelCallback: OnCancelCallback;
  loading?: boolean;
  errorMessage?: string;
};

export const ConfiguratorSessionInitializer: React.FC<
  ConfiguratorSessionInitializerProps
> = ({ onCancelCallback, loading, errorMessage }) => {
  const { t } = useTranslation();

  const errorComponent = errorMessage && (
    <ModalError
      text={errorMessage}
      buttons={[
        <Button
          key="modal-error-close"
          type="primary"
          onClick={onCancelCallback}
        >
          {t("actionButtons.close")}
        </Button>,
      ]}
    />
  );

  return (
    <Modal
      onClose={onCancelCallback}
      loading={loading}
      errorComponent={errorComponent}
    />
  );
};
