/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FusionMelcoFusionModelsSessionPreview
 */
export interface FusionMelcoFusionModelsSessionPreview {
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSessionPreview
     */
    image_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSessionPreview
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSessionPreview
     */
    url?: string | null;
}

export function FusionMelcoFusionModelsSessionPreviewFromJSON(json: any): FusionMelcoFusionModelsSessionPreview {
    return FusionMelcoFusionModelsSessionPreviewFromJSONTyped(json, false);
}

export function FusionMelcoFusionModelsSessionPreviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoFusionModelsSessionPreview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'image_id': !exists(json, 'image_id') ? undefined : json['image_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function FusionMelcoFusionModelsSessionPreviewToJSON(value?: FusionMelcoFusionModelsSessionPreview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image_id': value.image_id,
        'name': value.name,
        'url': value.url,
    };
}


