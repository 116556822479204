/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FusionMelcoModelsPreview,
    FusionMelcoModelsPreviewFromJSON,
    FusionMelcoModelsPreviewFromJSONTyped,
    FusionMelcoModelsPreviewToJSON,
} from './';

/**
 * 
 * @export
 * @interface FusionMelcoFusionModelsDefaultImage
 */
export interface FusionMelcoFusionModelsDefaultImage {
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsDefaultImage
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsDefaultImage
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsDefaultImage
     */
    view_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsDefaultImage
     */
    color_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsDefaultImage
     */
    color_hex?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsDefaultImage
     */
    publish_state?: number;
    /**
     * 
     * @type {Array<FusionMelcoModelsPreview>}
     * @memberof FusionMelcoFusionModelsDefaultImage
     */
    previews?: Array<FusionMelcoModelsPreview> | null;
}

export function FusionMelcoFusionModelsDefaultImageFromJSON(json: any): FusionMelcoFusionModelsDefaultImage {
    return FusionMelcoFusionModelsDefaultImageFromJSONTyped(json, false);
}

export function FusionMelcoFusionModelsDefaultImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoFusionModelsDefaultImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'view_name': !exists(json, 'view_name') ? undefined : json['view_name'],
        'color_name': !exists(json, 'color_name') ? undefined : json['color_name'],
        'color_hex': !exists(json, 'color_hex') ? undefined : json['color_hex'],
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
        'previews': !exists(json, 'previews') ? undefined : (json['previews'] === null ? null : (json['previews'] as Array<any>).map(FusionMelcoModelsPreviewFromJSON)),
    };
}

export function FusionMelcoFusionModelsDefaultImageToJSON(value?: FusionMelcoFusionModelsDefaultImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'view_name': value.view_name,
        'color_name': value.color_name,
        'color_hex': value.color_hex,
        'publish_state': value.publish_state,
        'previews': value.previews === undefined ? undefined : (value.previews === null ? null : (value.previews as Array<any>).map(FusionMelcoModelsPreviewToJSON)),
    };
}


