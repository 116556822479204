import { useEffect, useRef, useState } from "react";
import { useScroll } from "react-use";

import { ModalSidebarScrollIndicator } from "./scrollIndicator/ModalSidebarScrollIndicator";

import "./ModalSidebar.css";

const ModalSidebar = ({ children }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);

  const sidebarRef = useRef<HTMLDivElement>(null);
  const sidebarInnerRef = useRef<HTMLDivElement>(null);

  const { y: sidebarInnerScrollY } = useScroll(sidebarInnerRef);

  useEffect(() => {
    if (
      sidebarInnerRef.current.scrollHeight > sidebarRef.current.clientHeight
    ) {
      setShouldScroll(true);
    }
  }, []);

  useEffect(() => {
    if (sidebarInnerScrollY > 0 && !isScrolled) {
      setIsScrolled(true);
    }
  }, [sidebarInnerScrollY]);

  return (
    <div className="mfc__modal-sidebar" ref={sidebarRef}>
      <div className="mfc__modal-sidebar-inner" ref={sidebarInnerRef}>
        {children}
      </div>
      {shouldScroll && !isScrolled && <ModalSidebarScrollIndicator />}
    </div>
  );
};

export { ModalSidebar };
