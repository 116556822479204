/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FusionMelcoFusionModelsCustomProductImage,
    FusionMelcoFusionModelsCustomProductImageFromJSON,
    FusionMelcoFusionModelsCustomProductImageFromJSONTyped,
    FusionMelcoFusionModelsCustomProductImageToJSON,
} from './';

/**
 * 
 * @export
 * @interface FusionMelcoFusionModelsCustomProductView
 */
export interface FusionMelcoFusionModelsCustomProductView {
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsCustomProductView
     */
    name?: string | null;
    /**
     * 
     * @type {Array<FusionMelcoFusionModelsCustomProductImage>}
     * @memberof FusionMelcoFusionModelsCustomProductView
     */
    color_list?: Array<FusionMelcoFusionModelsCustomProductImage> | null;
}

export function FusionMelcoFusionModelsCustomProductViewFromJSON(json: any): FusionMelcoFusionModelsCustomProductView {
    return FusionMelcoFusionModelsCustomProductViewFromJSONTyped(json, false);
}

export function FusionMelcoFusionModelsCustomProductViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoFusionModelsCustomProductView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'color_list': !exists(json, 'color_list') ? undefined : (json['color_list'] === null ? null : (json['color_list'] as Array<any>).map(FusionMelcoFusionModelsCustomProductImageFromJSON)),
    };
}

export function FusionMelcoFusionModelsCustomProductViewToJSON(value?: FusionMelcoFusionModelsCustomProductView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'color_list': value.color_list === undefined ? undefined : (value.color_list === null ? null : (value.color_list as Array<any>).map(FusionMelcoFusionModelsCustomProductImageToJSON)),
    };
}


