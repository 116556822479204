import FocusTrap from "focus-trap-react";
import { useTranslation } from "react-i18next";
import { FloatingButton } from "../floatingButton/FloatingButton";
import { CloseIcon } from "../icons/icons";
import { Loader } from "../loader/Loader";
import { ModalBackdrop } from "./backdrop/ModalBackdrop";
import { ModalSidebar } from "./sidebar/ModalSidebar";

import "./Modal.css";

type ModalProps = {
  onClose: () => void;
  sidebar?: React.ReactNode;
  closeDisabled?: boolean;
  loading?: boolean;
  errorComponent?: React.ReactNode;
  children?: React.ReactNode;
};

const Modal: React.FC<ModalProps> = ({
  onClose,
  sidebar,
  closeDisabled,
  loading,
  errorComponent,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FocusTrap>
        <div className="mfc__modal">
          <div className="mfc__modal-inner">
            <div className="mfc__modal-close">
              <FloatingButton
                onClick={onClose}
                disabled={closeDisabled}
                roundedFull
              >
                <CloseIcon />
              </FloatingButton>
            </div>

            <div className="mfc__modal-content">
              {children}
              {sidebar && <ModalSidebar>{sidebar}</ModalSidebar>}
            </div>

            {loading && <Loader text={t("loading")} spinnerSize="large" />}

            {errorComponent && errorComponent}
          </div>
        </div>
      </FocusTrap>

      <ModalBackdrop />
    </>
  );
};

export { Modal };
