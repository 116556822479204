import { useCallback, useState } from "react";
import { FusionMelcoModelsDesign } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoModelsDesign";
import { useFeature } from "flagged";
import { useTranslation } from "react-i18next";
import { ActiveView } from "../../hooks/useActiveView";
import { useDesign } from "../../hooks/useDesign";
import { useDesignCollection } from "../../hooks/useDesignCollection";
import { ActiveColor } from "../../hooks/useProductColor";
import { PersonalizeEditItem } from "../personalize/editItem/PersonalizeEditItem";
import { PersonalizeEditSection } from "../personalize/editSection/PersonalizeEditSection";
import { DesignPicker } from "./DesignPicker";
import { DesignFloatingButton } from "../floatingButton/DesignFloatingButton";

type DesignCollectionProps = {
  designCollectionId: string;
  activeView: ActiveView | undefined;
  activeColor: ActiveColor | undefined;
  apiKey: string;
};

export const DesignCollection: React.FC<DesignCollectionProps> = ({
  designCollectionId,
  activeView,
  activeColor,
  apiKey,
}) => {
  const { t } = useTranslation();

  const isLabeledFeatureEnabled = useFeature("ui/labeled");

  const [activeDesign] = useDesign(activeView, activeColor);

  const [availableDesigns, setDesign] = useDesignCollection(
    designCollectionId,
    activeView,
    activeColor,
    apiKey
  );

  const [isPickerVisible, setPickerVisible] = useState(false);

  const showPicker = useCallback(() => {
    setPickerVisible(true);
  }, [setPickerVisible]);

  const closePicker = useCallback(() => {
    setPickerVisible(false);
  }, [setPickerVisible]);

  const onDesignChange = useCallback(
    (design: FusionMelcoModelsDesign) => {
      setDesign(design);
    },
    [setDesign]
  );

  if (availableDesigns.length <= 0 || !activeDesign) {
    return null;
  }

  return (
    <>
      <PersonalizeEditSection
        title={
          isLabeledFeatureEnabled ? t("designCollection.title") : undefined
        }
      >
        <PersonalizeEditItem size="full">
          <DesignFloatingButton design={activeDesign} onClick={showPicker} />
        </PersonalizeEditItem>
      </PersonalizeEditSection>

      <DesignPicker
        key={activeDesign.id}
        isPickerVisible={isPickerVisible}
        closePicker={closePicker}
        initialDesign={activeDesign}
        onDesignChange={onDesignChange}
        availableDesigns={availableDesigns}
      />
    </>
  );
};
