import classNames from "classnames";
import { useTranslation } from "react-i18next";

import "./PersonalizeEditItem.css";

type PersonalizeEditItemProps = {
  size?: "default" | "full";
  error?: boolean;
  errorMessage?: string;
  help?: string;
  suffix?: string;
  children?: React.ReactNode;
};

const PersonalizeEditItem: React.FC<PersonalizeEditItemProps> = ({
  size = "default",
  error,
  errorMessage,
  help,
  suffix,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames("mfc__personalize-edit-item", {
        "mfc__personalize-edit-item-full": size === "full",
        "mfc__personalize-edit-item-error": error,
      })}
    >
      <div className="mfc__personalize-edit-item-inner">
        {children}
        {suffix && (
          <div className="mfc__personalize-edit-item-inner-suffix">
            {suffix}
          </div>
        )}
      </div>

      {error ? (
        <div className="mfc__personalize-edit-item-help">{t(errorMessage)}</div>
      ) : (
        help && <div className="mfc__personalize-edit-item-help">{help}</div>
      )}
    </div>
  );
};

export { PersonalizeEditItem };
