import { useMemo } from "react";
import range from "lodash/range";
import { useAvailableProductColors } from "./useAvailableProductColors";

export const useProductColorIndexList = () => {
  const availableProductColors = useAvailableProductColors();

  return useMemo(
    () => range(availableProductColors.length),
    [availableProductColors.length]
  );
};
