import { useCallback, useState } from "react";
import { PersonalizeEditItem } from "../personalize/editItem/PersonalizeEditItem";
import { ActiveColor } from "../../hooks/useProductColor";
import { ColorPicker } from "./ColorPicker";
import { LabeledFloatingButton } from "../labeledFloatingButton/LabeledFloatingButton";
import { useElementColors } from "../../hooks/useElementColors";
import { ActiveView } from "../../hooks/useActiveView";
import { ColorHelper } from "melco-ui/dist/helper/ColorHelper";
import { useFeature } from "flagged";

const { argbToRGB } = ColorHelper;

type DesignColorItemProps = {
  activeView: ActiveView;
  elementIndex: number | undefined;
};

export const DesignColorItem: React.FC<DesignColorItemProps> = ({
  activeView,
  elementIndex,
}) => {
  const isLabeledFeatureEnabled = useFeature("ui/labeled");

  const [availableColors, activeColor, setColor] = useElementColors(
    activeView,
    elementIndex
  );

  const [isPickerVisible, setPickerVisible] = useState(false);

  const showPicker = useCallback(() => {
    setPickerVisible(true);
  }, [setPickerVisible]);

  const closePicker = useCallback(() => {
    setPickerVisible(false);
  }, [setPickerVisible]);

  const onColorChange = useCallback(
    (color: ActiveColor | undefined) => {
      setColor(color);
    },
    [setColor]
  );

  if (availableColors.length <= 0 || !activeColor) {
    return null;
  }

  return (
    <>
      <PersonalizeEditItem>
        <LabeledFloatingButton
          backgroundColor={argbToRGB(activeColor.argb)}
          label={isLabeledFeatureEnabled ? activeColor.name ?? "" : ""}
          onClick={showPicker}
        />
      </PersonalizeEditItem>

      <ColorPicker
        key={activeColor.id}
        isPickerVisible={isPickerVisible}
        closePicker={closePicker}
        initialColor={activeColor}
        onColorChange={onColorChange}
        availableColors={availableColors}
      />
    </>
  );
};
