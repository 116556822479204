import { useField } from "formik";
import { MelcoCoreModelsCustomProductImage } from "melco-shared-logic/dist/client-fusion/models/MelcoCoreModelsCustomProductImage";
import { FusionMelcoModelsUserImage } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoModelsUserImage";
import { ActiveView } from "./useActiveView";
import { ActiveColor } from "./useProductColor";

export const useDesignListFormikPath = (
  activeView: ActiveView | undefined,
  activeColor: ActiveColor | undefined
) => {
  const [{ value: blankPictures }] = useField<
    FusionMelcoModelsUserImage[] | undefined
  >("product.blank.images");

  const [{ value: colorList }] = useField<
    MelcoCoreModelsCustomProductImage[] | undefined
  >(`product.view_list[${activeView?.index}].color_list`);

  // get the color list index of a color for which we only have a
  // reference to the blank picture id
  const colorIndex = (colorList ?? []).findIndex((c) => {
    const blankPicture = (blankPictures ?? []).find((bp) => bp.id === c.id);

    if (!blankPicture) {
      return false;
    }

    return blankPicture.color_name === activeColor?.name;
  });

  return `product.view_list[${activeView?.index}].color_list[${colorIndex}].design_list[0]`;
};
