import classNames from "classnames";

import { Loader } from "../loader/Loader";

import "./FloatingButton.css";

export type FloatingButtonProps = Omit<
  React.HTMLProps<HTMLButtonElement>,
  "size" | "type"
> & {
  size?: "default" | "large";
  roundedFull?: boolean;
  isLoading?: boolean;
  isActive?: boolean;
  backgroundColor?: string;
  isLowContrast?: boolean;
  children?: React.ReactNode;
};

const FloatingButton: React.FC<FloatingButtonProps> = ({
  size = "default",
  roundedFull,
  isLoading,
  isActive,
  backgroundColor,
  isLowContrast,
  children,
  ...rest
}) => (
  <div
    className={classNames("mfc__floating-btn", {
      "mfc__floating-btn-large": size === "large",
      "mfc__floating-btn-loading": isLoading,
      "mfc__floating-btn-rounded-full": roundedFull,
      "mfc__floating-btn-active": isActive,
      "mfc__floating-btn-lowcontrast": isLowContrast,
    })}
  >
    <button
      className="mfc__floating-btn-inner"
      style={backgroundColor && { backgroundColor }}
      type="button"
      {...rest}
    >
      {children}
    </button>
    {isLoading && <Loader spinnerSize={size} backgroundTransparent />}
  </div>
);

export { FloatingButton };
