import { useState } from "react";
import { DesignSelectorOptions } from "../..";
import { DesignCollectionOfView } from "../../hooks/useDesignCollectionsOfProduct";
import { useFetchDesignCollection } from "../../hooks/useFetchDesignCollection";
import { SelectableDesign } from "./SelectableDesign";

type DesignCollectionForViewProps = {
  designCollectionOfView: DesignCollectionOfView;
  token: string;
  apiKey: string;
  onDesignSelection?: DesignSelectorOptions["onDesignSelection"];
  defaultDesignId?: string;
  showViewNameLabel?: boolean;
};

export const DesignCollectionForView: React.FC<
  DesignCollectionForViewProps
> = ({
  designCollectionOfView,
  token,
  apiKey,
  onDesignSelection,
  defaultDesignId,
  showViewNameLabel,
}) => {
  const [selectedDesignId, setSelectedDesignId] = useState(
    defaultDesignId ?? ""
  );

  const { viewName, designCollectionId } = designCollectionOfView;

  const [availableDesigns, isLoading] = useFetchDesignCollection(
    designCollectionId,
    apiKey
  );

  if (!designCollectionId || isLoading) {
    return null;
  }

  return (
    <>
      {showViewNameLabel && (
        <div className="mfc__design-collection-view-label">{viewName}</div>
      )}

      <ul className="mfc__design-collection-view" data-view-name={viewName}>
        {availableDesigns.map((design) => (
          <SelectableDesign
            key={design.id}
            design={design}
            isSelected={selectedDesignId === design.id}
            onSelection={
              onDesignSelection
                ? () => {
                    setSelectedDesignId(design.id!);

                    onDesignSelection(design.id!, viewName);
                  }
                : undefined
            }
          />
        ))}
      </ul>

      <input
        className="mfc__design-collection-selected-design"
        type="hidden"
        data-token={token}
        data-view-name={viewName}
        data-design-id={selectedDesignId}
      />
    </>
  );
};
