{
  "colorPicker": {
    "title": "Choisissez une couleur"
  },
  "fontPicker": {
    "title": "Choisissez une police"
  },
  "designPicker": {
    "title": "Choisissez un design"
  },
  "customization": {
    "title": "Personnalisation"
  },
  "designCollection": {
    "title": "Design"
  },
  "designColors": {
    "title": "Couleurs du design"
  },
  "lettering": {
    "color": "Couleur",
    "font": "Police"
  },
  "product": {
    "color": "Couleur du produit"
  },
  "loading": "Chargement, veuillez patienter...",
  "cancelSession": {
    "title": "Êtes-vous sûr de fermer ?",
    "text": "Vous perdrez votre personnalisation",
    "ok": "Oui, fermer",
    "cancel": "Non"
  },
  "actionButtons": {
    "ok": "OK",
    "finish": "Terminer",
    "cancel": "Annuler",
    "close": "Fermer",
    "restart": "Redémarrer",
    "editPersonalization": "Modifier la personnalisation"
  },
  "help": {
    "lettering": {
      "text": {
        "text_max_length": "Maximum de {{textMaxLength}} caractères possible",
        "required": "Veuillez ajouter du texte; ce champ ne peut pas être vide."
      }
    }
  }
}
