import { useCallback, useState } from "react";
import { ConfirmConfig } from "../components/confirm/Confirm";

type ShowConfirmAction = (config: ConfirmConfig) => void;
type HideConfirmAction = () => void;

export type ConfirmActions = {
  hideConfirm: HideConfirmAction;
  showConfirm: ShowConfirmAction;
};

export const useConfirm = () => {
  const [currentConfirm, setCurrentConfirm] = useState<
    ConfirmConfig | undefined
  >(undefined);

  const hideConfirm: HideConfirmAction = useCallback(() => {
    setCurrentConfirm(undefined);
  }, [setCurrentConfirm]);

  const showConfirm: ShowConfirmAction = useCallback(
    (confirm: ConfirmConfig) => {
      setCurrentConfirm(confirm);
    },
    [setCurrentConfirm]
  );

  const actions: ConfirmActions = {
    hideConfirm,
    showConfirm,
  };

  return { currentConfirm, actions };
};
