import classNames from "classnames";

import "./SwitchItem.css";

type SwitchItemProps = {
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
};

const SwitchItem: React.FC<SwitchItemProps> = ({
  children,
  onClick,
  active,
  disabled,
}) => (
  <button
    className={classNames("mfc__switch-item", {
      "mfc__switch-item-active": active,
    })}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

export { SwitchItem };
