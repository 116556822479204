import { useEffect, useState } from "react";
import { useField } from "formik";
import { useAvailableProductColors } from "./useAvailableProductColors";
import { FusionMelcoModelsUserImage } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoModelsUserImage";

export type ActiveColor = {
  id: string;
  name: string;
  argb: string;
};

const getDefaultColor = (
  defaultColor: string | undefined,
  availableProductColors: ActiveColor[]
) => {
  if (!defaultColor || defaultColor === "") {
    return;
  }

  return availableProductColors.find((c) => c.name === defaultColor);
};

const getColorFromSession = (
  colorNameFromSession: string | undefined,
  availableProductColors: ActiveColor[]
) => {
  if (!colorNameFromSession || colorNameFromSession === "") {
    return;
  }

  return availableProductColors.find((c) => c.name === colorNameFromSession);
};

export const useProductColor = (defaultColor?: string) => {
  const [{ value: colorImageId }, , { setValue: setColorImageId }] = useField<
    string | undefined
  >("selected_color_image_id");

  const [activeProductColor, setActiveProductColor] = useState<
    ActiveColor | undefined
  >();

  const [{ value: blankPictures }] = useField<
    FusionMelcoModelsUserImage[] | undefined
  >("product.blank.images");

  const availableProductColors = useAvailableProductColors();

  // set default color (when given) or first product color to be initially active (e.g. when updating a session)
  useEffect(() => {
    if (
      !activeProductColor &&
      availableProductColors &&
      availableProductColors.length > 0
    ) {
      const defaultAvailableColor = getDefaultColor(
        defaultColor,
        availableProductColors
      );

      const colorNameFromSession = (blankPictures ?? []).find(
        (bp) => bp.id === colorImageId
      )?.color_name;

      const colorFromSession = getColorFromSession(
        colorNameFromSession ?? undefined,
        availableProductColors
      );

      if (defaultAvailableColor) {
        setActiveProductColor(defaultAvailableColor);
      } else if (colorFromSession) {
        setActiveProductColor(colorFromSession);
      } else {
        const firstAvailableColor = availableProductColors[0];
        setActiveProductColor(firstAvailableColor);
      }
    }

    // when the active color changes, always update the color image id in the session object
    if (activeProductColor) {
      setColorImageId(activeProductColor.id);
    }
  }, [
    activeProductColor,
    setActiveProductColor,
    availableProductColors,
    setActiveProductColor,
    defaultColor,
    colorImageId,
  ]);

  return [activeProductColor, setActiveProductColor] as const;
};
