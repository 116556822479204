import { useEffect, useState } from "react";
import { useAvailableViews } from "./useAvailableViews";

export type ActiveView = {
  index: number;
  name: string;
};

export const useActiveView = (defaultViewName?: string) => {
  const availableViews = useAvailableViews();

  const [activeView, setActiveView] = useState<ActiveView | undefined>();

  // set first view to be initially active
  useEffect(() => {
    if (!activeView && availableViews && availableViews.length > 0) {
      // activate default view if available
      if (defaultViewName) {
        const index = availableViews.findIndex(
          (v) => v.name === defaultViewName
        );

        if (index >= 0) {
          setActiveView({ index, name: defaultViewName });
          return;
        }
      }

      // activate first view by default
      const { name } = availableViews[0];

      setActiveView({ index: 0, name: name ?? "" });
    }
  }, [activeView, setActiveView, availableViews, defaultViewName]);

  return [activeView, setActiveView] as const;
};
