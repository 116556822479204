{
  "colorPicker": {
    "title": "Farbe auswählen"
  },
  "fontPicker": {
    "title": "Schriftart wählen"
  },
  "designPicker": {
    "title": "Design wählen"
  },
  "customization": {
    "title": "Personalisierung"
  },
  "designCollection": {
    "title": "Design"
  },
  "designColors": {
    "title": "Designfarben"
  },
  "lettering": {
    "color": "Farbe",
    "font": "Schriftart"
  },
  "product": {
    "color": "Produktfarbe"
  },
  "loading": "Lade, bitte warten...",
  "cancelSession": {
    "title": "Möchten Sie wirklich abbrechen?",
    "text": "Sie verlieren ihre Personalisierung, wenn Sie abbrechen.",
    "ok": "Ja, schliessen",
    "cancel": "Nein"
  },
  "actionButtons": {
    "ok": "OK",
    "finish": "Fertig",
    "cancel": "Abbrechen",
    "close": "Schliessen",
    "restart": "Neu starten",
    "editPersonalization": "Personalisierung bearbeiten"
  },
  "help": {
    "lettering": {
      "text": {
        "text_max_length": "Maximal mögliche {{textMaxLength}} Zeichen",
        "required": "Bitte fügen Sie Text hinzu; dieses Feld darf nicht leer sein."
      }
    }
  }
}
