import { colord, extend, getFormat } from "colord";
import a11yPlugin from "colord/plugins/a11y";

extend([a11yPlugin]);

const isValidARGBRegExp = new RegExp("^[0-9a-f]{8}$", "i");
const isValidRGBARegExp = new RegExp("^#[0-9a-f]{8}$", "i");

const FALLBACK_ARGB_VALUE = "00000000"; //transparent
const FALLBACK_RGBA_VALUE = "#00000000"; //transparent

const isValidARGB = (argb?: string) => isValidARGBRegExp.test(String(argb));

const isValidRGBA = (rgba?: string) => isValidRGBARegExp.test(String(rgba));

const isValidRGB = (rgb?: string) =>
  getFormat(ensureRGBValueIsPrefixedWithHash(rgb!) ?? "") === "hex";

const argbToRGBA = (argb?: string) => {
  if (!isValidARGB(argb)) {
    return FALLBACK_RGBA_VALUE;
  }

  return `#${argb!.substring(2, 8)}${argb!.substring(0, 2)}`.toUpperCase();
};

const argbToRGB = (argb?: string) => {
  return rgbaToRGB(argbToRGBA(argb));
};

const rgbaToRGB = (rgba: string) => {
  let rgbaValue = rgba;
  if (!isValidRGBA(rgba)) {
    rgbaValue = FALLBACK_RGBA_VALUE;
  }
  const { r, g, b } = colord(rgbaValue).toRgb();
  return colord({ r, g, b }).toHex().toUpperCase();
};

const colorToRGB = (color?: string) => {
  let rgb = colord(ensureRGBValueIsPrefixedWithHash(color) ?? "");
  if (!rgb.isValid()) {
    rgb = colord(FALLBACK_RGBA_VALUE);
  }
  return rgbaToRGB(rgbToRGBA(rgb.toHex()));
};

const rgbaToARGB = (rgba?: string) => {
  if (!isValidRGBA(rgba)) {
    return FALLBACK_ARGB_VALUE;
  }

  return `${rgba!.substring(7, 9)}${rgba!.substring(1, 7)}`.toUpperCase();
};

const rgbToARGB = (rgb?: string) => {
  return rgbaToARGB(rgbToRGBA(rgb));
};

const rgbToRGBA = (rgb?: string) => {
  if (!isValidRGB(rgb)) {
    return FALLBACK_RGBA_VALUE;
  }
  const rgbHex = colord(ensureRGBValueIsPrefixedWithHash(rgb!) ?? "")
    .toHex()
    .toUpperCase(); // could return either RGB or RGBA hex;
  return isValidRGBARegExp.test(rgbHex) ? rgbHex : `${rgbHex}FF`;
};

const ensureRGBValueIsPrefixedWithHash = (rgb?: string) =>
  String(rgb).startsWith("#") ? rgb : `#${rgb}`;

//TODO: get contrast with mix to white
const getContrastToWhiteFromARGB = (argb?: string) =>
  colord(argbToRGBA(argb)).contrast("#fff");

const getAlphaFromARGB = (argb?: string) => colord(argbToRGBA(argb)).alpha();

const ColorHelper = {
  isValidARGB,
  isValidRGBA,
  isValidRGB,
  argbToRGBA,
  rgbaToRGB,
  rgbaToARGB,
  rgbToARGB,
  rgbToRGBA,
  getContrastToWhiteFromARGB,
  getAlphaFromARGB,
  argbToRGB,
  colorToRGB,
};

export { ColorHelper };
