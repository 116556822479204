import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ConfiguratorOptions } from ".";
import { Configurator } from "./components/configurator/Configurator";
import "./index.css";
import i18n from "./i18n";

const queryClient = new QueryClient();

type AppProps = {
  token: string;
  apiKey: string;
  options: ConfiguratorOptions;
};

export const App: React.FC<AppProps> = ({ token, apiKey, options }) => {
  const { language } = options;

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <QueryClientProvider client={queryClient}>
      <Configurator token={token} apiKey={apiKey} options={options} />
    </QueryClientProvider>
  );
};
