/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FusionMelcoFusionModelsRendererMatrix
 */
export interface FusionMelcoFusionModelsRendererMatrix {
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsRendererMatrix
     */
    m00?: number;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsRendererMatrix
     */
    m01?: number;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsRendererMatrix
     */
    m02?: number;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsRendererMatrix
     */
    m10?: number;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsRendererMatrix
     */
    m11?: number;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsRendererMatrix
     */
    m12?: number;
}

export function FusionMelcoFusionModelsRendererMatrixFromJSON(json: any): FusionMelcoFusionModelsRendererMatrix {
    return FusionMelcoFusionModelsRendererMatrixFromJSONTyped(json, false);
}

export function FusionMelcoFusionModelsRendererMatrixFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoFusionModelsRendererMatrix {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'm00': !exists(json, 'm00') ? undefined : json['m00'],
        'm01': !exists(json, 'm01') ? undefined : json['m01'],
        'm02': !exists(json, 'm02') ? undefined : json['m02'],
        'm10': !exists(json, 'm10') ? undefined : json['m10'],
        'm11': !exists(json, 'm11') ? undefined : json['m11'],
        'm12': !exists(json, 'm12') ? undefined : json['m12'],
    };
}

export function FusionMelcoFusionModelsRendererMatrixToJSON(value?: FusionMelcoFusionModelsRendererMatrix | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'm00': value.m00,
        'm01': value.m01,
        'm02': value.m02,
        'm10': value.m10,
        'm11': value.m11,
        'm12': value.m12,
    };
}


