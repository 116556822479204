/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FusionMelcoModelsCustomWarning
 */
export interface FusionMelcoModelsCustomWarning {
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoModelsCustomWarning
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoModelsCustomWarning
     */
    source?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoModelsCustomWarning
     */
    message?: string | null;
}

export function FusionMelcoModelsCustomWarningFromJSON(json: any): FusionMelcoModelsCustomWarning {
    return FusionMelcoModelsCustomWarningFromJSONTyped(json, false);
}

export function FusionMelcoModelsCustomWarningFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoModelsCustomWarning {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function FusionMelcoModelsCustomWarningToJSON(value?: FusionMelcoModelsCustomWarning | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'source': value.source,
        'message': value.message,
    };
}


