import {Rectangle, Matrix, Color, MatrixUtil, RectangleUtil} from './geom';
import { LetteringParams, DesignParams, ImageParams, ThreadColor, GridParams } from './elementparams';

export enum WireframeType {
    SELECTION_BOX='SELECtION_BOX'
}
/**
 *
 */
export interface BaseWireframeElement {
    type: WireframeType;
}

export interface ImageMetadata {
    readonly xdim: number;
    readonly ydim: number;
    readonly defaultRect: Rectangle | undefined;
}

export enum ElementType {
    IMAGE='IMAGE',
    DESIGN='DESIGN',
    LETTERING='LETTERING',
    GRID='GRID',
}

export function IsStitchElementType(type: ElementType) {
    return type == ElementType.DESIGN || type == ElementType.LETTERING
}

/**
 * Image element
 */
export interface ImageElement {
    /** uid - used for cache lookups */
    uid: string;
    /** Element type */
    type: ElementType;
    /** Image params used to create image element */
    image_params: ImageParams;
    /** rectangle to render image to.  Defaults to dpi/center in imageParams if not set */
    rect?: Rectangle;
    /** Transformation for image, applied after image scaled to rect. */
    matrix?: Matrix;
}

/**
 * Design sub element
 */
export interface StitchSubElement {
    internalSubElementIndex: number;
    letteringIndex: number;
    colorsIndexes: Array<number>;
    selectedColorIndexes?: Array<boolean>
}

/**
 * hit test information
 */
export interface HitTestInfo {
    /** Set to true if there was a hit */
    hit: boolean;
    /** Distance in points of item from hit point (zero if hit exactly on item) */
    distanceInPoints?: number;
    /** Sub element of item which was hit */
    subElementHit?: number;
    /** Sub element color index of item which was hit */
    subElementColorIndexHit?: number;
    /** Color index of design which was hit */
    colorIndex?: number;
}
export interface StitchMetadata {
    /** The stitch count for element */
    readonly stitchCount: number;
    /** The original bounding rectangle for element (Before transform applied) */
    readonly originalBoundingBox: Rectangle;
    /** Original array of colors for design */
    readonly originalColors: Array<ThreadColor>;
    /** Original sub elements for design */
    readonly originalSubElements: Array<StitchSubElement>;
    /** Params for any lettering sub elements in design. */
    readonly originalLetteringParams: Array<LetteringParams>;
}
export interface BaseElement {
    /** uid - used for cache lookups */
    uid: string;
    /** Element type */
    type: ElementType;
}
/**
 * Design element.
 */
export interface StitchElement {
    /** uid - used for cache lookups */
    uid: string;
    /** Element type */
    type: ElementType;
    /** Design params used to create element. */
    design_params?: DesignParams;
    /** Transformation for design. */
    matrix?: Matrix;
    /** Array of colors for design, if undefined uses default */
    colors?: Array<ThreadColor>;
    /** Array of subelements can modify to change colors indexes if needed
     *   In most cases modifying colors array instead should be sufficient.
     */
    subElements?: Array<StitchSubElement>;
    /** Params for any lettering sub elements. */
    letteringParams?: Array<LetteringParams>;
}
/**
 * Grid element.
 */
export interface GridElement {
    /** uid - used for cache lookups */
    uid: string;
    /** Element type */
    type: ElementType;
    /** Grid params */
    grid_params: GridParams
}

/**
 * enum for hit testing on selection box
 */
export enum SelectionBoxHit {
    hitNothing='hitNothing',
    hitTopLeft='hitTopLeft',
    hitTopRight='hitTopRight',
    hitBottomRight='hitBottomRight',
    hitBottomLeft='hitBottomLeft',
    hitTop='hitTop',
    hitRight='hitRight',
    hitBottom='hitBottom',
    hitLeft='hitLeft',
    hitMiddle='hitMiddle'
}

export function NumberToSelectionBoxHit(v: number) {
    switch (v) {
        case 1:
            return SelectionBoxHit.hitTopLeft
        case 2:
            return SelectionBoxHit.hitTopRight
        case 3:
            return SelectionBoxHit.hitBottomRight
        case 4:
            return SelectionBoxHit.hitBottomLeft
        case 5:
            return SelectionBoxHit.hitTop
        case 6:
            return SelectionBoxHit.hitRight
        case 7:
            return SelectionBoxHit.hitBottom
        case 8:
            return SelectionBoxHit.hitLeft
        case 9:
            return SelectionBoxHit.hitMiddle
        default:
            return SelectionBoxHit.hitNothing
    }
}

export interface SelectionDataElem {
    elementIndex: number,
    originalTransformation: Matrix
}

export class SelectionDataElemUtil {
    public static emptySelectionDataElem(): SelectionDataElem {
        return {
            elementIndex: -1,
            originalTransformation: MatrixUtil.identityMatrix()
        }
    }
    public static cloneSelectionDataElem(elem: SelectionDataElem): SelectionDataElem {
        return {...elem};
    }
}


export interface SelectionBox {
    type: WireframeType,
    rect?: Rectangle,
    selectedElemIds?: Array<string>,
    isRotate: boolean,
    display: boolean,
    matrix?: Matrix
}

export class SelectionBoxUtil {
    public static createEmpty(): SelectionBox {
        return {
            type: WireframeType.SELECTION_BOX,
            isRotate: false,
            display: false,
            rect: RectangleUtil.emptyRect()
        }
    }
    public static cloneSelectionBox(data: SelectionBox): SelectionBox {
        let r = {...data};
        return r;
    }
}