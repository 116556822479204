{
  "colorPicker": {
    "title": "Choose a color"
  },
  "fontPicker": {
    "title": "Choose a font"
  },
  "designPicker": {
    "title": "Choose a design"
  },
  "customization": {
    "title": "Customization"
  },
  "designCollection": {
    "title": "Design"
  },
  "designColors": {
    "title": "Design colors"
  },
  "lettering": {
    "color": "Color",
    "font": "Font"
  },
  "product": {
    "color": "Product color"
  },
  "loading": "Loading, please wait...",
  "cancelSession": {
    "title": "Are you sure you want to close?",
    "text": "You will lose your personalization",
    "ok": "Yes, close",
    "cancel": "No"
  },
  "actionButtons": {
    "ok": "OK",
    "finish": "Finish",
    "cancel": "Cancel",
    "close": "Close",
    "restart": "Restart",
    "editPersonalization": "Edit Personalization"
  },
  "help": {
    "lettering": {
      "text": {
        "required": "Please add text; this field cannot be empty.",
        "text_max_length": "Maximum {{textMaxLength}} characters possible"
      }
    }
  }
}
