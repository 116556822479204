import { useCallback, useEffect, useMemo } from "react";
import { useField, useFormikContext } from "formik";
import { ActiveView } from "./useActiveView";
import { elementFormikPrefix } from "../helper/formikPrefixHelper";
import { useProductColorIndexList } from "./useProductColorIndexList";
import { FusionMelcoModelsUserAlphabet } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoModelsUserAlphabet";
import { selectThumbnailForSize } from "melco-shared-logic/dist/helper/thumbnails/thumbnailHelper";

export type ActiveFont = {
  id: string;
  name: string;
  previewThumbnailUrl: string;
};

export const useElementFonts = (
  activeView: ActiveView,
  elementIndex: number
) => {
  const allColorIndexes = useProductColorIndexList();

  const { setFieldValue } = useFormikContext();

  const formikPrefix = elementFormikPrefix(activeView, elementIndex);

  const [{ value: defaultFontId }] = useField<string | undefined>(
    `${formikPrefix}.lettering.default_alphabet_id`
  );

  const [{ value: fontCollection }] = useField<
    FusionMelcoModelsUserAlphabet[] | undefined
  >(`${formikPrefix}.lettering.alphabet_list`);

  const availableFonts = useMemo(() => {
    return (fontCollection ?? []).map((font) => {
      const { id, name, previews } = font;

      return {
        id: id!,
        name: name ?? "",
        previewThumbnailUrl:
          selectThumbnailForSize(previews ?? [], 48)?.url ?? "",
      } as ActiveFont;
    });
  }, [fontCollection]);

  const activeFont = useMemo(() => {
    return availableFonts.find((c) => defaultFontId && c.id === defaultFontId);
  }, [availableFonts, defaultFontId]);

  const setFont = useCallback(
    (font: ActiveFont | undefined) => {
      // update value for all colors
      // i am not happy with this, but the data model forces us to do so and assume that all colors have the same designs applied with the same element lists that need to be kept in sync
      for (const colorIndex of allColorIndexes) {
        setFieldValue(
          `${elementFormikPrefix(
            activeView,
            elementIndex,
            colorIndex
          )}.lettering.default_alphabet_id`,
          font?.id
        );
      }
    },
    [allColorIndexes, activeView, elementIndex, setFieldValue]
  );

  // select the first font by default, if no valid default font id is set
  useEffect(() => {
    if (!activeFont) {
      const firstFont = availableFonts[0];

      if (firstFont) {
        setFont(firstFont);
      }
    }
  }, [activeFont, setFont, availableFonts]);

  return [availableFonts, activeFont, setFont] as const;
};
