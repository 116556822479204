import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFeature } from "flagged";
import { ColorHelper } from "melco-ui/dist/helper/ColorHelper";
import { ActiveColor } from "../../hooks/useProductColor";
import { ModalPicker } from "../modal/picker/ModalPicker";
import { Button } from "../button/Button";
import { FloatingButton } from "../floatingButton/FloatingButton";
import { FloatingButtonGrid } from "../floatingButtonGrid/FloatingButtonGrid";
import { LabeledFloatingButton } from "../labeledFloatingButton/LabeledFloatingButton";

const { argbToRGB } = ColorHelper;

type ColorPickerProps = {
  isPickerVisible: boolean;
  closePicker: () => void;
  initialColor: ActiveColor | undefined;
  onColorChange: (newColor: ActiveColor | undefined) => void;
  availableColors: ActiveColor[];
};

export const ColorPicker: React.FC<ColorPickerProps> = ({
  isPickerVisible,
  closePicker,
  initialColor,
  onColorChange,
  availableColors,
}) => {
  const { t } = useTranslation();

  const isLabeledFeatureEnabled = useFeature("ui/labeled");

  const [selectedColor, setSelectedColor] = useState<ActiveColor | undefined>(
    initialColor
  );

  // set initial color, if no color has been selected
  useEffect(() => {
    if (!selectedColor && initialColor) {
      setSelectedColor(initialColor);
    }
  }, [selectedColor, initialColor, setSelectedColor]);

  const onCancel = useCallback(() => {
    closePicker();

    // reset
    setSelectedColor(initialColor);
  }, [initialColor, closePicker, setSelectedColor]);

  const onOk = useCallback(() => {
    // confirm color change
    onColorChange(selectedColor);

    closePicker();
  }, [selectedColor, closePicker, setSelectedColor]);

  return (
    <ModalPicker
      isVisible={isPickerVisible}
      title={t("colorPicker.title")}
      buttons={[
        <Button key="picker-cancel" onClick={onCancel}>
          {t("actionButtons.cancel")}
        </Button>,
        <Button key="picker-ok" type="primary" onClick={onOk}>
          {t("actionButtons.ok")}
        </Button>,
      ]}
    >
      {isLabeledFeatureEnabled && (
        <LabeledFloatingButton
          backgroundColor={argbToRGB(selectedColor?.argb)}
          label={selectedColor?.name ?? ""}
        />
      )}

      <FloatingButtonGrid>
        {availableColors.map((color) => {
          const { id, argb } = color;

          return (
            <FloatingButton
              key={id}
              backgroundColor={argbToRGB(argb)}
              isActive={selectedColor && selectedColor.id === id}
              onClick={() => {
                setSelectedColor(color);
              }}
            />
          );
        })}
      </FloatingButtonGrid>
    </ModalPicker>
  );
};
