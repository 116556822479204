{
  "colorPicker": {
    "title": "Kies een kleur"
  },
  "fontPicker": {
    "title": "Kies een lettertype"
  },
  "designPicker": {
    "title": "Kies een ontwerp"
  },
  "customization": {
    "title": "Personaliseren"
  },
  "designCollection": {
    "title": "Ontwerp"
  },
  "designColors": {
    "title": "Ontwerp kleuren"
  },
  "lettering": {
    "color": "Kleur",
    "font": "Lettertype"
  },
  "product": {
    "color": "Product kleur"
  },
  "loading": "Bezig met laden, even geduld...",
  "cancelSession": {
    "title": "Weet u zeker dat u wilt sluiten?",
    "text": "Je verliest je personalisatie",
    "ok": "Ja, sluiten",
    "cancel": "Nee"
  },
  "actionButtons": {
    "ok": "OK",
    "finish": "Finish",
    "cancel": "Annuleren",
    "close": "Sluiten",
    "restart": "Herstarten",
    "editPersonalization": "Personalisatie bewerken"
  },
  "help": {
    "lettering": {
      "text": {
        "text_max_length": "Maximaal {{textMaxLength}} tekens mogelijk",
        "required": "Voeg alstublieft tekst toe; dit veld mag niet leeg zijn."
      }
    }
  }
}
