{
  "colorPicker": {
    "title": "Scegli un colore"
  },
  "fontPicker": {
    "title": "Scegli un carattere"
  },
  "designPicker": {
    "title": "Scegli un design"
  },
  "customization": {
    "title": "Personalizzazione"
  },
  "designCollection": {
    "title": "Design"
  },
  "designColors": {
    "title": "Colori del design"
  },
  "lettering": {
    "color": "Colore",
    "font": "Carattere"
  },
  "product": {
    "color": "Colore del prodotto"
  },
  "loading": "Caricamento, attendere prego...",
  "cancelSession": {
    "title": "Sei sicuro di voler chiudere?",
    "text": "Perderete la vostra personalizzazione",
    "ok": "Sì, chiudere",
    "cancel": "No"
  },
  "actionButtons": {
    "ok": "OK",
    "finish": "Fine",
    "cancel": "Annulla",
    "close": "Chiudere",
    "restart": "Riavviare",
    "editPersonalization": "Modifica personalizzazione"
  },
  "help": {
    "lettering": {
      "text": {
        "text_max_length": "Massimo {{textMaxLength}} caratteri possibili"
      }
    }
  }
}
