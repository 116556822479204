import classNames from "classnames";

import { Spinner } from "../spinner/Spinner";

import "./Button.css";

type ButtonProps = Omit<React.HTMLProps<HTMLButtonElement>, "type"> & {
  type?: "default" | "primary";
  loading?: boolean;
  children?: React.ReactNode;
};

const Button: React.FC<ButtonProps> = ({
  type = "default",
  loading,
  children,
  ...rest
}) => (
  <button
    className={classNames("mfc__btn", {
      "mfc__btn-primary": type === "primary",
      "mfc__btn-loading": loading,
    })}
    type="button"
    {...rest}
  >
    {loading && <Spinner type={type} />}
    <div className="mfc__btn-inner">{children}</div>
  </button>
);

export { Button };
