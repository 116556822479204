/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FusionMelcoFusionModelsSessionPreview,
    FusionMelcoFusionModelsSessionPreviewFromJSON,
    FusionMelcoFusionModelsSessionPreviewFromJSONTyped,
    FusionMelcoFusionModelsSessionPreviewToJSON,
} from './';

/**
 * 
 * @export
 * @interface FusionMelcoFusionModelsSessionPersonalizationListItem
 */
export interface FusionMelcoFusionModelsSessionPersonalizationListItem {
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    session_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    personalization_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    product_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    product_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    product_token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    blank_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    blank_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    color_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    view_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    design_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    hoop_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    hoop_name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    session_status?: number;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    production_status?: number;
    /**
     * 
     * @type {Date}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    start_timestamp?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    end_timestamp?: Date | null;
    /**
     * 
     * @type {Array<FusionMelcoFusionModelsSessionPreview>}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    previews?: Array<FusionMelcoFusionModelsSessionPreview> | null;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsSessionPersonalizationListItem
     */
    total?: number;
}

export function FusionMelcoFusionModelsSessionPersonalizationListItemFromJSON(json: any): FusionMelcoFusionModelsSessionPersonalizationListItem {
    return FusionMelcoFusionModelsSessionPersonalizationListItemFromJSONTyped(json, false);
}

export function FusionMelcoFusionModelsSessionPersonalizationListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoFusionModelsSessionPersonalizationListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'session_id': !exists(json, 'session_id') ? undefined : json['session_id'],
        'personalization_id': !exists(json, 'personalization_id') ? undefined : json['personalization_id'],
        'product_id': !exists(json, 'product_id') ? undefined : json['product_id'],
        'product_name': !exists(json, 'product_name') ? undefined : json['product_name'],
        'product_token': !exists(json, 'product_token') ? undefined : json['product_token'],
        'blank_id': !exists(json, 'blank_id') ? undefined : json['blank_id'],
        'blank_name': !exists(json, 'blank_name') ? undefined : json['blank_name'],
        'color_name': !exists(json, 'color_name') ? undefined : json['color_name'],
        'view_name': !exists(json, 'view_name') ? undefined : json['view_name'],
        'design_name': !exists(json, 'design_name') ? undefined : json['design_name'],
        'hoop_id': !exists(json, 'hoop_id') ? undefined : json['hoop_id'],
        'hoop_name': !exists(json, 'hoop_name') ? undefined : json['hoop_name'],
        'session_status': !exists(json, 'session_status') ? undefined : json['session_status'],
        'production_status': !exists(json, 'production_status') ? undefined : json['production_status'],
        'start_timestamp': !exists(json, 'start_timestamp') ? undefined : (json['start_timestamp'] === null ? null : new Date(json['start_timestamp'])),
        'end_timestamp': !exists(json, 'end_timestamp') ? undefined : (json['end_timestamp'] === null ? null : new Date(json['end_timestamp'])),
        'previews': !exists(json, 'previews') ? undefined : (json['previews'] === null ? null : (json['previews'] as Array<any>).map(FusionMelcoFusionModelsSessionPreviewFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function FusionMelcoFusionModelsSessionPersonalizationListItemToJSON(value?: FusionMelcoFusionModelsSessionPersonalizationListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'session_id': value.session_id,
        'personalization_id': value.personalization_id,
        'product_id': value.product_id,
        'product_name': value.product_name,
        'product_token': value.product_token,
        'blank_id': value.blank_id,
        'blank_name': value.blank_name,
        'color_name': value.color_name,
        'view_name': value.view_name,
        'design_name': value.design_name,
        'hoop_id': value.hoop_id,
        'hoop_name': value.hoop_name,
        'session_status': value.session_status,
        'production_status': value.production_status,
        'start_timestamp': value.start_timestamp === undefined ? undefined : (value.start_timestamp === null ? null : value.start_timestamp.toISOString()),
        'end_timestamp': value.end_timestamp === undefined ? undefined : (value.end_timestamp === null ? null : value.end_timestamp.toISOString()),
        'previews': value.previews === undefined ? undefined : (value.previews === null ? null : (value.previews as Array<any>).map(FusionMelcoFusionModelsSessionPreviewToJSON)),
        'total': value.total,
    };
}


