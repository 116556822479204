{
  "colorPicker": {
    "title": "Välj en färg"
  },
  "fontPicker": {
    "title": "Välj typsnitt"
  },
  "designPicker": {
    "title": "Välj en design"
  },
  "customization": {
    "title": "Anpassa"
  },
  "designCollection": {
    "title": "Design"
  },
  "designColors": {
    "title": "Designfärger"
  },
  "lettering": {
    "color": "Färg",
    "font": "Typsnitt"
  },
  "product": {
    "color": "Produktens färg"
  },
  "loading": "Laddar, vänta...",
  "cancelSession": {
    "title": "Är du säker på att du vill stänga?",
    "text": "Du kommer att förlora din anpassning",
    "ok": "Ja, stäng",
    "cancel": "Nej"
  },
  "actionButtons": {
    "ok": "OK",
    "finish": "Klar",
    "cancel": "Avbryt",
    "close": "Stäng",
    "restart": "Börja om",
    "editPersonalization": "Redigera anpassning"
  },
  "help": {
    "lettering": {
      "text": {
        "text_max_length": "Max antal tecken är {{textMaxLength}}",
        "required": "Vänligen lägg till text; detta fält kan inte vara tomt."
      }
    }
  }
}
