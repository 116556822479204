import { useDesignListFormikPath } from "./useDesignListFormikPath";
import { ActiveView } from "./useActiveView";
import { ActiveColor } from "./useProductColor";

export const useDesignFormikPath = (
  activeView: ActiveView | undefined,
  activeColor: ActiveColor | undefined
) => {
  const designListFormikPath = useDesignListFormikPath(activeView, activeColor);

  return `${designListFormikPath}.design`;
};
