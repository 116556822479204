/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FusionMelcoFusionModelsRendererRect
 */
export interface FusionMelcoFusionModelsRendererRect {
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsRendererRect
     */
    llx?: number;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsRendererRect
     */
    lly?: number;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsRendererRect
     */
    urx?: number;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsRendererRect
     */
    ury?: number;
}

export function FusionMelcoFusionModelsRendererRectFromJSON(json: any): FusionMelcoFusionModelsRendererRect {
    return FusionMelcoFusionModelsRendererRectFromJSONTyped(json, false);
}

export function FusionMelcoFusionModelsRendererRectFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoFusionModelsRendererRect {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'llx': !exists(json, 'llx') ? undefined : json['llx'],
        'lly': !exists(json, 'lly') ? undefined : json['lly'],
        'urx': !exists(json, 'urx') ? undefined : json['urx'],
        'ury': !exists(json, 'ury') ? undefined : json['ury'],
    };
}

export function FusionMelcoFusionModelsRendererRectToJSON(value?: FusionMelcoFusionModelsRendererRect | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'llx': value.llx,
        'lly': value.lly,
        'urx': value.urx,
        'ury': value.ury,
    };
}


