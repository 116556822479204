import { useCallback } from "react";
import hasIn from "lodash-es/hasIn";
import uniq from "lodash-es/uniq";
import { convertToAPIErrors } from "./apiErrorConverter";

// e.g. [{"key":"INVALID_PASSWORD","source":"password","message":"password is incorrect"}]
export type APIError = {
  key: string;
  source: string;
  message: string;
};

type MessageWithTitle = {
  title: string;
  message: string;
};

type FieldErrorMessages = { [fieldName: string]: string[] };

const normalizeFieldName = (fieldName?: string) => {
  if (!fieldName) {
    return undefined;
  }

  return fieldName;
};

const isFormikField = (fieldPath?: string, formData?: unknown) => {
  if (!formData || !fieldPath) {
    return false;
  }

  return hasIn(formData, fieldPath);
};

export const useErrorMessageMapping = () => {
  return useCallback(
    async (error: APIError[] | Response, formData?: unknown) => {
      let fieldErrorMessages: FieldErrorMessages = {};
      const apiErrors = await convertToAPIErrors(error);

      const errorMessages: string[] = uniq(
        apiErrors.map((apiError) => apiError.message)
      );

      if (formData) {
        fieldErrorMessages = apiErrors
          .filter((apiError) =>
            isFormikField(normalizeFieldName(apiError.source), formData)
          )
          .reduce((messagesMap, apiError) => {
            messagesMap[apiError.source] = uniq([
              ...(messagesMap[apiError.source] ?? []),
              apiError.message,
            ]);

            return messagesMap;
          }, fieldErrorMessages);
      }

      const globalErrorMessages: string[] = uniq(
        apiErrors
          .filter(
            (apiError) =>
              !isFormikField(normalizeFieldName(apiError.source), formData)
          )
          .map((apiError) => apiError.message)
      );

      const feedbackMessage: MessageWithTitle = {
        title: "global.error.action.title",
        message: apiErrors[0].message,
      };

      return {
        errorMessages,
        fieldErrorMessages,
        globalErrorMessages,
        feedbackMessage,
      };
    },
    []
  );
};
