import {Matrix, MatrixUtil, Point, PointUtil, Rectangle, RectangleUtil} from './geom';

/**
 * Input output class for calculating image size based on how designs or scaled
 */
export class ViewDesignInformation {
    /** current image Rect  */
    public imageRect: Rectangle = RectangleUtil.emptyRect();
    /** Additional transformation applied to imageRect (should be identity in most cases) */
    public imageMatrix: Matrix = MatrixUtil.identityMatrix();
    /** The original rectangle of design */
    public designRects: Array<Rectangle> = [];
    /** Transforms applied to design to position on image */
    public designTransforms: Array<Matrix> = [];
    /** current view port (optional).   */
    public viewPort: Rectangle = RectangleUtil.emptyRect();

    public clone(): ViewDesignInformation {
        let r = new ViewDesignInformation();
        r.imageRect = this.imageRect;
        r.imageMatrix = this.imageMatrix;
        r.designTransforms = this.designTransforms;
        r.designRects = this.designRects;
        r.viewPort = this.viewPort;
        return r;
    }
}

/**
 * Utilties to help in computing view port.
 */
export class ViewPortUtil {
    /**
     * Computes viewport after applying zoomFactor while center point remains same.
     * 
     * @param viewPort The current viewPort
     * @param zoomFactor ZoomFactor to scale current zoom by (ie. 1.1 to zoom 10%)
     * @returns new view port 
     */
    public static zoomAroundCenter(viewPort: Rectangle, zoomFactor: number): Rectangle {
        return this.zoomAroundPoint(viewPort, RectangleUtil.center(viewPort), zoomFactor);
    }
    /**
     * Offsets current viewport by offset, useful for pan features.
     *  
     * @param viewPort Current viewport
     * @param offset offset
     * @returns new view port
     */
    public static panViewPort(viewPort: Rectangle, offset: Point): Rectangle {
        return RectangleUtil.offset(viewPort, offset.x, offset.y);
    }
    /**
     * Converts canvas pixel value to embroidery points.
     * 
     * @param p point in pixels
     * @param viewPort current viewport of canvas
     * @param canvasWidth width of canvas in pixels
     * @param canvasHeight height of canvas in pixels
     * @returns 
     */
   public static clientToLogical(p: Point, viewPort: Rectangle, canvasWidth: number, canvasHeight: number): Point {
       let cx = canvasWidth;
       let cy = canvasHeight;
       let x = p.x / cx * (viewPort.urx - viewPort.llx) + viewPort.llx;
       let y = viewPort.ury - p.y / cy * (viewPort.ury - viewPort.lly);
       return {x: x, y: y};
   }
    /**
     * Converts embroidery point to canvas pixel value.
     * 
     * @param p point in pixels
     * @param viewPort current viewport of canvas
     * @param canvasWidth width of canvas in pixels
     * @param canvasHeight height of canvas in pixels
     * @returns 
     */
   public static logicalToClient(p: Point, viewPort: Rectangle, canvasWidth: number, canvasHeight: number): Point {
       let cx = canvasWidth;
       let cy = canvasHeight;
       let px = (p.x - viewPort.llx) * cx / (viewPort.urx - viewPort.llx);
       let py = (viewPort.ury - p.y) * cy / (viewPort.ury - viewPort.lly);
       return {x: px, y: py};
   }
    /**
     * Computes new viewport applying zoomFactor and keeping viewPort center the same
     * 
     * @param viewPort The current viewport
     * @param point The point viewport should be centered at (typically current viewport center,
     *  for mousewheel zooms typically mouse coord)
     * @param zoomFactor A scale factor to zoom in by (ie. 1.1 to zoom 10%)
     * @returns new view port
     */
    public static zoomAroundPoint(viewPort: Rectangle, point: Point, zoomFactor: number): Rectangle {
        var oldCenter: Point = point;
        var oldPixel: Point = ViewPortUtil.logicalToClient(point, viewPort, 500, 500);
        var t: Rectangle = RectangleUtil.multiply(zoomFactor, viewPort);
        var newCenter: Point = ViewPortUtil.clientToLogical(oldPixel, t, 500, 500);
        var delta: Point = PointUtil.substract(oldCenter, newCenter);
        return RectangleUtil.offset(t, delta.x, delta.y);
    }
    /**
     * Computes viewport ensuring design fits
     * 
     * @param designRect The rectangle to ensure fits in view
     * @param canvasWidth The width of the canvas in pixels
     * @param canvasHeight The height of the canvas in pixels
     * @param maxdpi Dont permit dpi to go higher than this when zoom to fit (if 0 ignored)
     * @param margin Minimum margin in pixels design should be from edge of canvas.
     */
    public static zoomToFit(designRect: Rectangle, canvasWidth: number, canvasHeight: number, maxdpi: number, margin: number): Rectangle {
        let center: Point = RectangleUtil.center(designRect);
        let xmargin = Math.min(canvasWidth * .25, margin);
        let ymargin = Math.min(canvasHeight * .25, margin);
        let xscale = (designRect.urx - designRect.llx) / (canvasWidth - 2 * xmargin);
        let yscale = (designRect.ury - designRect.lly) / (canvasHeight - 2 * ymargin);
        let scale = Math.max(xscale, yscale);
        if (scale * canvasWidth < 1) {
            scale = 96.0 / 254;
        } else {
            let dpi = 254.0 / scale;
            if (maxdpi > 0 && dpi > maxdpi) {
                scale *= (dpi / maxdpi);
            }
        }
        let widthPts = canvasWidth * scale;
        let heightPts = canvasHeight * scale;
        return {llx: center.x - widthPts / 2, lly: center.y - heightPts / 2, urx: center.x + widthPts / 2, ury: center.y + heightPts / 2};
    }

    /**
     * Re-calculates image rect as if designs were moved rather than scaled.
     */
    public static normalizeViewRect(info: ViewDesignInformation): ViewDesignInformation {
        if (info.designRects.length > 0 && info.designRects.length == info.designTransforms.length &&
                RectangleUtil.width(info.imageRect) != 0 && RectangleUtil.height(info.imageRect) != 0) {
            let r = info.clone();
            let arr: Array<number> = [];
            for (let i=0;i<info.designRects.length;i++) {
                let dr = info.designRects[i];
                let t = info.designTransforms[i];
                if (RectangleUtil.width(dr) !=0 && RectangleUtil.height(dr) != 0) {
                    arr.push(PointUtil.distance(MatrixUtil.calcPoint(t, {x: dr.llx, y: RectangleUtil.center(dr).y}),
                        MatrixUtil.calcPoint(t, {x: dr.urx, y: RectangleUtil.center(dr).y})) / RectangleUtil.width(dr));
                    arr.push(PointUtil.distance(MatrixUtil.calcPoint(t, {x: RectangleUtil.center(dr).x, y: dr.lly}),
                        MatrixUtil.calcPoint(t, {x: RectangleUtil.center(dr).x, y: dr.ury})) / RectangleUtil.height(dr));
                }
            }
            if (arr.length > 0) {
                let r = info.clone();
                let scaleSum = 0;
                for (let a of arr) {
                    scaleSum += a;
                }
                let scale = scaleSum / arr.length;
                let invScale = 1.0 / scale;
                let invScaleMat = MatrixUtil.scaleMatrix(invScale, invScale);
                if (MatrixUtil.isIdentity(info.imageMatrix) || true) {
                    r.imageRect = RectangleUtil.multiply(invScale, r.imageRect);
                } else {
                    r.imageMatrix = MatrixUtil.multiply(invScaleMat, r.imageMatrix);
                }
                r.viewPort = RectangleUtil.multiply(invScale, r.viewPort);
                for (let i=0;i<info.designTransforms.length;i++) {
                    r.designTransforms[i] = (MatrixUtil.multiply(invScaleMat, r.designTransforms[i]));
                }
                let oldCenter = MatrixUtil.calcPoint(info.imageMatrix, RectangleUtil.center(info.imageRect));
                let newCenter = MatrixUtil.calcPoint(info.imageMatrix, RectangleUtil.center(r.imageRect));
                let dx = oldCenter.x - newCenter.x;
                let dy = oldCenter.y - newCenter.y;
                let tm = MatrixUtil.translateMatrix(dx, dy);
                if (MatrixUtil.isIdentity(r.imageMatrix) || true) {
                    r.imageRect = RectangleUtil.offset(r.imageRect, dx, dy);
                } else {
                    r.imageMatrix = MatrixUtil.multiply(tm, r.imageMatrix);
                }
                r.viewPort = RectangleUtil.offset(r.viewPort, dx, dy);
                for (let i=0;i<r.designTransforms.length;i++) {
                    r.designTransforms[i] = MatrixUtil.multiply(tm, r.designTransforms[i]);
                }
                return r;
            }
        }
        return info;
    }
}
