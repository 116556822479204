/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FusionMelcoFusionModelsCustomProductImageDesign,
    FusionMelcoFusionModelsCustomProductImageDesignFromJSON,
    FusionMelcoFusionModelsCustomProductImageDesignFromJSONTyped,
    FusionMelcoFusionModelsCustomProductImageDesignToJSON,
    FusionMelcoFusionModelsRendererRect,
    FusionMelcoFusionModelsRendererRectFromJSON,
    FusionMelcoFusionModelsRendererRectFromJSONTyped,
    FusionMelcoFusionModelsRendererRectToJSON,
} from './';

/**
 * 
 * @export
 * @interface FusionMelcoFusionModelsCustomProductImage
 */
export interface FusionMelcoFusionModelsCustomProductImage {
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsCustomProductImage
     */
    id?: string | null;
    /**
     * 
     * @type {FusionMelcoFusionModelsRendererRect}
     * @memberof FusionMelcoFusionModelsCustomProductImage
     */
    renderer_rect?: FusionMelcoFusionModelsRendererRect;
    /**
     * 
     * @type {Array<FusionMelcoFusionModelsCustomProductImageDesign>}
     * @memberof FusionMelcoFusionModelsCustomProductImage
     */
    design_list?: Array<FusionMelcoFusionModelsCustomProductImageDesign> | null;
}

export function FusionMelcoFusionModelsCustomProductImageFromJSON(json: any): FusionMelcoFusionModelsCustomProductImage {
    return FusionMelcoFusionModelsCustomProductImageFromJSONTyped(json, false);
}

export function FusionMelcoFusionModelsCustomProductImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoFusionModelsCustomProductImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'renderer_rect': !exists(json, 'renderer_rect') ? undefined : FusionMelcoFusionModelsRendererRectFromJSON(json['renderer_rect']),
        'design_list': !exists(json, 'design_list') ? undefined : (json['design_list'] === null ? null : (json['design_list'] as Array<any>).map(FusionMelcoFusionModelsCustomProductImageDesignFromJSON)),
    };
}

export function FusionMelcoFusionModelsCustomProductImageToJSON(value?: FusionMelcoFusionModelsCustomProductImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'renderer_rect': FusionMelcoFusionModelsRendererRectToJSON(value.renderer_rect),
        'design_list': value.design_list === undefined ? undefined : (value.design_list === null ? null : (value.design_list as Array<any>).map(FusionMelcoFusionModelsCustomProductImageDesignToJSON)),
    };
}


