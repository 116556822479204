import isArray from "lodash-es/isArray";
import isEmpty from "lodash-es/isEmpty";
import { isAPIResponse } from "../shared";
import { APIError } from "./useErrorMessageMapping";

export const convertToAPIErrors = async (
  error: APIError[] | Response | Error
) => {
  if (isArray(error)) {
    return error;
  }

  try {
    if (isAPIResponse(error)) {
      const response = error as Response;

      const errorResponse = await response.clone().json();

      if (!isArray(errorResponse) || isEmpty(errorResponse)) {
        // we don't exepect the API to return empty responses
        throw new Error();
      }

      return errorResponse as APIError[];
    }
  } catch (error) {}

  const fallbackError: APIError = {
    key: "generic",
    source: "generic",
    message:
      !("message" in error) || isEmpty(error.message)
        ? "Internal server error"
        : (error as Error).message,
  };

  return [fallbackError];
};
