import {Matrix, MatrixUtil, Rectangle, RectangleUtil} from "../geom";
import { RenderScene } from "../scene";
import { StitchElementUtil } from "../stitchelementutil";
import {ElementType, SelectionBox, SelectionBoxHit, SelectionDataElem, SelectionDataElemUtil, StitchElement} from "../elements";

export enum UpdateType {
    NONE='NONE',
    MOVE='MOVE',
    SCALE='SCALE',
    SELECT='SELECT',
    ROTATE='ROTATE'
}

export interface SelectionData {
    selectElems: Array<SelectionDataElem>;
    dragTransformation: Matrix;
    dragging: boolean ;
    dragRect?: Rectangle;
    dragHit: SelectionBoxHit;
    dragUpdateType: UpdateType;
    isRotating: boolean;
}

export class SelectionDataUtil {
    public static createEmpty(): SelectionData {
        return {
            selectElems: [],
            dragTransformation: MatrixUtil.identityMatrix(),
            dragging: false,
            dragHit: SelectionBoxHit.hitNothing,
            dragUpdateType: UpdateType.NONE,
            isRotating: false
        };
    }
    public static cloneSelectionData(r: SelectionData): SelectionData {
        let v = {...r};
        v.selectElems = [...v.selectElems];
        return v;
    }

    public static toggleRotation(data: SelectionData) {
        data.isRotating = !data.isRotating;
    }

    public static changeSelection(data: SelectionData, scene: RenderScene, selected: Array<number>): void {
        data.dragging = false;
        let se: Array<SelectionDataElem> = [];
        for (let i=0;i<selected.length;i++) {
            let ei = selected[i];
            if (ei < scene.elements.length) {
                let e = scene.elements[ei];
                if (e && e.type != ElementType.IMAGE) {
                    let selem = e as StitchElement;
                    let d = SelectionDataElemUtil.emptySelectionDataElem();
                    d.elementIndex = ei;
                    if (selem.matrix) {
                        d.originalTransformation = selem.matrix;
                    }
                    se.push(d);
                }
            }
        }
        data.selectElems = se;
        if (data.dragging) {
            SelectionDataUtil.beginDrag(data, scene, data.dragHit);
        }
    }
    public static beginDrag(data: SelectionData, scene: RenderScene, dragHit: SelectionBoxHit): void {
        data.dragHit = dragHit;
        data.dragging = true;
        data.dragTransformation = MatrixUtil.identityMatrix();
        let a: Array<SelectionDataElem> = [];
        for (let i=0;i<data.selectElems.length;i++) {
            if (data.selectElems[i].elementIndex < scene.elements.length) {
                let d = SelectionDataElemUtil.cloneSelectionDataElem(data.selectElems[i]);
                let e = scene.elements[d.elementIndex];
                if (e && e.type != ElementType.IMAGE) {
                    let selem = e as StitchElement;
                    if (selem.matrix) {
                        d.originalTransformation = selem.matrix;
                    }
                }
                a.push(d);
            }
        }
        data.selectElems = a;
    }
    public static endDrag(data: SelectionData): void {
        data.dragging = false;
    }
    public static updateDragTransformation(data: SelectionData, dragTransformation: Matrix) {
        data.dragTransformation = dragTransformation;
    }
    public static syncSelectionBox(data: SelectionData, scene: RenderScene, box: SelectionBox): void {
        let first: boolean = true;
        if (data.dragRect && data.dragging) {
            box.rect = data.dragRect;
        }
        if (!data.dragging) {
            if (!MatrixUtil.isIdentity(data.dragTransformation)) {
                data.dragTransformation = MatrixUtil.identityMatrix();
            }
            box.rect = undefined;
            box.display = false;
            box.selectedElemIds = [];
            box.isRotate = data.isRotating;
            box.matrix = undefined;
            for (let i=0;i<data.selectElems.length;i++) {
                if (data.selectElems[i].elementIndex < scene.elements.length) {
                    let e = scene.elements[data.selectElems[i].elementIndex];
                    if (e && e.type != ElementType.IMAGE) {
                        let selem = e as StitchElement;
                        if (selem.matrix) {
                            data.selectElems[i].originalTransformation = selem.matrix;
                        }
                        box.selectedElemIds.push(e.uid);
                        box.display = true;
                    }
                }
            }
        } else if (data.dragRect) {
            box.selectedElemIds = undefined;
            box.isRotate = data.isRotating;
            box.display = false;
            for (let i=0;i<data.selectElems.length;i++) {
                if (data.selectElems[i].elementIndex < scene.elements.length) {
                    let e = scene.elements[data.selectElems[i].elementIndex];
                    if (e && e.type != ElementType.IMAGE) {
                        let selem = e as StitchElement;
                        let e2 = StitchElementUtil.cloneStitchElement(selem);
                        e2.matrix = MatrixUtil.multiply(data.dragTransformation, data.selectElems[i].originalTransformation);
                        scene.elements[data.selectElems[i].elementIndex] = e2;
                        box.display = true;
                    }
                }
            }
            if (data.isRotating) {
                box.matrix = data.dragTransformation;
                box.rect = data.dragRect;
            } else {
                box.matrix = undefined;
                if (data.dragRect) {
                    box.rect = data.dragRect;
                }
            }
        } else {
            box.display = false;
        }
    }
}

