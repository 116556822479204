import { useBaseAPIConfig } from "melco-shared-logic/dist/hooks/api/useAPIConfig";
import { useMemo } from "react";

export const useAPIConfigOptions = (apiKey: string) => {
  const basePath = process.env.REACT_APP_API_BASE_URL;

  return useMemo(() => {
    return {
      apiKey,
      basePath,
      skipRedirectToLoginIfUnauthorized: true,
    };
  }, [apiKey, basePath]);
};

export const useAPIConfig = (apiKey: string) => {
  const apiConfigOptions = useAPIConfigOptions(apiKey);
  const baseApiConfig = useBaseAPIConfig();

  return useMemo(() => {
    return baseApiConfig(apiConfigOptions);
  }, [apiConfigOptions, baseApiConfig]);
};
