import { useFormikContext } from "formik";
import { ConfiguratorApi } from "melco-shared-logic/dist/client-fusion/apis/ConfiguratorApi";
import { FusionMelcoFusionModelsSession } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoFusionModelsSession";
import { useMutation } from "react-query";
import { OnCompleteCallback } from "..";
import { useAPIConfig } from "./useAPIConfig";

export const useFinishSession = (
  apiKey: string,
  onCompleteCallback: OnCompleteCallback
) => {
  const apiConfig = useAPIConfig(apiKey);
  const { values } = useFormikContext<
    FusionMelcoFusionModelsSession | undefined
  >();

  return useMutation(async () => {
    const api = new ConfiguratorApi(apiConfig);

    if (!values) {
      return;
    }

    const completedSession = await api.configuratorSessionsComplete({
      sessionId: values.session_id!,
      fusionMelcoFusionModelsSession: values,
    });

    onCompleteCallback(completedSession);
  });
};
