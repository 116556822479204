import FocusTrap from "focus-trap-react";
import { Button } from "../button/Button";
import { ErrorIcon } from "../icons/icons";
import { ConfirmBackdrop } from "./backdrop/ConfirmBackdrop";

import "./Confirm.css";

export type ConfirmConfig = {
  title: string;
  text: string;
  onCancel: () => void;
  onCancelText: string;
  onOk: () => void;
  onOkText: string;
};

type ConfirmProps = {
  config: ConfirmConfig;
};

export const Confirm: React.FC<ConfirmProps> = ({ config }) => {
  const { title, text, onCancel, onCancelText, onOk, onOkText } = config;

  return (
    <>
      <FocusTrap>
        <div className="mfc__confirm">
          <div className="mfc__confirm-inner">
            <div className="mfc__confirm-content">
              <ErrorIcon />

              <div className="mfc__confirm-contents">
                <div className="mfc__confirm-title">{title}</div>
                <span>{text}</span>
              </div>

              <div className="mfc__confirm-buttons">
                <Button onClick={onCancel}>{onCancelText}</Button>

                <Button onClick={onOk} type="primary">
                  {onOkText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </FocusTrap>

      <ConfirmBackdrop />
    </>
  );
};
