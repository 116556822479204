/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FusionMelcoFusionModelsDefaultImage,
    FusionMelcoFusionModelsDefaultImageFromJSON,
    FusionMelcoFusionModelsDefaultImageFromJSONTyped,
    FusionMelcoFusionModelsDefaultImageToJSON,
    FusionMelcoModelsCanvasPreview,
    FusionMelcoModelsCanvasPreviewFromJSON,
    FusionMelcoModelsCanvasPreviewFromJSONTyped,
    FusionMelcoModelsCanvasPreviewToJSON,
    FusionMelcoModelsCustomWarning,
    FusionMelcoModelsCustomWarningFromJSON,
    FusionMelcoModelsCustomWarningFromJSONTyped,
    FusionMelcoModelsCustomWarningToJSON,
    FusionMelcoModelsUserImage,
    FusionMelcoModelsUserImageFromJSON,
    FusionMelcoModelsUserImageFromJSONTyped,
    FusionMelcoModelsUserImageToJSON,
} from './';

/**
 * 
 * @export
 * @interface FusionMelcoFusionModelsUserBlank
 */
export interface FusionMelcoFusionModelsUserBlank {
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsUserBlank
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsUserBlank
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsUserBlank
     */
    canvas_width?: number;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsUserBlank
     */
    canvas_height?: number;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsUserBlank
     */
    views_count?: number;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsUserBlank
     */
    colors_count?: number;
    /**
     * 
     * @type {FusionMelcoFusionModelsDefaultImage}
     * @memberof FusionMelcoFusionModelsUserBlank
     */
    default_image?: FusionMelcoFusionModelsDefaultImage;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsUserBlank
     */
    publish_state?: number;
    /**
     * 
     * @type {Date}
     * @memberof FusionMelcoFusionModelsUserBlank
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FusionMelcoFusionModelsUserBlank
     */
    publish_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FusionMelcoFusionModelsUserBlank
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {Array<FusionMelcoModelsUserImage>}
     * @memberof FusionMelcoFusionModelsUserBlank
     */
    images?: Array<FusionMelcoModelsUserImage> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FusionMelcoFusionModelsUserBlank
     */
    colors?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FusionMelcoFusionModelsUserBlank
     */
    views?: Array<string> | null;
    /**
     * 
     * @type {Array<FusionMelcoModelsCanvasPreview>}
     * @memberof FusionMelcoFusionModelsUserBlank
     */
    canvas_previews?: Array<FusionMelcoModelsCanvasPreview> | null;
    /**
     * 
     * @type {Array<FusionMelcoModelsCustomWarning>}
     * @memberof FusionMelcoFusionModelsUserBlank
     */
    warning_list?: Array<FusionMelcoModelsCustomWarning> | null;
}

export function FusionMelcoFusionModelsUserBlankFromJSON(json: any): FusionMelcoFusionModelsUserBlank {
    return FusionMelcoFusionModelsUserBlankFromJSONTyped(json, false);
}

export function FusionMelcoFusionModelsUserBlankFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoFusionModelsUserBlank {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'canvas_width': !exists(json, 'canvas_width') ? undefined : json['canvas_width'],
        'canvas_height': !exists(json, 'canvas_height') ? undefined : json['canvas_height'],
        'views_count': !exists(json, 'views_count') ? undefined : json['views_count'],
        'colors_count': !exists(json, 'colors_count') ? undefined : json['colors_count'],
        'default_image': !exists(json, 'default_image') ? undefined : FusionMelcoFusionModelsDefaultImageFromJSON(json['default_image']),
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'publish_date': !exists(json, 'publish_date') ? undefined : (json['publish_date'] === null ? null : new Date(json['publish_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'images': !exists(json, 'images') ? undefined : (json['images'] === null ? null : (json['images'] as Array<any>).map(FusionMelcoModelsUserImageFromJSON)),
        'colors': !exists(json, 'colors') ? undefined : json['colors'],
        'views': !exists(json, 'views') ? undefined : json['views'],
        'canvas_previews': !exists(json, 'canvas_previews') ? undefined : (json['canvas_previews'] === null ? null : (json['canvas_previews'] as Array<any>).map(FusionMelcoModelsCanvasPreviewFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(FusionMelcoModelsCustomWarningFromJSON)),
    };
}

export function FusionMelcoFusionModelsUserBlankToJSON(value?: FusionMelcoFusionModelsUserBlank | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'canvas_width': value.canvas_width,
        'canvas_height': value.canvas_height,
        'views_count': value.views_count,
        'colors_count': value.colors_count,
        'default_image': FusionMelcoFusionModelsDefaultImageToJSON(value.default_image),
        'publish_state': value.publish_state,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date === null ? null : value.publish_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'images': value.images === undefined ? undefined : (value.images === null ? null : (value.images as Array<any>).map(FusionMelcoModelsUserImageToJSON)),
        'colors': value.colors,
        'views': value.views,
        'canvas_previews': value.canvas_previews === undefined ? undefined : (value.canvas_previews === null ? null : (value.canvas_previews as Array<any>).map(FusionMelcoModelsCanvasPreviewToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(FusionMelcoModelsCustomWarningToJSON)),
    };
}


