/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FusionMelcoFusionModelsSessionPersonalizationListItem,
    FusionMelcoFusionModelsSessionPersonalizationListItemFromJSON,
    FusionMelcoFusionModelsSessionPersonalizationListItemFromJSONTyped,
    FusionMelcoFusionModelsSessionPersonalizationListItemToJSON,
    FusionMelcoModelsCustomWarning,
    FusionMelcoModelsCustomWarningFromJSON,
    FusionMelcoModelsCustomWarningFromJSONTyped,
    FusionMelcoModelsCustomWarningToJSON,
} from './';

/**
 * 
 * @export
 * @interface FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItem
 */
export interface FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItem {
    /**
     * 
     * @type {Array<FusionMelcoFusionModelsSessionPersonalizationListItem>}
     * @memberof FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItem
     */
    list: Array<FusionMelcoFusionModelsSessionPersonalizationListItem>;
    /**
     * 
     * @type {Array<FusionMelcoModelsCustomWarning>}
     * @memberof FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItem
     */
    warning_list?: Array<FusionMelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItem
     */
    total: number;
}

export function FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItemFromJSON(json: any): FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItem {
    return FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItemFromJSONTyped(json, false);
}

export function FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(FusionMelcoFusionModelsSessionPersonalizationListItemFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(FusionMelcoModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItemToJSON(value?: FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(FusionMelcoFusionModelsSessionPersonalizationListItemToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(FusionMelcoModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


