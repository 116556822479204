import { useField } from "formik";
import { FusionMelcoFusionModelsCustomProductView } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoFusionModelsCustomProductView";

export const useAvailableViews = () => {
  const [{ value: viewList }] = useField<
    FusionMelcoFusionModelsCustomProductView[] | undefined
  >("product.view_list");

  return viewList ?? [];
};
