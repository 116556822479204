import { useTranslation } from "react-i18next";
import { useFeature } from "flagged";
import { LabeledFloatingButton } from "../labeledFloatingButton/LabeledFloatingButton";
import { ActiveFont } from "../../hooks/useElementFonts";

type LetteringFloatingButtonProps = {
  font: ActiveFont | undefined;
  onClick?: () => void;
};

export const LetteringFloatingButton: React.FC<
  LetteringFloatingButtonProps
> = ({ font, onClick }) => {
  const { t } = useTranslation();

  const isLabeledFeatureEnabled = useFeature("ui/labeled");

  if (!font) {
    return null;
  }

  const { name, previewThumbnailUrl } = font;

  return (
    <LabeledFloatingButton
      label={isLabeledFeatureEnabled ? font.name : t("lettering.font")}
      onClick={onClick}
    >
      <img src={previewThumbnailUrl} alt={name} />
    </LabeledFloatingButton>
  );
};
