/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FusionMelcoFusionModelsCustomProduct,
    FusionMelcoFusionModelsCustomProductFromJSON,
    FusionMelcoFusionModelsCustomProductFromJSONTyped,
    FusionMelcoFusionModelsCustomProductToJSON,
    FusionMelcoFusionModelsFusionConfiguratorSetting,
    FusionMelcoFusionModelsFusionConfiguratorSettingFromJSON,
    FusionMelcoFusionModelsFusionConfiguratorSettingFromJSONTyped,
    FusionMelcoFusionModelsFusionConfiguratorSettingToJSON,
    FusionMelcoFusionModelsProductionStatusHistory,
    FusionMelcoFusionModelsProductionStatusHistoryFromJSON,
    FusionMelcoFusionModelsProductionStatusHistoryFromJSONTyped,
    FusionMelcoFusionModelsProductionStatusHistoryToJSON,
    FusionMelcoFusionModelsSessionPreview,
    FusionMelcoFusionModelsSessionPreviewFromJSON,
    FusionMelcoFusionModelsSessionPreviewFromJSONTyped,
    FusionMelcoFusionModelsSessionPreviewToJSON,
} from './';

/**
 * 
 * @export
 * @interface FusionMelcoFusionModelsSession
 */
export interface FusionMelcoFusionModelsSession {
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSession
     */
    session_id?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsSession
     */
    session_status?: number;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsSession
     */
    production_status?: number;
    /**
     * 
     * @type {Date}
     * @memberof FusionMelcoFusionModelsSession
     */
    start_timestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FusionMelcoFusionModelsSession
     */
    end_timestamp?: Date | null;
    /**
     * 
     * @type {FusionMelcoFusionModelsCustomProduct}
     * @memberof FusionMelcoFusionModelsSession
     */
    product?: FusionMelcoFusionModelsCustomProduct;
    /**
     * 
     * @type {Array<FusionMelcoFusionModelsSessionPreview>}
     * @memberof FusionMelcoFusionModelsSession
     */
    previews?: Array<FusionMelcoFusionModelsSessionPreview> | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsSession
     */
    selected_color_image_id?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FusionMelcoFusionModelsSession
     */
    is_edits_applied?: boolean;
    /**
     * 
     * @type {FusionMelcoFusionModelsFusionConfiguratorSetting}
     * @memberof FusionMelcoFusionModelsSession
     */
    configurator_setting?: FusionMelcoFusionModelsFusionConfiguratorSetting;
    /**
     * 
     * @type {Array<FusionMelcoFusionModelsProductionStatusHistory>}
     * @memberof FusionMelcoFusionModelsSession
     */
    production_status_history?: Array<FusionMelcoFusionModelsProductionStatusHistory> | null;
}

export function FusionMelcoFusionModelsSessionFromJSON(json: any): FusionMelcoFusionModelsSession {
    return FusionMelcoFusionModelsSessionFromJSONTyped(json, false);
}

export function FusionMelcoFusionModelsSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoFusionModelsSession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'session_id': !exists(json, 'session_id') ? undefined : json['session_id'],
        'session_status': !exists(json, 'session_status') ? undefined : json['session_status'],
        'production_status': !exists(json, 'production_status') ? undefined : json['production_status'],
        'start_timestamp': !exists(json, 'start_timestamp') ? undefined : (new Date(json['start_timestamp'])),
        'end_timestamp': !exists(json, 'end_timestamp') ? undefined : (json['end_timestamp'] === null ? null : new Date(json['end_timestamp'])),
        'product': !exists(json, 'product') ? undefined : FusionMelcoFusionModelsCustomProductFromJSON(json['product']),
        'previews': !exists(json, 'previews') ? undefined : (json['previews'] === null ? null : (json['previews'] as Array<any>).map(FusionMelcoFusionModelsSessionPreviewFromJSON)),
        'selected_color_image_id': !exists(json, 'selected_color_image_id') ? undefined : json['selected_color_image_id'],
        'is_edits_applied': !exists(json, 'is_edits_applied') ? undefined : json['is_edits_applied'],
        'configurator_setting': !exists(json, 'configurator_setting') ? undefined : FusionMelcoFusionModelsFusionConfiguratorSettingFromJSON(json['configurator_setting']),
        'production_status_history': !exists(json, 'production_status_history') ? undefined : (json['production_status_history'] === null ? null : (json['production_status_history'] as Array<any>).map(FusionMelcoFusionModelsProductionStatusHistoryFromJSON)),
    };
}

export function FusionMelcoFusionModelsSessionToJSON(value?: FusionMelcoFusionModelsSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'session_id': value.session_id,
        'session_status': value.session_status,
        'production_status': value.production_status,
        'start_timestamp': value.start_timestamp === undefined ? undefined : (value.start_timestamp.toISOString()),
        'end_timestamp': value.end_timestamp === undefined ? undefined : (value.end_timestamp === null ? null : value.end_timestamp.toISOString()),
        'product': FusionMelcoFusionModelsCustomProductToJSON(value.product),
        'previews': value.previews === undefined ? undefined : (value.previews === null ? null : (value.previews as Array<any>).map(FusionMelcoFusionModelsSessionPreviewToJSON)),
        'selected_color_image_id': value.selected_color_image_id,
        'is_edits_applied': value.is_edits_applied,
        'configurator_setting': FusionMelcoFusionModelsFusionConfiguratorSettingToJSON(value.configurator_setting),
        'production_status_history': value.production_status_history === undefined ? undefined : (value.production_status_history === null ? null : (value.production_status_history as Array<any>).map(FusionMelcoFusionModelsProductionStatusHistoryToJSON)),
    };
}


