/**
 * Immutable class representing point.
 */
export interface Point {
    readonly x: number,
    readonly y: number
}

export class PointUtil {
    public static createPoint(x: number, y: number) {
        return {
            x: x,
            y: y
        }
    }
    public static emptyPoint(): Point {
        return {
            x: 0, y: 0
        }
    }
    public static equals(lhs: Point, rhs: Point): boolean {
        return lhs.x == rhs.x && lhs.y == rhs.y;
    }
    public static distance(p1: Point, p2: Point): number {
        let dx = p1.x - p2.x;
        let dy = p1.y - p2.y;
        return Math.sqrt(dx * dx + dy * dy);
    }
    public static substract(a: Point, b: Point): Point {
        return {
            x: a.x - b.x,
            y: a.y - b.y
        }
    }
    public static add(a: Point, b: Point): Point {
        return {
            x: a.x + b.x,
            y: a.y + b.y
        }
    }
}

export interface Rectangle {
    /** Left most value of rectangle */
    readonly llx: number;
    /** Bottom of rectangle */
    readonly lly: number;
    /** Right of rectangle */
    readonly urx: number;
    /** Top of rectangle */
    readonly ury: number;
}

export class RectangleUtil {
    public static emptyRect(): Rectangle {
        return {
            llx: 0, lly: 0, urx: 0, ury: 0
        }
    }
    public static createRect(llx: number, lly: number, urx: number, ury: number) {
        return {
            llx: llx,
            lly: lly,
            urx: urx,
            ury: ury
        }
    }
    public static equals(lhs: Rectangle, rhs: Rectangle) : boolean {
        return lhs.llx === rhs.llx && lhs.lly === rhs.lly && lhs.ury === rhs.ury && lhs.urx === rhs.urx;
    }
    /** Center point of rectangle */
    public static center(r: Rectangle): Point {
        return {
            x: .5 * (r.llx + r.urx),
            y: .5 * (r.lly + r.ury)
        }
    }
    /** True if rectangle empty */
    public static empty(r: Rectangle): boolean {
        return r.llx == r.urx && r.lly == r.ury;
    }
    /** Width of rectangle */
    public static width(r: Rectangle): number {
        return r.urx - r.llx;
    }
    /** Height of rectangle */
    public static height(r: Rectangle): number {
        return r.ury - r.lly;
    }
    /** Returns union of current rectangle with rectangle passed in. */
    public static union(lhs: Rectangle, rhs: Rectangle): Rectangle {
        return {
            llx: Math.min(lhs.llx, rhs.llx),
            lly: Math.min(lhs.lly, rhs.lly),
            urx: Math.max(lhs.urx, rhs.urx),
            ury: Math.max(lhs.ury, rhs.ury),
        }
    }
    /** True if point contained in rectangle */
    public static contains(r: Rectangle, p: Point): boolean {
        return (p.x - r.llx) * (p.x - r.urx) <=0 && (p.y - r.lly) * (p.y - r.ury) <= 0;
    }
    /** Multiply rectangle by constant */
    public static multiply(a: number, r: Rectangle): Rectangle {
        return {
            llx: a * r.llx,
            lly: a * r.lly,
            urx: a * r.urx,
            ury: a * r.ury
        };
    }
    /** offset rectangle */
    public static offset(r: Rectangle, dx: number, dy: number) {
        return {
            llx: r.llx + dx,
            lly: r.lly + dy,
            urx: r.urx + dx,
            ury: r.ury + dy
        }
    }

}

export interface Color {
    /** Color value from 0-255 */
    readonly red: number;
    /** Color value from 0-255 */
    readonly green: number;
    /** Color value from 0-255 */
    readonly blue: number;
    /** alpha value from 0-255, typically 255 */
    readonly alpha: number
}

export class ColorUtil {
    public static equals(lhs: Color, rhs: Color): boolean {
        return lhs.red == rhs.red && lhs.green == rhs.green && lhs.blue == rhs.blue && lhs.alpha == rhs.alpha;
    }
    public static createColor(r: number, g: number, b: number, a: number = 255) {
        return {
            red: r,
            green: g,
            blue: b,
            alpha: a
        }
    }
    public static fromHexVal(hex: number, alpha: number = 255) : Color {
        return {
            red: (hex & 0xFF0000) >> 16,
            green: (hex & 0xFF00) >> 8,
            blue: (hex & 0xFF),
            alpha: alpha
        }
    }
}

export interface Matrix {
    readonly m00: number;
    readonly m01: number;
    readonly m02: number;
    readonly m10: number;
    readonly m11: number;
    readonly m12: number;
}

export class MatrixUtil {
    public static identityMatrix(): Matrix {
        return {
            m00: 1,
            m01: 0,
            m02: 0,
            m10: 0,
            m11: 1,
            m12: 0
        }
    }
    public static createMatrix(m00: number, m01: number, m02: number, m10: number, m11: number, m12: number) {
        return {
            m00: m00,
            m01: m01,
            m02: m02,
            m10: m10,
            m11: m11,
            m12: m12
        }
    }
    public static scaleMatrix(sx: number, sy: number): Matrix {
        return {
            m00: sx,
            m01: 0,
            m02: 0,
            m10: 0,
            m11: sy,
            m12: 0
        }
    }
    public static translateMatrix(tx: number, ty: number): Matrix {
        return {
            m00: 1,
            m01: 0,
            m02: tx,
            m10: 0,
            m11: 1,
            m12: ty
        }
    }
    public static rotateMatrix(angleRadians: number): Matrix {
        return {
            m00: Math.cos(angleRadians),
            m01: -Math.sin(angleRadians),
            m02: 0,
            m10: Math.sin(angleRadians),
            m11: Math.cos(angleRadians),
            m12: 0
        }
    }
    /** result = a * b */
    public static multiply(a:Matrix, b: Matrix) {
        var m00: number = a.m00 * b.m00 + a.m01 * b.m10;
        var m01: number = a.m00 * b.m01 + a.m01 * b.m11;
        var m02: number = a.m00 * b.m02 + a.m01 * b.m12 + a.m02;
        var m10: number = a.m10 * b.m00 + a.m11 * b.m10;
        var m11: number = a.m10 * b.m01 + a.m11 * b.m11;
        var m12: number = a.m10 * b.m02 + a.m11 * b.m12 + a.m12;
        return {
            m00: m00,
            m01: m01,
            m02: m02,
            m10: m10,
            m11: m11,
            m12: m12
        }
    }
    /** Matrix rotates point around (cx,cy) */
    public static rotateAroundPoint(angleRadians: number, cx: number, cy: number): Matrix {
        let t1 = MatrixUtil.translateMatrix(-cx, -cy);
        let r1 = MatrixUtil.rotateMatrix(angleRadians);
        let t2 = MatrixUtil.translateMatrix(cx, cy);
        return MatrixUtil.multiply(t2, MatrixUtil.multiply(r1, t1));
    }
    /** Matrix scales from old rect to new rect */
    public static scaleToRect(rectOld: Rectangle, rectNew: Rectangle): Matrix {
        if (RectangleUtil.width(rectOld) == 0 || RectangleUtil.height(rectOld) == 0) {
            let cOld = RectangleUtil.center(rectOld);
            let cNew = RectangleUtil.center(rectNew);
            return MatrixUtil.translateMatrix(cNew.x - cOld.x, cNew.y - cOld.y);
        }
        let t1 = MatrixUtil.translateMatrix(-rectOld.llx, -rectOld.lly);
        let s1 = MatrixUtil.scaleMatrix((rectNew.urx - rectNew.llx)/(rectOld.urx - rectOld.llx), (rectNew.ury - rectNew.lly)/(rectOld.ury - rectOld.lly));
        let t2 = MatrixUtil.translateMatrix(rectNew.llx, rectNew.lly);
        return MatrixUtil.multiply(t2, MatrixUtil.multiply(s1, t1));
    }
    public static equals(lhs: Matrix, rhs: Matrix): boolean {
        return lhs.m00 === rhs.m00 && lhs.m01 === rhs.m01 && lhs.m02 === rhs.m02
            && lhs.m10 === rhs.m10 && lhs.m11 === rhs.m11 && lhs.m12 === rhs.m12;
    }
    /** Result when matrix applied to point. */
    public static calcPoint(m: Matrix, pt: Point): Point {
        return {
            x: m.m00 * pt.x + m.m01 * pt.y + m.m02, 
            y: m.m10 * pt.x + m.m11 * pt.y + m.m12
        }
    }
    /** true if identity matrix */
    public static isIdentity(m: Matrix): boolean {
        return m.m00 == 1 && m.m11 ==1 &&
            m.m01 == 0 && m.m10 == 0 && m. m02== 0 && m.m12 == 0;
    }
    /** Removes rotation from matrix */
    public static getUnrotatedMatrix(matrix: Matrix): {matrix: Matrix, angleRadians: number} {
        const p = MatrixUtil.calcPoint(matrix, {x: 0, y: 0})
        const p2 = MatrixUtil.calcPoint(matrix, {x: 1, y: 0})
        const v = {x: p2.x - p.x, y: p2.y - p.y}
        let rotation = (v.x == 0 && v.y == 0 ) ? 0 : Math.atan2(v.y, v.x)

        let temp = MatrixUtil.multiply(MatrixUtil.rotateMatrix(-rotation), matrix)
        if (temp.m11 < 0) {
            rotation += Math.PI
            temp = MatrixUtil.multiply(MatrixUtil.rotateMatrix(-Math.PI), temp)
        }
        if (rotation < -Math.PI) rotation += 2*Math.PI
        if (rotation > Math.PI) rotation -= 2*Math.PI

        return {matrix: temp, angleRadians: rotation}
    }
    /** invert matrix */
    public invert(m: Matrix): Matrix {
        let det = m.m00 * m.m11 - m.m01 * m.m10;
        if (det != 0) {
            let m00 = (m.m11) / det;
            let m01 = -(m.m01) / det;
            let m02 = (m.m01 * m.m12 - m.m02 * m.m11) / det;
            let m10 = -(m.m10) / det;
            let m11 = (m.m00) / det;
            let m12 = -(m.m00 * m.m12 - m.m02 * m.m10) / det;
            return {
                m00: m00,
                m01: m01,
                m02: m02,
                m10: m10,
                m11: m11,
                m12: m12
            }
        }
        return MatrixUtil.identityMatrix();
    }
}
