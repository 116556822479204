import { useCallback, useState } from "react";
import { FlagsProvider } from "flagged";
import { ConfiguratorOptions } from "../../index";
import { ConfiguratorSession } from "./ConfiguratorSession";
import { ConfiguratorSessionInitializer } from "./ConfiguratorSessionInitializer";
import { useGetOrStartSession } from "../../hooks/useGetOrStartSession";
import { useErrorMessage } from "../../hooks/useErrorMessage";
import { FusionMelcoFusionModelsSession } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoFusionModelsSession";
import { useConfiguratorFeatures } from "../../hooks/useConfiguratorFeatures";

type ConfiguratorProps = {
  token: string;
  apiKey: string;
  options: ConfiguratorOptions;
};

export const Configurator: React.FC<ConfiguratorProps> = ({
  token,
  apiKey,
  options,
}) => {
  const [isConfigurationFinished, setConfigurationFinished] = useState(false);

  const [isViewModeEnabled, setViewModeEnabled] = useState(
    options.startInViewMode ?? false
  );

  const {
    sessionId,
    isProductColorChangeDisabled,
    isDesignChangeDisabled,
    defaultDesignIDs,
    color,
    defaultViewName,
  } = options;

  const {
    session,
    isLoading,
    error: startSessionError,
    onRestart,
  } = useGetOrStartSession(apiKey, token, sessionId, isViewModeEnabled);

  const configuratorFeatures = useConfiguratorFeatures(session);

  const errorMessage = useErrorMessage(startSessionError);

  const onCancel = useCallback(() => {
    setConfigurationFinished(true);

    if (options.onCancel) {
      options.onCancel();
    }
  }, [options.onCancel]);

  const onComplete = useCallback(
    (session: FusionMelcoFusionModelsSession) => {
      setConfigurationFinished(true);

      if (options.onComplete) {
        options.onComplete(session);
      }
    },
    [options.onComplete]
  );

  if (isConfigurationFinished) {
    // close the configurator
    return null;
  }

  if (!session || isLoading || errorMessage) {
    return (
      <ConfiguratorSessionInitializer
        onCancelCallback={onCancel}
        loading={isLoading}
        errorMessage={errorMessage}
      />
    );
  }

  return (
    <FlagsProvider features={configuratorFeatures}>
      <ConfiguratorSession
        key={session.session_id}
        session={session}
        apiKey={apiKey}
        defaultColor={color}
        defaultViewName={defaultViewName}
        onCancelCallback={onCancel}
        onCompleteCallback={onComplete}
        onRestart={onRestart}
        isProductColorChangeDisabled={isProductColorChangeDisabled}
        isDesignChangeDisabled={isDesignChangeDisabled}
        defaultDesignIds={defaultDesignIDs}
        isViewModeEnabled={isViewModeEnabled}
        setViewModeEnabled={setViewModeEnabled}
      />
    </FlagsProvider>
  );
};
