import ZoomInSvg from "./icons/Zoom-In.svg";
import ZoomOutSvg from "./icons/Zoom-Out.svg";
import CloseSvg from "./icons/Close.svg";
import ErrorSvg from "./icons/Error.svg";
import ArrowSvg from "./icons/Arrow.svg";

const ZoomInIcon = () => <ZoomInSvg />;
const ZoomOutIcon = () => <ZoomOutSvg />;
const CloseIcon = () => <CloseSvg />;
const ErrorIcon = () => <ErrorSvg />;
const ArrowIcon = () => <ArrowSvg />;

export { ZoomInIcon, ZoomOutIcon, CloseIcon, ErrorIcon, ArrowIcon };
