import {
  FloatingButton,
  FloatingButtonProps,
} from "../floatingButton/FloatingButton";

import "./LabeledFloatingButton.css";

type LabeledFloatingButtonProps = FloatingButtonProps & {
  label: string;
};

const LabeledFloatingButton: React.FC<LabeledFloatingButtonProps> = ({
  label,
  ...rest
}) => (
  <div className="mfc__labeled-floating-btn">
    <FloatingButton {...rest} />
    <label title={label}>{label}</label>
  </div>
);

export { LabeledFloatingButton };
