import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFeature } from "flagged";
import { ColorHelper } from "melco-ui/dist/helper/ColorHelper";
import { PersonalizeEditItem } from "../personalize/editItem/PersonalizeEditItem";
import { LabeledFloatingButton } from "../labeledFloatingButton/LabeledFloatingButton";
import { ActiveColor } from "../../hooks/useProductColor";
import { ColorPicker } from "./ColorPicker";
import { useElementColors } from "../../hooks/useElementColors";
import { ActiveView } from "../../hooks/useActiveView";

const { argbToRGB } = ColorHelper;

type LetteringColorProps = {
  activeView: ActiveView;
  elementIndex: number;
};

export const LetteringColor: React.FC<LetteringColorProps> = ({
  activeView,
  elementIndex,
}) => {
  const { t } = useTranslation();

  const isLabeledFeatureEnabled = useFeature("ui/labeled");

  const [availableColors, activeColor, setColor] = useElementColors(
    activeView,
    elementIndex
  );

  const [isPickerVisible, setPickerVisible] = useState(false);

  const showPicker = useCallback(() => {
    setPickerVisible(true);
  }, [setPickerVisible]);

  const closePicker = useCallback(() => {
    setPickerVisible(false);
  }, [setPickerVisible]);

  const onColorChange = useCallback(
    (color: ActiveColor | undefined) => {
      setColor(color);
    },
    [setColor]
  );

  if (availableColors.length <= 0 || !activeColor) {
    return null;
  }

  return (
    <>
      <PersonalizeEditItem>
        <LabeledFloatingButton
          backgroundColor={argbToRGB(activeColor?.argb)}
          label={
            isLabeledFeatureEnabled
              ? activeColor?.name ?? ""
              : t("lettering.color")
          }
          onClick={showPicker}
        />
      </PersonalizeEditItem>

      <ColorPicker
        key={activeColor.id}
        isPickerVisible={isPickerVisible}
        closePicker={closePicker}
        initialColor={activeColor}
        onColorChange={onColorChange}
        availableColors={availableColors}
      />
    </>
  );
};
