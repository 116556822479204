import { useFormikContext } from "formik";
import { FusionMelcoFusionModelsCustomProductView } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoFusionModelsCustomProductView";
import { useEffect } from "react";
import { designFormikPrefix } from "../../helper/formikPrefixHelper";
import { useFetchDesignCollection } from "../../hooks/useFetchDesignCollection";

type SetDefaultDesignProps = {
  apiKey: string;
  availableViews: FusionMelcoFusionModelsCustomProductView[];
  viewName: string;
  designCollectionId: string;
  designId: string;
};

export const SetDefaultDesign: React.FC<SetDefaultDesignProps> = ({
  apiKey,
  availableViews,
  viewName,
  designCollectionId,
  designId,
}) => {
  const { setFieldValue } = useFormikContext();

  const [availableDesigns, isLoading] = useFetchDesignCollection(
    designCollectionId,
    apiKey
  );

  const design = availableDesigns.find((d) => d.id === designId);

  useEffect(() => {
    if (isLoading || !design) {
      return;
    }

    availableViews.forEach((view, viewIdx) => {
      if (view.name !== viewName) {
        return;
      }

      // set default design from design collection for each color of the current view
      (view.color_list ?? []).forEach((_color, colorIdx) => {
        setFieldValue(
          designFormikPrefix({ name: viewName, index: viewIdx }, colorIdx),
          design
        );
      });
    });
  }, [design?.id]);

  return null;
};
