import { StitchCanvas } from "@melco/renderer-react-component";
import { FusionMelcoModelsDesign } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoModelsDesign";
import { FusionMelcoFusionModelsRendererMatrix } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoFusionModelsRendererMatrix";
import { useTranslation } from "react-i18next";
import { useElementConfiguration } from "../../hooks/useElementConfiguration";
import { useBlankPictureUrl } from "../../hooks/useBlankPictureUrl";
import { useRenderer } from "../../hooks/useRenderer";
import { ActiveColor } from "../../hooks/useProductColor";
import { ActiveView } from "../../hooks/useActiveView";
import { ZoomButton } from "./ZoomButton";
import "./Preview.css";

type PreviewProps = {
  design: FusionMelcoModelsDesign | undefined;
  designRendererMatrix: FusionMelcoFusionModelsRendererMatrix | undefined;
  activeView: ActiveView | undefined;
  activeProductColor: ActiveColor | undefined;
};

export const Preview: React.FC<PreviewProps> = ({
  design,
  designRendererMatrix,
  activeView,
  activeProductColor,
}) => {
  const { t } = useTranslation();

  const blankPictureUrl = useBlankPictureUrl(activeView, activeProductColor);

  const elementConfiguration = useElementConfiguration(
    activeView,
    activeProductColor,
    design?.rfm_url ?? undefined,
    blankPictureUrl ?? undefined
  );

  const {
    canvasId,
    canvas,
    initCallback,
    rendererState,
    isLoading,
    rendererDispatch,
    elementUtil,
  } = useRenderer(
    blankPictureUrl ?? "",
    design!,
    designRendererMatrix,
    elementConfiguration
  );

  const { scene, viewPort, renderOptions, animationParams } = rendererState;

  const zoomedTo = rendererState.zoomedTo;

  return (
    <>
      <ZoomButton
        zoomedTo={zoomedTo}
        canvas={canvas!}
        rendererDispatch={rendererDispatch}
        elementUtil={elementUtil}
      />

      <div className="mfc__configurator-preview">
        {isLoading && t("loading")}

        <StitchCanvas
          canvasId={canvasId}
          initCallback={initCallback}
          scene={scene}
          viewPort={viewPort}
          renderOptions={renderOptions}
          animationParams={animationParams}
        />
      </div>
    </>
  );
};
