import classNames from "classnames";
import { useFeature } from "flagged";
import { PropsWithChildren } from "react";
import "./FloatingButtonGrid.css";

const FloatingButtonGrid: React.FC<PropsWithChildren> = ({ children }) => {
  const isLabeledFeatureEnabled = useFeature("ui/labeled");

  return (
    <div
      className={classNames("mfc__floating-btn-grid", {
        "mfc__floating-btn-grid-labeled": isLabeledFeatureEnabled,
      })}
    >
      <div
        className={classNames("mfc__floating-btn-grid-inner", {
          "mfc__floating-btn-grid-inner-small": true,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export { FloatingButtonGrid };
