type GlobalError = {
  message: string;
  code?: string;
  details?: string;
};

type FieldError = GlobalError & {
  field: string;
};

export type Errors = {
  global: GlobalError[];
  fields: FieldError[];
};

export type Loading = {
  isLoading: boolean;
};

export type APIState = { errors?: Errors } & Loading;

export const initialResponse = {
  errors: undefined,
  isLoading: true,
};

export const successfulResponse = {
  errors: undefined,
  isLoading: false,
};

// this type is needed to work with API responses when catching errors. when using cypress, the errors are not of type Response but just a mock of it
export type APIResponse = Pick<Response, "status" | "ok" | "json">;

export const isAPIResponse = (object: any) => {
  if (!object) {
    return false;
  }

  if (typeof object.status !== "number") {
    return false;
  }

  if (typeof object.ok !== "boolean") {
    return false;
  }

  if (!object.json) {
    return false;
  }

  return true;
};
