import { useTranslation } from "react-i18next";
import { useFeature } from "flagged";
import { FusionMelcoModelsDesign } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoModelsDesign";
import { selectThumbnailForSize } from "melco-shared-logic/dist/helper/thumbnails/thumbnailHelper";
import { LabeledFloatingButton } from "../labeledFloatingButton/LabeledFloatingButton";

type DesignFloatingButtonProps = {
  design: FusionMelcoModelsDesign | undefined;
  onClick?: () => void;
};

export const DesignFloatingButton: React.FC<DesignFloatingButtonProps> = ({
  design,
  onClick,
}) => {
  const { t } = useTranslation();

  const isLabeledFeatureEnabled = useFeature("ui/labeled");

  if (!design) {
    return null;
  }

  const { name, previews } = design;

  const designPreviewThumbnailUrl =
    selectThumbnailForSize(previews ?? [], 48)?.url ?? "";

  return (
    <LabeledFloatingButton
      label={
        isLabeledFeatureEnabled
          ? design.name ?? ""
          : t("designCollection.title")
      }
      onClick={onClick}
    >
      <img src={designPreviewThumbnailUrl} alt={name ?? ""} />
    </LabeledFloatingButton>
  );
};
