import {
  Alphabet,
  ColorUtil,
  ElementType,
  ImageElement,
  MatrixUtil,
  RectangleUtil,
  RenderSceneUtil,
  StitchElement,
  StitchElementUtil,
  ThreadColor,
  ThreadRenderOptionsUtil,
  ViewPortUtil,
} from "@melco/renderer";
import {
  BaseAction,
  LetteringChanged,
  MultiAction,
  RenderState,
  SingleColorChanged,
  ViewPortChangeAction,
} from "@melco/renderer/dist/events";
import { FusionMelcoFusionModelsRendererMatrix } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoFusionModelsRendererMatrix";
import { Resources, ElementConfiguration } from "../hooks/useRenderer";

const defaultBackgroundColor = ColorUtil.createColor(255, 255, 255, 1.0);

const initThreadRenderOptions = () => {
  const threadRenderOptions = ThreadRenderOptionsUtil.createDefault();

  if (/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) {
    threadRenderOptions.directionalLight = true;
  }

  return threadRenderOptions;
};

export type ZoomedTo = "product" | "design";
export type ZoomToFitAction = ZoomedTo | "toggle" | undefined;

export interface ConfiguratorRenderState extends RenderState {
  zoomedTo: ZoomedTo;
}

export class ConfiguratorRenderStateUtil {
  public static createDefault(): ConfiguratorRenderState {
    const renderState: ConfiguratorRenderState = {
      scene: RenderSceneUtil.createEmptyScene(),
      viewPort: RectangleUtil.emptyRect(),
      renderOptions: initThreadRenderOptions(),
      zoomedTo: "product",
    };

    renderState.scene.bgColor = defaultBackgroundColor;

    return renderState;
  }

  public static cloneCustomState(
    c: ConfiguratorRenderState
  ): ConfiguratorRenderState {
    return { ...c };
  }
}

export class ConfiguratorSetBlankPictureAndDesignAction implements BaseAction {
  private blankPicture?: ImageElement;
  private design?: StitchElement;
  private designRendererMatrix?: FusionMelcoFusionModelsRendererMatrix;

  constructor(
    blankPicture?: ImageElement,
    design?: StitchElement,
    designRendererMatrix?: FusionMelcoFusionModelsRendererMatrix
  ) {
    this.blankPicture = blankPicture;
    this.design = design;
    this.designRendererMatrix = designRendererMatrix;
  }

  public modifyState(state: RenderState): RenderState {
    const newState = ConfiguratorRenderStateUtil.cloneCustomState(
      state as ConfiguratorRenderState
    );

    newState.scene = RenderSceneUtil.cloneScene(newState.scene);
    newState.scene.elements = [];

    if (this.blankPicture) {
      newState.scene.elements.push(this.blankPicture);
    }

    if (this.design) {
      if (this.designRendererMatrix) {
        const { m00, m01, m02, m10, m11, m12 } = this.designRendererMatrix;
        this.design.matrix = MatrixUtil.createMatrix(
          m00!,
          m01!,
          m02!,
          m10!,
          m11!,
          m12!
        );
      }

      newState.scene.elements.push(this.design);

      // initialize color indexes so we don't re-use same colors
      // this is important as we need full control over color handling in Fusion (e.g. with color groups)
      const threadColors: ThreadColor[] = [];

      this.design.subElements = (this.design.subElements ?? []).map(
        (originalSubElement) => {
          const subElement = { ...originalSubElement };

          const colorIndexes: number[] = [];

          subElement.colorsIndexes.forEach((ci) => {
            threadColors.push(this.design!.colors![ci]);
            colorIndexes.push(threadColors.length - 1); // index of last added color
          });

          subElement.colorsIndexes = colorIndexes;

          return subElement;
        }
      );

      this.design.colors = threadColors;
    }

    return newState;
  }
}

export class ConfiguratorElementConfigurationChangedAction
  implements BaseAction
{
  private designElement: StitchElement;
  private elementConfiguration: ElementConfiguration;
  private resources: Resources;

  constructor(
    designElement: StitchElement,
    elementConfiguration: ElementConfiguration,
    resources: Resources
  ) {
    this.designElement = designElement;
    this.elementConfiguration = elementConfiguration;
    this.resources = resources;
  }

  public modifyState(state: RenderState): RenderState {
    const actions: BaseAction[] = [];

    const designElementClone = { ...this.designElement };

    const { elements } = this.elementConfiguration;

    (designElementClone.subElements ?? []).forEach((_rendererElement, idx) => {
      const element = elements[idx];

      const { color, activeColorGroupId, lettering } = element;

      // update colors
      if (color) {
        let threadColor: ThreadColor | undefined;

        if (activeColorGroupId == null) {
          // regular color
          const { red, green, blue } = color;

          threadColor = { red, green, blue };
        } else {
          // grouped color
          const colorGroup = elements[activeColorGroupId].color;

          if (colorGroup) {
            const { red, green, blue } = colorGroup;

            threadColor = { red, green, blue };
          }
        }

        if (threadColor) {
          actions.push(
            new SingleColorChanged(designElementClone, idx, threadColor)
          );
        }
      }

      // update all lettering objects
      if (lettering) {
        const { rendererIndex, text, rfmUrl, isEditable } = lettering;

        // we only want to actually update the lettering if the lettering is editable and the font has been loaded
        // otherwise alphabet will be undefined which causes the renderer to fall back to render initial alphabet
        const alphabet =
          rfmUrl && isEditable
            ? (this.resources[rfmUrl] as Alphabet)
            : undefined;

        //Only update the renderer if the alphabet is avaiblable in resources
        //Revert is explicitly not used to not have a default font between loading resources and showing the chosen font.
        if (alphabet) {
          actions.push(
            new LetteringChanged(
              designElementClone,
              rendererIndex,
              text,
              alphabet
            )
          );
        }
      }
    });

    const multiAction = new MultiAction(actions);

    return multiAction.modifyState(state);
  }
}

export class ConfiguratorZoomToFitAction implements BaseAction {
  private zoomTo: ZoomToFitAction;
  private width: number;
  private height: number;
  private util: StitchElementUtil;

  constructor(
    zoomTo: ZoomToFitAction,
    width: number,
    height: number,
    util: StitchElementUtil
  ) {
    this.zoomTo = zoomTo;
    this.width = width;
    this.height = height;
    this.util = util;
  }

  public modifyState(state: RenderState): RenderState {
    const newState = ConfiguratorRenderStateUtil.cloneCustomState(
      state as ConfiguratorRenderState
    );

    if (this.zoomTo === "toggle") {
      newState.zoomedTo =
        (state as ConfiguratorRenderState).zoomedTo === "product"
          ? "design"
          : "product";
    }

    const ZOOM_ANIMATION_DURATION_MS = 300;

    const dpi = 0;
    const margin = newState.zoomedTo === "product" ? 80 : 100;

    const designElement = newState.scene.elements.find(
      (e) => e?.type === ElementType.DESIGN
    ) as StitchElement;

    const productElement = newState.scene.elements.find(
      (e) => e?.type === ElementType.IMAGE
    ) as ImageElement;

    if (
      (newState.zoomedTo === "design" && !designElement) ||
      (newState.zoomedTo === "product" && !productElement)
    ) {
      return newState;
    }

    const rect =
      newState.zoomedTo === "design"
        ? this.util.calcRectForTransform(
            designElement,
            designElement?.matrix
              ? designElement.matrix
              : MatrixUtil.identityMatrix()
          )
        : productElement.rect!;

    const viewPort = ViewPortUtil.zoomToFit(
      rect,
      this.width,
      this.height,
      dpi,
      margin
    );

    return new ViewPortChangeAction(
      viewPort,
      this.zoomTo ? ZOOM_ANIMATION_DURATION_MS : undefined
    ).modifyState(newState) as ConfiguratorRenderState;
  }
}
