import {
    StitchElement, ImageElement, SelectionBox, ElementType, WireframeType, GridElement
} from './elements.js';
import { DesignParams, LetteringParams, ImageParams, GridParams } from './elementparams'
import { Alphabet } from "./alphabet";

import createRendererModule, { Renderer, RenderElement, RenderModule } from '../wasm/MelcoRendererApp.js';

import { Point, Rectangle, RectangleUtil } from './geom.js';
import { StitchElementUtil } from './stitchelementutil.js';
import { RenderScene, WireframeLayer, ThreadRenderOptions, ThreadRenderOptionsUtil, ViewPortAnimationParams } from './scene.js';
import { LocalFileInterface } from './filesystem.js';

export const renderer_version: string = "0.0.0";
const default_cdn_prefix: string = "https://test-cdn.melcocloud.com/@melco/renderer/" + renderer_version;

function isMobile(): boolean {
    if (typeof navigator === 'undefined') {
        return false;
    }
    return /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent);
}
/**
 * Factory to create elements.  Call GLRenderer.getFactory to get instance.
 */
export class ElementFactory {
    private _renderer: Renderer;
    private _module: RenderModule;
    private _stitchElementUtil: StitchElementUtil;
    private _fileInterface: LocalFileInterface
    constructor(renderer: Renderer, module: RenderModule) {
        this._renderer = renderer;
        this._module = module;
        this._stitchElementUtil = new StitchElementUtil(module);
        this._fileInterface = new LocalFileInterface(module)
    }
    public get elementUtil(): StitchElementUtil  {
        return this._stitchElementUtil;
    }
    public get localFileInterface(): LocalFileInterface {
        return this._fileInterface
    }
    /**
     * Create new image element.
     * 
     * @param params parameters for element
     * @returns 
     */
    public async createImageElement(params: ImageParams, uid?: string): Promise<ImageElement> {
        let i = this.createImageElementLazyLoad(params, uid);
        await this._stitchElementUtil.ensureImageLoaded(i);
        return i;
    }
    public createImageElementLazyLoad(params: ImageParams, uid?: string): ImageElement {
        let i: ImageElement = {
            uid: uid || getNextUID(),
            type: ElementType.IMAGE,
            image_params: params
        };
        return i;
    }
    /**
     * Create new Design element. 
     * @param params parameters for element
     * @returns 
     */
    public async createDesignElement(params: DesignParams, uid?: string): Promise<StitchElement> {
        let p = this.createDesignElementLazyLoad(params, uid);
        await this._stitchElementUtil.ensureElementLoaded(p, undefined, true);
        return p;
    }
    public createDesignElementLazyLoad(params: DesignParams, uid?: string): StitchElement {
        let v: StitchElement = {
            uid: uid || getNextUID(),
            type: ElementType.DESIGN,
            design_params: params
        }
        return v;
    }

    /**
     * Create new lettering element.
     * @param params parameters for element
     * @returns StitchElement 
     */
    public createLetteringElementLazyLoad(params: LetteringParams, alphabet: Alphabet): StitchElement {
        params.alphabet = alphabet;
        let v: StitchElement = {
            uid: getNextUID(),
            type: ElementType.LETTERING,
            letteringParams: [params]
        }
        return v;
    }
    public async createLetteringElement(params: LetteringParams, alphabet: Alphabet): Promise<StitchElement> {
        let l = this.createLetteringElementLazyLoad(params, alphabet);
        await this._stitchElementUtil.ensureElementLoaded(l, undefined, true);
        return l;
    }

    /**
     * Create new alphabet from path
     */
    public async createAlphabet(path: string): Promise<Alphabet> {
        console.log("Create alphabet");
        let a = this.createAlphabetLazyLoad(path);
        await this._stitchElementUtil.ensureAlphabetLoaded(a);
        return a;
    }
    public createAlphabetLazyLoad(path: string): Alphabet {
        let a: Alphabet = {
            alphabet_metadata_path: path
        }
        return a;
    }
    /**
     * Create selection box
     */
    public createSelectionBox(): SelectionBox {
        return {
            type: WireframeType.SELECTION_BOX,
            display: false,
            rect: RectangleUtil.emptyRect(),
            isRotate: false
        };
    }
    /**
     * Create grid element
     */
    public createGridElement(params: GridParams): GridElement {
        return {
            uid: getNextUID(),
            type: ElementType.GRID,
            grid_params: params
        }
    }

    /**
     * Creates empty scene
     */
    public createScene(): RenderScene {
        return {
            elements: [],
            bgColor: {
                red: 200,
                green: 200,
                blue: 200,
                alpha: 255
            }
        };
    }

    public destroy() {
        this._stitchElementUtil.destroy();
    }
}
/**
 * Implementation for rendering to webgl canvas.
 * 
 * Factory methods to create images, designs, lettering create handle to resources stored on gpu, in
 * addition to cached downloads from urls.  For performance reasons elements should be persisted until no
 * longer needed.
 */
export class GLRenderer {
    private _renderer: Renderer | undefined;
    private _autoZoom: number = 0;
    private _elementFactory: ElementFactory;
    private _module: RenderModule;
    private _renderOptions: ThreadRenderOptions;
    private _layer?: WireframeLayer;
    private _scene?: RenderScene;
    private _viewPort?: Rectangle;
    private _noopid = 1;
    private _animationRequested = false;
    private _resizeFrameBuffer?: () => void 

    /**
     * Create object should use createRenderer factory method rather than creating directly.
     * 
     * @param renderer webasm renderer
     */
    constructor(renderer: Renderer, module: RenderModule) {
        this._renderer = renderer;
        this._elementFactory = new ElementFactory(renderer, module);
        this._module = module;
        this._renderOptions = ThreadRenderOptionsUtil.createDefault();
        if (isMobile()) {
            this._renderOptions.directionalLight = true;
        }
        this._updateRenderOptions();
    }

    /**
     * Renders to canvas using all parameters set (ie. viewPort, backgroundColor, elements, etc.)
     */
    public updateRenderScene(scene: RenderScene): void {
        this._elementFactory.elementUtil._internalIncrementIterNumber();
        if (this._renderer) {
            console.debug("Scene Updated", scene);
            this._scene = scene;
            this._renderer.setBackgroundColor(scene.bgColor.red, scene.bgColor.green, scene.bgColor.blue);
            //this._renderer.setThreadRenderOptions(scene.threadRenderOptions);
            var elements = new this._module.VectorRenderElement();
            let s = new Set<RenderElement>();
            for (let e of scene.elements) {
                let loadElement = this._elementFactory.elementUtil._internalSyncElement(e, (isFirst: boolean) => {
                    if (this._scene) {
                        this.updateRenderScene(this._scene);
                    }
                    this.redraw();
                }, s, true);

                if (loadElement && loadElement.element) {
                    elements.push_back(loadElement.element);
                }
            }
            this._renderer.setRenderElements(elements);
            elements.delete();
            if (this._layer) {
                this._internalSyncWireframe();
            }
            this.redraw();
        }
    }

    public _internalSyncWireframe() {
        if (this._renderer) {
            let wf = this._elementFactory.elementUtil._internalGetSelectionBoxRect(this._layer, this._scene);
            let v = new this._module.VectorWireframeElement();
            if (wf) {
                v.push_back(wf);
            }
            this._renderer.setWireframeElements(v);
            v.delete();
        }
    }
    public setWireframeElements(layer?: WireframeLayer) {
        if (this._renderer) {
            this._layer = layer;
            this._internalSyncWireframe();
            this.redraw();
        }
    }

    /**
     * Retrieve Factory for creating elements
     */
    public getFactory(): ElementFactory {
        return this._elementFactory;
    }

    /**
     * Set for viewport changes (pan/zoom) but scene remains same.
     * Optional animation params can be set if which to animate view port change.
     */
    public setViewPort(viewPort: Rectangle, viewPortAnimationParams?: ViewPortAnimationParams) {
        this._viewPort = viewPort;
        if (this._renderer) {
            console.debug("ViewPort Updated", viewPort);
            if (viewPortAnimationParams && viewPortAnimationParams.valid()) {
                let idempotenceVal = viewPortAnimationParams.idempotenceVal ? viewPortAnimationParams.idempotenceVal : Date.now();
                this._renderer.setViewPortWithAnimation(viewPort.llx, viewPort.lly, viewPort.urx, viewPort.ury, viewPortAnimationParams.durationMs, idempotenceVal, this._autoZoom);
            } else {
                this._renderer.setViewport(viewPort.llx, viewPort.lly, viewPort.urx, viewPort.ury, this._autoZoom);
            }
            this.redraw();
        }
    }

    /** Get current viewport */
    public getViewPort() {
        return this._viewPort
    }

    /**
     * If using non-fixed canvas should call this from ResizeObserver to ensure canvas size changes arent missed.
     */
    public handleCanvasSizeChanged(resizeFrameBuffer?: () => void) {
        if (resizeFrameBuffer) {
            this._resizeFrameBuffer = resizeFrameBuffer
        }
        if (this._renderer) {
          console.debug("HandleCanvasSizeChanged");
          this._renderer.handleCanvasSizeChanged();
          this.redraw();
        }
    }

    /**
     * Forces redraw, render/changeViewport will call this.
     */
    public redraw() {
        if (!this._animationRequested) {
            this._animationRequested = true;
            window.requestAnimationFrame(() => {
                if (this._resizeFrameBuffer) {
                    this._resizeFrameBuffer()
                    this._resizeFrameBuffer = undefined
                }
                this._animationRequested = false;
                if (this._renderer) {
                    console.debug("Redraw");
                    let redraw = this._renderer.doDrawEvent();
                    if (redraw) {
                        this.redraw();
                    }
                }
            });
        }
    }

    /**
     * Forces redraw of wireframe layer
     */
    public redrawWireframe() {
        this.redraw();
    }

    private _updateRenderOptions(): void {
        if (this._renderer) {
            let to = new this._module.ThreadRenderOptions();
            to.directionalLight = this._renderOptions.directionalLight;
            to.enable3d = this._renderOptions.enable3d;
            to.enableTextures = this._renderOptions.enableTextures;
            to.enableTwists = this._renderOptions.enableTwists;
            to.highQualityZoomLevel = this._renderOptions.highQualityZoomLevel;
            to.lightHeightScale = this._renderOptions.lightHeightScale;
            to.enableZBuffer = this._renderOptions.enableZBuffer;
            this._renderer.setThreadRenderOptions(to);
            to.delete();
            this.redraw();
        }
    }
    /**
     * Options to render stitch elements
     */
    public set threadRenderOptions(options: ThreadRenderOptions) {
        this._renderOptions = options;
        this._updateRenderOptions();
    }
    public get threadRenderOptions() {
        return this._renderOptions;
    }

    /**
     * Overrides auto computed zoom (rendering config is different for different zoom levels, setting to zero auto computes
     * based on dpi and viewPort.)
     */
    public setZoom(zoom: number): void {
        this._autoZoom = zoom;
        this.redraw();
    }

    public destroy(): void {
        if (this._renderer) {
          this._renderer.delete();
          this._renderer = undefined;
        }
    }
    public valid(): boolean {
        return this._renderer ? true : false;
    }
}

class ModuleCache {
    public constructor() {
        this.prefix = Date.now().toString() + "_";
    }
    public module: Promise<RenderModule> | undefined;
    public uid: number = 1;
    public prefix: string;
}
declare global {
  var _MELCO_RENDERER_MODULE_: ModuleCache | undefined;
}
function getNextUID(): string {
    if (!globalThis._MELCO_RENDERER_MODULE_) {
        globalThis._MELCO_RENDERER_MODULE_ = new ModuleCache();
    }
    globalThis._MELCO_RENDERER_MODULE_.uid = globalThis._MELCO_RENDERER_MODULE_.uid + 1;
    return globalThis._MELCO_RENDERER_MODULE_.prefix + globalThis._MELCO_RENDERER_MODULE_.uid.toString();
}

/**
 * Downloads and loads emscripten wasm renderer module to memory
 */
export function loadRendererModule(wasmUrlOverride?: string): ModuleCache {
    if (!globalThis._MELCO_RENDERER_MODULE_) {
        globalThis._MELCO_RENDERER_MODULE_ = new ModuleCache();
    }
    if (!globalThis._MELCO_RENDERER_MODULE_.module) {
        let baseModule = {
            locateFile: function(url: string, scriptDirectory: string): string {
                    if (url == "MelcoRendererApp.wasm") {
                        if (wasmUrlOverride) {
                            return wasmUrlOverride;
                        } else if (renderer_version != "0.0.0") {
                            return default_cdn_prefix + "/" + url;
                        }
                    }
                    return scriptDirectory + url;
                },
                onAbort: function() {
                    globalThis._MELCO_RENDERER_MODULE_ = undefined;
                },
            doNotCaptureKeyboard: true    
        };
        globalThis._MELCO_RENDERER_MODULE_.module = createRendererModule(baseModule);
    }
    return globalThis._MELCO_RENDERER_MODULE_;
}
/**
 * Creates renderer and attaches to canvas using webgl1.
 * 
 * @param canvas The canvas to attach renderer to.  Creates webgl context attached to canvas, on success
 *   a handle is returned to renderer element which can render to canvas.
 */
export async function createRenderer(canvas: string | HTMLElement, wasmUrlOverride?: string, enableMultiSampling?: boolean): Promise<GLRenderer> {
    let multisample = true;
    if (enableMultiSampling !== undefined) {
        multisample = enableMultiSampling;
    }
    var id = '';
    var canvasElement: HTMLElement | null = null;
    if (typeof canvas == "string") {
        id = canvas;
        canvasElement = document.getElementById(id);
    } else if (!canvas.id) {
        throw new Error('Canvas doesnt have id');
    } else {
        id = canvas.id;
        canvasElement = canvas;
    }
    if (!canvasElement) {
        throw new Error('Canvas element doesnt exist');
    }
    let moduleCache = loadRendererModule(wasmUrlOverride);
    let module = await moduleCache.module;
    if (!module) {
        throw new Error('Module couldnt be loaded');
    }
    module.canvas = canvasElement;
    let r = module.createRenderer(id, multisample);
    if (!r) {
        throw new Error('Failed to initialize gl context');
    }
    return new GLRenderer(r, module);
}
