import classNames from "classnames";

import "./PersonalizeEditSection.css";

export type PersonalizeEditSectionProps = {
  title?: string | null;
  hideOnDesktop?: boolean;
  children?: React.ReactNode;
};

const PersonalizeEditSection: React.FC<PersonalizeEditSectionProps> = ({
  title,
  hideOnDesktop,
  children,
}) => (
  <div
    className={classNames("mfc__personalize-edit-section", {
      "mfc__hidden-desktop": hideOnDesktop,
    })}
  >
    <div className="mfc__personalize-edit-section-inner">
      {title && <h2>{title}</h2>}
      {children}
    </div>
  </div>
);

export { PersonalizeEditSection };
