
import {BaseElement, StitchElement, ImageElement, SelectionBox, StitchSubElement, ElementType, WireframeType, SelectionBoxUtil, SelectionDataElemUtil, SelectionDataElem} from "../elements";
import { Alphabet } from '../alphabet';
import { RenderScene, RenderSceneUtil, WireframeLayer, WireframeLayerUtil, AnimationParams, ThreadRenderOptions, ViewPortAnimationParams } from "../scene";
import { StitchElementUtil } from "../stitchelementutil";
import { Matrix, Color, Rectangle, RectangleUtil, MatrixUtil } from "../geom";
import {
    ElementFactory
} from "../glrenderer";
import { ThreadColor } from "../elementparams";
import {ViewDesignInformation, ViewPortUtil} from "../utils";
import {SelectionData, SelectionDataUtil} from "./SelectionState";

export interface RenderState {
    scene: RenderScene,
    wireframeLayer?: WireframeLayer,
    viewPort: Rectangle,
    selectionData?: SelectionData,
    animationParams?: AnimationParams,
    renderOptions?: ThreadRenderOptions
}
export class RenderStateUtil {
    public static cloneState(state: RenderState): RenderState {
        return {...state};
    }
}
export interface BaseAction {
    modifyState(state: RenderState): RenderState;
}
export class RenderOptionsChange implements BaseAction {
    constructor(r: ThreadRenderOptions) {
        this.renderOptions = r;
    }
    public modifyState(state: RenderState): RenderState {
        let s = RenderStateUtil.cloneState(state);
        s.renderOptions = this.renderOptions;
        return s;
    }
    public renderOptions: ThreadRenderOptions;
}
export class BackgroundColorChange implements BaseAction {
    constructor(c: Color) {
        this.backgroundColor = c;
    }
    public modifyState(state: RenderState): RenderState {
        let s = RenderStateUtil.cloneState(state);
        s.scene = RenderSceneUtil.cloneScene(s.scene);
        s.scene.bgColor = this.backgroundColor;
        return s;
    }
    public backgroundColor: Color;
}
export class ElementListChange implements BaseAction {
    constructor(elements: Array<BaseElement>, remapSelection: boolean=true) {
        this.elements = elements;
        this.remapSelection = remapSelection;
    }
    public modifyState(state: RenderState): RenderState {
        let s = RenderStateUtil.cloneState(state);
        s.scene = RenderSceneUtil.cloneScene(s.scene);
        if (s.selectionData && this.remapSelection && s.selectionData.selectElems) {
            s.selectionData = SelectionDataUtil.cloneSelectionData(s.selectionData);
            let orgMap = new Map<string, number>();
            for (let i=0;i<state.scene.elements.length;i++) {
                let e = state.scene.elements[i];
                if (e) {
                    orgMap.set(e.uid, i);
                }
            }
            let selectionRemap = new Map<number, number>();
            for (let i = 0; i < this.elements.length; i++ ) {
                let e = this.elements[i];
                if (e) {
                    let c = orgMap.get(e.uid);
                    if (c !== undefined) {
                        selectionRemap.set(c, i);
                    }
                }
            }
            let newSel: Array<SelectionDataElem> = [];
            for (let e of s.selectionData.selectElems) {
                let c = selectionRemap.get(e.elementIndex);
                if (c !== undefined) {
                    let v = SelectionDataElemUtil.cloneSelectionDataElem(e);
                    v.elementIndex = c;
                    newSel.push(v);
                }
            }
            s.selectionData.selectElems = newSel;
        }
        s.scene.elements = this.elements;
        return s;
    }
    public elements: Array<BaseElement>;
    public remapSelection: boolean = true;
}
export class WireframeLayerChanged implements BaseAction {
    constructor(wireframeLayer?: WireframeLayer, util?: StitchElementUtil) {
        this.wireframeLayer = wireframeLayer;
        this.util = util;
    }
    public modifyState(state: RenderState): RenderState {
        let s = RenderStateUtil.cloneState(state);
        s.wireframeLayer = this.wireframeLayer;
        return s;
    }
    public wireframeLayer?: WireframeLayer;
    public util?: StitchElementUtil;
}
export const replaceDesign = (s: RenderScene, d: BaseElement): BaseElement | undefined => {
    let design: BaseElement | undefined = undefined;
    for (let i=0;i<s.elements.length;i++) {
        let e = s.elements[i];
        if (e && e.uid == d.uid) {
            design = StitchElementUtil.cloneBaseElement(e);
            s.elements[i] = design;
        }
    }
    return design;
}
export class LetteringChanged implements BaseAction {
    constructor(design: StitchElement, letteringElement: number,
        text?: string, alphabet?: Alphabet) {
        this.design = design;
        this.letteringElement = letteringElement;
        this.text = text;
        this.alphabet = alphabet;
    }
    public modifyState(state: RenderState): RenderState {
        let s = RenderStateUtil.cloneState(state);
        s.scene = RenderSceneUtil.cloneScene(s.scene);
        let design = replaceDesign(s.scene, this.design);
        if (design && design.type != ElementType.IMAGE) {
            let selem = design as StitchElement;
            if (selem.letteringParams && this.letteringElement < selem.letteringParams.length) {
                selem.letteringParams[this.letteringElement] = {...selem.letteringParams[this.letteringElement]}

                if (this.text !== undefined) {
                    selem.letteringParams[this.letteringElement].text = this.text;
                }
                if (this.alphabet !== undefined) {
                    selem.letteringParams[this.letteringElement].alphabet = this.alphabet;
                }
            }
        }
        return s;
    }
    public design: StitchElement;
    public letteringElement: number = -1;
    public text?: string;
    public alphabet?: Alphabet;
}
export class RevertLetteringChanges implements BaseAction {
    constructor(design: StitchElement, letteringElement: number) {
        this.design = design;
        this.letteringElement = letteringElement;
    }
    public modifyState(state: RenderState): RenderState {
        let s = RenderStateUtil.cloneState(state);
        s.scene = RenderSceneUtil.cloneScene(s.scene);
        let design = replaceDesign(s.scene, this.design);
        if (design && design.type != ElementType.IMAGE) {
            let selem = design as StitchElement;
            if (selem.letteringParams && this.letteringElement < selem.letteringParams.length) {
                selem.letteringParams[this.letteringElement].alphabet = undefined;
            }
        }
        return s;
    }
    public design: StitchElement;
    public letteringElement: number = -1;
}
export class ColorsChanged implements BaseAction {
    constructor(design: StitchElement, threadColors: Array<ThreadColor>) {
        this.design = design;
        this.threadColors = threadColors;
    }
    public modifyState(state: RenderState): RenderState {
        let s = RenderStateUtil.cloneState(state);
        s.scene = RenderSceneUtil.cloneScene(s.scene);
        let design = replaceDesign(s.scene, this.design);
        if (design && design.type != ElementType.IMAGE) {
            let selem = design as StitchElement;
            selem.colors = this.threadColors;
        }
        return s;
    }
    public design: StitchElement;
    public threadColors: Array<ThreadColor> = [];
}
export class SingleColorChanged implements BaseAction {
    constructor(design: StitchElement, colorIdx: number, color: ThreadColor) {
        this.design = design;
        this.colorIdx = colorIdx;
        this.color = color;
    }
    public modifyState(state: RenderState): RenderState {
        let s = RenderStateUtil.cloneState(state);
        s.scene = RenderSceneUtil.cloneScene(s.scene);
        let design = replaceDesign(s.scene, this.design);
        if (design && design.type != ElementType.IMAGE) {
            let selem = design as StitchElement;
            if (selem.colors) {
                selem.colors = [...selem.colors];
                if (this.colorIdx < selem.colors.length) {
                    selem.colors[this.colorIdx] = this.color;
                }
            }
        }
        return s;
    }
   public design: StitchElement;
   public colorIdx: number;
   public color: ThreadColor;
}
export class TransformChanged implements BaseAction {
    constructor(design: StitchElement, matrix: Matrix) {
        this.design = design;
        this.matrix = matrix;
    }
    public modifyState(state: RenderState): RenderState {
        let s = RenderStateUtil.cloneState(state);
        s.scene = RenderSceneUtil.cloneScene(s.scene);
        let design = replaceDesign(s.scene, this.design);
        if (design && design.type != ElementType.IMAGE) {
            let selem = design as StitchElement;
            selem.matrix = this.matrix;
        }
        return s;
    }
    public design: StitchElement;
    public matrix: Matrix;
}
export class ImageTransformChanged implements BaseAction {
    constructor(im: ImageElement, r: Rectangle, m?: Matrix) {
        this.rect = r;
        if (m) {
            this.matrix = m;
        }
        this.image = im;
    }
    public modifyState(state: RenderState): RenderState {
        let s = RenderStateUtil.cloneState(state);
        s.scene = RenderSceneUtil.cloneScene(s.scene);
        let image = replaceDesign(s.scene, this.image);
        if (image && image.type == ElementType.IMAGE) {
            let im = image as ImageElement;
            im.matrix = this.matrix;
            im.rect = this.rect;
        }
        return s;
    }
    public image: ImageElement;
    public rect: Rectangle;
    public matrix: Matrix = MatrixUtil.identityMatrix();
}
export class SubElementChanged implements BaseAction {
    constructor(design: StitchElement, subElementIdx: number, colorIndexes: Array<number>) {
        this.design = design;
        this.subElementIdx = subElementIdx;
        this.colorIndexes = colorIndexes;
    }
    public modifyState(state: RenderState): RenderState {
        let s = RenderStateUtil.cloneState(state);
        s.scene = RenderSceneUtil.cloneScene(s.scene);
        let design = replaceDesign(s.scene, this.design);
        if (design && design.type != ElementType.IMAGE) {
            let selem = design as StitchElement;
            if (selem.subElements && this.subElementIdx < selem.subElements.length) {
                selem.subElements[this.subElementIdx] = {...selem.subElements[this.subElementIdx]};
                selem.subElements[this.subElementIdx].colorsIndexes = this.colorIndexes;
            }
        }
        return s;
    }
    public design: StitchElement;
    public subElementIdx: number;
    public colorIndexes: Array<number>;
}
export class SubElementListChanged implements BaseAction {
    constructor(design: StitchElement, subElements: Array<StitchSubElement>) {
        this.design = design;
        this.subElements = subElements;
    }
    public modifyState(state: RenderState): RenderState {
        let s = RenderStateUtil.cloneState(state);
        s.scene = RenderSceneUtil.cloneScene(s.scene);
        let design = replaceDesign(s.scene, this.design);
        if (design && design.type != ElementType.IMAGE) {
            let selem = design as StitchElement;
            selem.subElements = this.subElements;
        }
        return s;
    }
    public design: StitchElement;
    public subElements: Array<StitchSubElement> = [];
}
export class MultiAction implements BaseAction {
    constructor(actions: Array<BaseAction>) {
        this.actions = actions || [];
    }
    public modifyState(state: RenderState): RenderState {
        let s = state;
        for (let a of this.actions) {
            s = renderStateReducer(s, a);
        }
        return s;
    }
    public actions: Array<BaseAction>;
}
export class ViewPortChangeAction implements BaseAction {
    constructor(r: Rectangle, animationDurationMs?: number) {
        this.rect = r;
        if (animationDurationMs) {
            this.animationParams = new ViewPortAnimationParams(animationDurationMs, Date.now());
        }
    }
    public modifyState(state: RenderState): RenderState {
        let s = RenderStateUtil.cloneState(state);
        s.viewPort = this.rect;
        if (this.animationParams) {
            if (!s.animationParams) {
                s.animationParams = {};
            } else {
                s.animationParams = {...s.animationParams};
            }
            s.animationParams.viewPortAnimationParams = this.animationParams;
        }
        return s;
    }
    public rect: Rectangle;
    public animationParams?: ViewPortAnimationParams;
}
export class NormalizeImageRectAction implements BaseAction {
    constructor(f: ElementFactory) {
        this.factory = f;
    }
    public modifyState(state: RenderState): RenderState {
        let v = new ViewDesignInformation();
        v.viewPort = state.viewPort;
        let hasImage = false;
        for (let e of state.scene.elements) {
            if (e && e.type != ElementType.IMAGE) {
                let selem = e as StitchElement;
                let metadata = this.factory.elementUtil.getStitchElementMetadataIfLoaded(selem);
                if (metadata) {
                    v.designRects.push(metadata.originalBoundingBox);
                    v.designTransforms.push(selem.matrix ? selem.matrix : MatrixUtil.identityMatrix());
                }
            }
            if (e && e.type == ElementType.IMAGE && !hasImage) {
                let im = e as ImageElement;
                hasImage = true;
                if (im.rect) {
                    v.imageRect = im.rect;
                } else {
                    let metadata = this.factory.elementUtil.getImageMetadataIfLoaded(im);
                    if (metadata && metadata.defaultRect) {
                        v.imageRect = metadata.defaultRect;
                    } else {
                        hasImage = false;
                    }
                }
                v.imageMatrix = im.matrix ? im.matrix : MatrixUtil.identityMatrix();
            }
        }
        if (hasImage && v.designRects.length > 0) {
            let s = RenderStateUtil.cloneState(state);
            s.scene = RenderSceneUtil.cloneScene(s.scene);
            let r = ViewPortUtil.normalizeViewRect(v);
            hasImage = false;
            let i = 0;
            for (let l=0;l<s.scene.elements.length;l++) {
                let e = s.scene.elements[l];
                if (e) {
                    e = StitchElementUtil.cloneBaseElement(e);
                    s.scene.elements[l] = e;
                    if (e && e.type != ElementType.IMAGE) {
                        let selem = e as StitchElement;
                        let metadata = this.factory.elementUtil.getStitchElementMetadataIfLoaded(selem);
                        if (metadata) {
                            selem.matrix = r.designTransforms[i];
                            i++;
                        }
                    } else if (e && e.type == ElementType.IMAGE && !hasImage) {
                        let im = e as ImageElement;
                        if (im.rect) {
                            hasImage = true;
                            im.rect = r.imageRect;
                            im.matrix = r.imageMatrix;
                        } else {
                            let metadata = this.factory.elementUtil.getImageMetadataIfLoaded(im);
                            if (metadata && metadata.defaultRect) {
                                hasImage = true;
                                im.rect = r.imageRect;
                                im.matrix = r.imageMatrix;
                            }
                        }
                    }
                }
            }
            s.viewPort = r.viewPort;
            s = new SelectionChanged(s.selectionData).modifyState(s);
            return s;
        }
        return state;
    }
    public factory: ElementFactory;
}

export class SelectionChanged implements BaseAction {
    constructor(d?: SelectionData) {
        this.data = d;
    }
    public modifyState(state: RenderState): RenderState {
        let selectionBox = undefined as (SelectionBox | undefined);
        let selectionBoxIdx = -1;
        if (state.wireframeLayer) {
            for (let i=0;i<state.wireframeLayer.elements.length;i++) {
                let e = state.wireframeLayer.elements[i];
                if (e && e.type == WireframeType.SELECTION_BOX) {
                    let sb = e as SelectionBox;
                    selectionBoxIdx = i;
                    selectionBox = sb;
                }
            }
        }
        if (this.data && this.data.selectElems.length == 0 || !this.data) {
            let s = state;
            if (selectionBox && selectionBox.display) {
                s = RenderStateUtil.cloneState(state);
                s.selectionData = this.data ? SelectionDataUtil.cloneSelectionData(this.data) : undefined;
                selectionBox = SelectionBoxUtil.cloneSelectionBox(selectionBox);
                selectionBox.display = false;
                if (s.wireframeLayer) {
                    s.wireframeLayer = WireframeLayerUtil.cloneWireframeLayer(s.wireframeLayer);
                    s.wireframeLayer.elements[selectionBoxIdx] = selectionBox;
                }
            }
            if (s.selectionData && s.selectionData.selectElems.length > 0) {
                s = RenderStateUtil.cloneState(state);
                s.selectionData = this.data ? SelectionDataUtil.cloneSelectionData(this.data) : undefined;
            }
            return s;
        }
        if (this.data && this.data.selectElems.length > 0) {
            let s = RenderStateUtil.cloneState(state);
            s.selectionData = SelectionDataUtil.cloneSelectionData(this.data);
            if (!s.wireframeLayer) {
                s.wireframeLayer = {elements: []};
            }
            if (s.wireframeLayer) {
                s.wireframeLayer = WireframeLayerUtil.cloneWireframeLayer(s.wireframeLayer);
                if (!selectionBox) {
                    selectionBox = SelectionBoxUtil.createEmpty();
                    s.wireframeLayer.elements.push(selectionBox);
                    selectionBoxIdx = s.wireframeLayer.elements.length - 1;
                } else {
                    selectionBox = SelectionBoxUtil.cloneSelectionBox(selectionBox);
                    s.wireframeLayer.elements[selectionBoxIdx] = selectionBox;
                }
                if (!this.data.dragging) {
                    s.scene = RenderSceneUtil.cloneScene(s.scene);
                    SelectionDataUtil.syncSelectionBox(s.selectionData, s.scene, selectionBox);
                } else if (this.data.dragRect) {
                    s.scene = RenderSceneUtil.cloneScene(s.scene);
                    SelectionDataUtil.syncSelectionBox(s.selectionData, s.scene, selectionBox);
                } else {
                    selectionBox.display = false;
                }
                s.wireframeLayer.elements[selectionBoxIdx] = selectionBox;
            }
            return s;
        }
        return state;
    }

    private data?: SelectionData;
}
export function renderStateReducer(state: RenderState, action: BaseAction) {
    return action.modifyState(state);
}
export function renderStateReducerWithLog(state: RenderState, action: BaseAction) {
    let st = action.modifyState(state);
    console.log(st);
    return st;
}