import { FusionMelcoFusionModelsCustomProductView } from "melco-shared-logic/dist/client-fusion/models/FusionMelcoFusionModelsCustomProductView";

export const getDesignCollectionIdForView = (
  view: FusionMelcoFusionModelsCustomProductView
) => {
  const firstColor = (view.color_list ?? [])[0];
  const firstDesign = (firstColor?.design_list ?? [])[0];
  const designCollection = firstDesign?.design_collection;

  return designCollection?.id ?? undefined;
};

export const parseDefaultDesignIDs = (jsonString: string | undefined) => {
  let parsedDesignIDs: Record<string, string> = {};

  try {
    if (jsonString) {
      parsedDesignIDs = JSON.parse(jsonString);
    }
  } catch (e) {
    console.error(e);
  }

  return parsedDesignIDs;
};
