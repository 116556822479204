import { useField } from "formik";
import { useDesignListFormikPath } from "./useDesignListFormikPath";
import { ActiveView } from "./useActiveView";
import { ActiveColor } from "./useProductColor";

export const useDesignCollectionId = (
  activeView: ActiveView | undefined,
  activeColor: ActiveColor | undefined,
  isDesignChangeDisabled?: boolean
) => {
  const designListFormikPath = useDesignListFormikPath(activeView, activeColor);

  const designCollectionFormikPath = `${designListFormikPath}.design_collection.id`;

  const [{ value: designCollectionId }] = useField<string | undefined>(
    designCollectionFormikPath
  );

  if (isDesignChangeDisabled) {
    return null;
  }

  return designCollectionId ?? undefined;
};
