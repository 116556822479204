import last from "lodash-es/last";
import head from "lodash-es/head";
import sortBy from "lodash-es/sortBy";

type Thumbnail = {
  name?: string | null;
  url?: string | null;
};

const inferredSizeOfThumbnail = (thumbnail: Thumbnail) => {
  const inferredSizeByName = parseInt(thumbnail.name ?? "");
  return !Number.isNaN(inferredSizeByName) ? inferredSizeByName : undefined;
};

export const selectThumbnailForSize = (
  thumbnails: Thumbnail[],
  size: number
) => {
  const fallbackThumbnail = last(thumbnails);

  const largeEnoughThumbnails = thumbnails.filter((t) => {
    const thumbnailSize = inferredSizeOfThumbnail(t);
    return thumbnailSize && thumbnailSize >= size;
  });

  const largeEnoughThumbnail = head(
    sortBy(largeEnoughThumbnails, (t) => inferredSizeOfThumbnail(t))
  );

  return largeEnoughThumbnail || fallbackThumbnail;
};
