import { Color } from "./geom";
import { ImageElement, StitchElement, SelectionBox, GridElement } from "./elements";


/** Defines scene to render to canvas */
export interface RenderScene {
    /** Background color of canvas */
    bgColor: Color,
    /** Element to render in redraw */
    elements: Array<ImageElement | StitchElement | GridElement | null>
}

export class RenderSceneUtil {
    public static createEmptyScene(): RenderScene {
        return {
            bgColor: {
                red: 200,
                green: 200,
                blue: 200,
                alpha: 255
            },
            elements: []
        };
    }
    public static cloneScene(scene: RenderScene): RenderScene {
        let clone = {...scene};
        clone.elements = [...clone.elements];
        return clone;
    }
}

/** Optional layer for wireframe */
export interface WireframeLayer {
    elements: Array<SelectionBox | null>
}

export class WireframeLayerUtil {
    public static createEmpty(): WireframeLayer {
        return {
            elements: []
        }
    }
    public static cloneWireframeLayer(wireframeLayer: WireframeLayer): WireframeLayer {
        let r = {...wireframeLayer};
        r.elements = [...r.elements];
        return r;
    }
}

/** 3d render options */
export interface ThreadRenderOptions {
    enable3d: boolean;
    enableTwists: boolean;
    directionalLight: boolean;
    enableTextures: boolean;
    lightHeightScale: number;
    highQualityZoomLevel: number;
    enableZBuffer: boolean;
}

export class ThreadRenderOptionsUtil {
    public static createDefault(): ThreadRenderOptions {
        return {
            enable3d: true,
            enableTwists: false,
            directionalLight: false,
            enableTextures: true,
            lightHeightScale: 1,
            highQualityZoomLevel: 75,
            enableZBuffer: true
        }; 
    }
    public static cloneThreadRenderOptions(r: ThreadRenderOptions): ThreadRenderOptions {
        return {...r};
    }
}

/**
 * Params for animating viewPort a given number of milliseconds
 */
export class ViewPortAnimationParams {
    /**
     *
     * @param durationMs The duration in milliseconds animation should last
     * @param idempotenceVal An optional idempotence number which prevents ViewPortAnimationParams
     *                       from being processed more than once.  The idempotenceVal should be increasing for
     *                       every instance of ViewPortAnimationParams created.  In most cases should set
     *                       to Date.now().
     */
    constructor(durationMs: number, idempotenceVal?: number) {
        this.durationMs = durationMs;
        if (this.idempotenceVal !== undefined) {
            this.idempotenceVal = idempotenceVal;
            this.createTime = Date.now();
        }
    }

    /**
     * Returns whether ViewPortAnimation params is valid, if idempotent
     * then can expire if durationMs passed.
     */
    public valid(): boolean {
        if (this.idempotenceVal === undefined) {
            return true;
        }
        return (this.createTime + this.durationMs) > Date.now();
    }
    public durationMs: number = 0;
    public idempotenceVal?: number = 0;
    public createTime: number = 0;
}

/**
 * Container for all animation effects which can be configured in application.
 */
export interface AnimationParams {
    viewPortAnimationParams?: ViewPortAnimationParams;
}

