/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FusionMelcoFusionModelsCustomProduct,
    FusionMelcoFusionModelsCustomProductFromJSON,
    FusionMelcoFusionModelsCustomProductToJSON,
    FusionMelcoFusionModelsPersonalizationDownloads,
    FusionMelcoFusionModelsPersonalizationDownloadsFromJSON,
    FusionMelcoFusionModelsPersonalizationDownloadsToJSON,
    FusionMelcoFusionModelsSession,
    FusionMelcoFusionModelsSessionFromJSON,
    FusionMelcoFusionModelsSessionToJSON,
    FusionMelcoFusionModelsUpdateProductionStatus,
    FusionMelcoFusionModelsUpdateProductionStatusFromJSON,
    FusionMelcoFusionModelsUpdateProductionStatusToJSON,
    FusionMelcoModelsBoolResponse,
    FusionMelcoModelsBoolResponseFromJSON,
    FusionMelcoModelsBoolResponseToJSON,
    FusionMelcoModelsDesignCollectionFullItem,
    FusionMelcoModelsDesignCollectionFullItemFromJSON,
    FusionMelcoModelsDesignCollectionFullItemToJSON,
    FusionMelcoModelsError,
    FusionMelcoModelsErrorFromJSON,
    FusionMelcoModelsErrorToJSON,
    FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItem,
    FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItemFromJSON,
    FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItemToJSON,
    FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItem,
    FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItemFromJSON,
    FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItemToJSON,
} from '../models';

export interface ConfiguratorApiConfiguratorSessionDownloadRequest {
    sessionId: string;
    format?: string;
    printSheet?: boolean;
}

export interface ConfiguratorApiConfiguratorSessionPersonalizationsDownloadRequest {
    personalizationId: string;
    format?: string;
}

export interface ConfiguratorApiConfiguratorSessionPersonalizationsDownloadsRequest {
    format?: string;
    fusionMelcoFusionModelsPersonalizationDownloads?: FusionMelcoFusionModelsPersonalizationDownloads;
}

export interface ConfiguratorApiConfiguratorSessionsCompleteRequest {
    sessionId: string;
    fusionMelcoFusionModelsSession?: FusionMelcoFusionModelsSession;
}

export interface ConfiguratorApiConfiguratorSessionsDesignCollectionRequest {
    id: string;
}

export interface ConfiguratorApiConfiguratorSessionsDropRequest {
    sessionId: string;
    fusionMelcoFusionModelsSession?: FusionMelcoFusionModelsSession;
}

export interface ConfiguratorApiConfiguratorSessionsGetDefaultPreviewRequest {
    sessionId: string;
    width?: string;
}

export interface ConfiguratorApiConfiguratorSessionsGetPreviewRequest {
    sessionId: string;
    imageId: string;
    width?: string;
}

export interface ConfiguratorApiConfiguratorSessionsGetSessionRequest {
    sessionId: string;
}

export interface ConfiguratorApiConfiguratorSessionsListRequest {
    searchText?: string;
    sessionStatus?: string;
    productionStatus?: string;
    startTimestamp?: Date;
    endTimestamp?: Date;
    offset?: number;
    limit?: number;
    sortBy?: string;
}

export interface ConfiguratorApiConfiguratorSessionsPersonalizationPreviewRequest {
    personalizationId: string;
    width?: number;
    applyRotation?: boolean;
}

export interface ConfiguratorApiConfiguratorSessionsProductionStatusUpdateRequest {
    sessionId: string;
    fusionMelcoFusionModelsUpdateProductionStatus?: FusionMelcoFusionModelsUpdateProductionStatus;
}

export interface ConfiguratorApiConfiguratorSessionsProductsGetProductRequest {
    token: string;
}

export interface ConfiguratorApiConfiguratorSessionsSearchPersonalizationsRequest {
    searchText?: string;
    sessionStatus?: string;
    productionStatus?: string;
    startTimestamp?: Date;
    endTimestamp?: Date;
    offset?: number;
    limit?: number;
    sortBy?: string;
}

export interface ConfiguratorApiConfiguratorSessionsStartRequest {
    productToken?: string;
    sessionId?: string;
}

export interface ConfiguratorApiConfiguratorSessionsUpdateRequest {
    sessionId: string;
    fusionMelcoFusionModelsSession?: FusionMelcoFusionModelsSession;
}

/**
 * 
 */
export class ConfiguratorApi extends runtime.BaseAPI {

    /**
     */
    async configuratorSessionDownloadRaw(requestParameters: ConfiguratorApiConfiguratorSessionDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling configuratorSessionDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.printSheet !== undefined) {
            queryParameters['print_sheet'] = requestParameters.printSheet;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/{session_id}/download`.replace(`{${"session_id"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async configuratorSessionDownload(requestParameters: ConfiguratorApiConfiguratorSessionDownloadRequest): Promise<Blob> {
        const response = await this.configuratorSessionDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async configuratorSessionPersonalizationsDownloadRaw(requestParameters: ConfiguratorApiConfiguratorSessionPersonalizationsDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.personalizationId === null || requestParameters.personalizationId === undefined) {
            throw new runtime.RequiredError('personalizationId','Required parameter requestParameters.personalizationId was null or undefined when calling configuratorSessionPersonalizationsDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/personalizations/{personalization_id}/download`.replace(`{${"personalization_id"}}`, encodeURIComponent(String(requestParameters.personalizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async configuratorSessionPersonalizationsDownload(requestParameters: ConfiguratorApiConfiguratorSessionPersonalizationsDownloadRequest): Promise<Blob> {
        const response = await this.configuratorSessionPersonalizationsDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async configuratorSessionPersonalizationsDownloadsRaw(requestParameters: ConfiguratorApiConfiguratorSessionPersonalizationsDownloadsRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/configurator/sessions/personalizations/download`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FusionMelcoFusionModelsPersonalizationDownloadsToJSON(requestParameters.fusionMelcoFusionModelsPersonalizationDownloads),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async configuratorSessionPersonalizationsDownloads(requestParameters: ConfiguratorApiConfiguratorSessionPersonalizationsDownloadsRequest): Promise<Blob> {
        const response = await this.configuratorSessionPersonalizationsDownloadsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async configuratorSessionsCompleteRaw(requestParameters: ConfiguratorApiConfiguratorSessionsCompleteRequest): Promise<runtime.ApiResponse<FusionMelcoFusionModelsSession>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling configuratorSessionsComplete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/configurator/sessions/{session_Id}/complete`.replace(`{${"session_Id"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FusionMelcoFusionModelsSessionToJSON(requestParameters.fusionMelcoFusionModelsSession),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoFusionModelsSessionFromJSON(jsonValue));
    }

    /**
     */
    async configuratorSessionsComplete(requestParameters: ConfiguratorApiConfiguratorSessionsCompleteRequest): Promise<FusionMelcoFusionModelsSession> {
        const response = await this.configuratorSessionsCompleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async configuratorSessionsDesignCollectionRaw(requestParameters: ConfiguratorApiConfiguratorSessionsDesignCollectionRequest): Promise<runtime.ApiResponse<FusionMelcoModelsDesignCollectionFullItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling configuratorSessionsDesignCollection.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/designs/collections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoModelsDesignCollectionFullItemFromJSON(jsonValue));
    }

    /**
     */
    async configuratorSessionsDesignCollection(requestParameters: ConfiguratorApiConfiguratorSessionsDesignCollectionRequest): Promise<FusionMelcoModelsDesignCollectionFullItem> {
        const response = await this.configuratorSessionsDesignCollectionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async configuratorSessionsDropRaw(requestParameters: ConfiguratorApiConfiguratorSessionsDropRequest): Promise<runtime.ApiResponse<FusionMelcoModelsBoolResponse>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling configuratorSessionsDrop.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/configurator/sessions/{session_Id}/drop`.replace(`{${"session_Id"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FusionMelcoFusionModelsSessionToJSON(requestParameters.fusionMelcoFusionModelsSession),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     */
    async configuratorSessionsDrop(requestParameters: ConfiguratorApiConfiguratorSessionsDropRequest): Promise<FusionMelcoModelsBoolResponse> {
        const response = await this.configuratorSessionsDropRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async configuratorSessionsGetDefaultPreviewRaw(requestParameters: ConfiguratorApiConfiguratorSessionsGetDefaultPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling configuratorSessionsGetDefaultPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/{session_id}/preview`.replace(`{${"session_id"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async configuratorSessionsGetDefaultPreview(requestParameters: ConfiguratorApiConfiguratorSessionsGetDefaultPreviewRequest): Promise<void> {
        await this.configuratorSessionsGetDefaultPreviewRaw(requestParameters);
    }

    /**
     */
    async configuratorSessionsGetPreviewRaw(requestParameters: ConfiguratorApiConfiguratorSessionsGetPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling configuratorSessionsGetPreview.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling configuratorSessionsGetPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/{session_id}/preview/{image_Id}`.replace(`{${"session_id"}}`, encodeURIComponent(String(requestParameters.sessionId))).replace(`{${"image_Id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async configuratorSessionsGetPreview(requestParameters: ConfiguratorApiConfiguratorSessionsGetPreviewRequest): Promise<void> {
        await this.configuratorSessionsGetPreviewRaw(requestParameters);
    }

    /**
     */
    async configuratorSessionsGetSessionRaw(requestParameters: ConfiguratorApiConfiguratorSessionsGetSessionRequest): Promise<runtime.ApiResponse<FusionMelcoFusionModelsSession>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling configuratorSessionsGetSession.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/{session_id}`.replace(`{${"session_id"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoFusionModelsSessionFromJSON(jsonValue));
    }

    /**
     */
    async configuratorSessionsGetSession(requestParameters: ConfiguratorApiConfiguratorSessionsGetSessionRequest): Promise<FusionMelcoFusionModelsSession> {
        const response = await this.configuratorSessionsGetSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async configuratorSessionsListRaw(requestParameters: ConfiguratorApiConfiguratorSessionsListRequest): Promise<runtime.ApiResponse<FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.sessionStatus !== undefined) {
            queryParameters['session_status'] = requestParameters.sessionStatus;
        }

        if (requestParameters.productionStatus !== undefined) {
            queryParameters['production_status'] = requestParameters.productionStatus;
        }

        if (requestParameters.startTimestamp !== undefined) {
            queryParameters['start_timestamp'] = (requestParameters.startTimestamp as any).toISOString();
        }

        if (requestParameters.endTimestamp !== undefined) {
            queryParameters['end_timestamp'] = (requestParameters.endTimestamp as any).toISOString();
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItemFromJSON(jsonValue));
    }

    /**
     */
    async configuratorSessionsList(requestParameters: ConfiguratorApiConfiguratorSessionsListRequest): Promise<FusionMelcoModelsGenericList1MelcoFusionModelsSessionListItem> {
        const response = await this.configuratorSessionsListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async configuratorSessionsPersonalizationPreviewRaw(requestParameters: ConfiguratorApiConfiguratorSessionsPersonalizationPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.personalizationId === null || requestParameters.personalizationId === undefined) {
            throw new runtime.RequiredError('personalizationId','Required parameter requestParameters.personalizationId was null or undefined when calling configuratorSessionsPersonalizationPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.applyRotation !== undefined) {
            queryParameters['apply_rotation'] = requestParameters.applyRotation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/personalizations/{personalization_id}/preview`.replace(`{${"personalization_id"}}`, encodeURIComponent(String(requestParameters.personalizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async configuratorSessionsPersonalizationPreview(requestParameters: ConfiguratorApiConfiguratorSessionsPersonalizationPreviewRequest): Promise<void> {
        await this.configuratorSessionsPersonalizationPreviewRaw(requestParameters);
    }

    /**
     */
    async configuratorSessionsProductionStatusUpdateRaw(requestParameters: ConfiguratorApiConfiguratorSessionsProductionStatusUpdateRequest): Promise<runtime.ApiResponse<FusionMelcoFusionModelsSession>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling configuratorSessionsProductionStatusUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/configurator/sessions/{session_Id}/production/status`.replace(`{${"session_Id"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FusionMelcoFusionModelsUpdateProductionStatusToJSON(requestParameters.fusionMelcoFusionModelsUpdateProductionStatus),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoFusionModelsSessionFromJSON(jsonValue));
    }

    /**
     */
    async configuratorSessionsProductionStatusUpdate(requestParameters: ConfiguratorApiConfiguratorSessionsProductionStatusUpdateRequest): Promise<FusionMelcoFusionModelsSession> {
        const response = await this.configuratorSessionsProductionStatusUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async configuratorSessionsProductsGetProductRaw(requestParameters: ConfiguratorApiConfiguratorSessionsProductsGetProductRequest): Promise<runtime.ApiResponse<FusionMelcoFusionModelsCustomProduct>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling configuratorSessionsProductsGetProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/products/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoFusionModelsCustomProductFromJSON(jsonValue));
    }

    /**
     */
    async configuratorSessionsProductsGetProduct(requestParameters: ConfiguratorApiConfiguratorSessionsProductsGetProductRequest): Promise<FusionMelcoFusionModelsCustomProduct> {
        const response = await this.configuratorSessionsProductsGetProductRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async configuratorSessionsSearchPersonalizationsRaw(requestParameters: ConfiguratorApiConfiguratorSessionsSearchPersonalizationsRequest): Promise<runtime.ApiResponse<FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.sessionStatus !== undefined) {
            queryParameters['session_status'] = requestParameters.sessionStatus;
        }

        if (requestParameters.productionStatus !== undefined) {
            queryParameters['production_status'] = requestParameters.productionStatus;
        }

        if (requestParameters.startTimestamp !== undefined) {
            queryParameters['start_timestamp'] = (requestParameters.startTimestamp as any).toISOString();
        }

        if (requestParameters.endTimestamp !== undefined) {
            queryParameters['end_timestamp'] = (requestParameters.endTimestamp as any).toISOString();
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/personalizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItemFromJSON(jsonValue));
    }

    /**
     */
    async configuratorSessionsSearchPersonalizations(requestParameters: ConfiguratorApiConfiguratorSessionsSearchPersonalizationsRequest): Promise<FusionMelcoModelsGenericList1MelcoFusionModelsSessionPersonalizationListItem> {
        const response = await this.configuratorSessionsSearchPersonalizationsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async configuratorSessionsStartRaw(requestParameters: ConfiguratorApiConfiguratorSessionsStartRequest): Promise<runtime.ApiResponse<FusionMelcoFusionModelsSession>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.productToken !== undefined) {
            queryParameters['product_token'] = requestParameters.productToken;
        }

        if (requestParameters.sessionId !== undefined) {
            queryParameters['session_Id'] = requestParameters.sessionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configurator/sessions/start`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoFusionModelsSessionFromJSON(jsonValue));
    }

    /**
     */
    async configuratorSessionsStart(requestParameters: ConfiguratorApiConfiguratorSessionsStartRequest): Promise<FusionMelcoFusionModelsSession> {
        const response = await this.configuratorSessionsStartRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async configuratorSessionsUpdateRaw(requestParameters: ConfiguratorApiConfiguratorSessionsUpdateRequest): Promise<runtime.ApiResponse<FusionMelcoFusionModelsSession>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling configuratorSessionsUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/configurator/sessions/{session_Id}`.replace(`{${"session_Id"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FusionMelcoFusionModelsSessionToJSON(requestParameters.fusionMelcoFusionModelsSession),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoFusionModelsSessionFromJSON(jsonValue));
    }

    /**
     */
    async configuratorSessionsUpdate(requestParameters: ConfiguratorApiConfiguratorSessionsUpdateRequest): Promise<FusionMelcoFusionModelsSession> {
        const response = await this.configuratorSessionsUpdateRaw(requestParameters);
        return await response.value();
    }

}
