import { ArrowIcon } from "../../../icons/icons";

import "./ModalSidebarScrollIndicator.css";

const ModalSidebarScrollIndicator = () => (
  <div className="mfc__modal-sidebar-scroll-indicator">
    <ArrowIcon />
  </div>
);

export { ModalSidebarScrollIndicator };
